import { useFormik } from "formik";
import { useState } from "react";
import LiveForm from "../components/Courses/MutationPages/LiveForm";
import * as yup from "yup";
import {
  useCreateLiveCourseMutation,
  useGetLiveCourseQuery,
  useUpdateLiveCourseMutation,
} from "../services/liveCourses";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  title: yup.string().required("اسم الدورة مطلوب"),
  topic_id: yup.string().required("قسم الدورة مطلوب"),
  start_date: yup.string().required(" تاريخ الدورة مطلوب"),
  start_at: yup.string().required(" توقيت بدأ الدورة مطلوب"),
  end_at: yup.string().required(" توقيت انتهاء الدورة مطلوب"),
  preorder_course_id: yup.string().required(" دورة الباقي مطلوب"),
  promo_video_url: yup.string().required("الفيديو التسويقي للدورة مطلوب"),
});

const AddNewLiveCourse = () => {
  const navigate = useNavigate();
  const { courseSlug } = useParams();
  const { data: courseInfo } = useGetLiveCourseQuery(courseSlug);
  const [imageUploaded, setimageUploaded] = useState(false);
  const [submmiting, setSubmmiting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [createNewLiveCourse] = useCreateLiveCourseMutation();
  const [editMode, setEditMode] = useState(
    courseSlug == undefined ? false : true
  );
  const [updateLiveCourse] = useUpdateLiveCourseMutation();

  const sendToReview = async () => {
    const Create_data = new FormData();
    Create_data.append("[course][status]", "pending");
    const response = await updateLiveCourse({
      id: courseInfo?.data?.id,
      body: Create_data,
    });
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("تم إرسال الدورة للمراجعة");
      navigate("/dashboard");
    }
  };

  const handleDateFormat = (day, values) => {
    if (typeof day == "string" && day.includes("/")) {
      const date = day.split("/");
      date[1] = (date[1] - 1).toString().padStart(2, "0");
      values.start_at.set("year", date[2]);
      values.start_at.set("month", date[1]);
      values.start_at.set("date", date[0]);
      values.end_at.set("year", date[2]);
      values.end_at.set("month", date[1]);
      values.end_at.set("date", date[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: courseInfo?.data?.title || "",
      topic_id: courseInfo?.data?.topic ? courseInfo?.data?.topic.id : "",
      topicName: courseInfo?.data?.topic ? courseInfo?.data?.topic.title : "",
      broadcast_url: courseInfo?.data?.broadcast_url || "",
      key_points: courseInfo?.data?.key_points || [""],
      description: courseInfo?.data?.description || "",
      price: courseInfo?.data?.price || "0.0",
      promo_video_url: courseInfo?.data?.promo_video_url || "",
      preorder_course_id: courseInfo?.data?.preorder_course_id || "",
      start_date: courseInfo?.data?.start_at
        ? new Date(courseInfo?.data?.start_at)
        : "",
      start_at: courseInfo?.data?.start_at
        ? moment(new Date(courseInfo?.data?.start_at))
        : "",
      end_at: courseInfo?.data?.end_at
        ? moment(new Date(courseInfo?.data?.end_at))
        : "",
      image: courseInfo?.data?.image_url || null,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors = {};
      if (
        values.description === "" ||
        values.description === " " ||
        values.description === undefined ||
        values.description === "<p><br></p>" ||
        values.description === '<p class="ql-align-right"><br></p>'
      ) {
        errors.description = " النبذة عن الدورة مطلوبة";
      }

      // if (!imageUploaded && !editMode) {
      //   errors.image = "صورة الدورة مطلوبة";
      // }
      return errors;
    },

    onSubmit: async (values) => {
      setSubmmiting(true);
      let response;
      const create_data = new FormData();
      if (!imageUploaded && editMode) {
        delete values.image;
      } else {
        create_data.append("[course][image]", values.image);
      }
      handleDateFormat(values.start_date, values);
      create_data.append("[course][title]", values.title);
      create_data.append("[course][topic_id]", values.topic_id);
      create_data.append("[course][broadcast_url]", values.broadcast_url);
      values.key_points.forEach((item, index) => {
        create_data.append("[course]key_points[]", item);
      });
      create_data.append("[course][price]", values.price);
      create_data.append("[course][description]", values.description);
      create_data.append("[course][start_at]", values.start_at);
      create_data.append("[course][end_at]", values.end_at);
      create_data.append("[course][promo_video_url]", values.promo_video_url);
      create_data.append(
        "[course][preorder_course_id]",
        values.preorder_course_id
      );
      if (editMode) {
        response = await updateLiveCourse({
          id: courseInfo?.data?.id,
          body: create_data,
        });
      } else {
        response = await createNewLiveCourse(create_data);
      }
      if (response?.error) {
        toast.error(response?.error?.data?.message);
      } else {
        toast.success("تم الحفظ بنجاح");
        navigate("/dashboard");
      }
      setSubmmiting(false);
    },
  });

  return (
    <div>
      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="left-side">
            <h1 className="title-page mt-1">إنشاء دورة مباشرة</h1>
          </div>

          <form
            className="content-form mt-3 mt-md-0 pb-20"
            onSubmit={(e) => {
              formik.handleSubmit(e);
              e.preventDefault();
            }}
          >
            <div className="right-side">
              <ul className="list-filter">
                <li>
                  <button
                    type="submit"
                    className="btn btn-main-color font-15 px-30 font-extrabold shadow-none"
                    disabled={submmiting}
                    onClick={() => setIsSaving(true)}
                  >
                    حفظ الدورة
                    {submmiting && (
                      <Spinner
                        style={{
                          marginRight: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn btn-main-color-border font-15 px-18 font-extrabold shadow-none mx-10"
                    disabled={
                      editMode && courseInfo?.data?.status == "draft"
                        ? false
                        : true
                    }
                    onClick={() => {
                      sendToReview();
                    }}
                  >
                    إرسال للمراجعة
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn btn-main-color-border font-24 px-10 shadow-none "
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_WEB_APP_URL}/webinar/${courseInfo?.data?.slug}?preview=true`;
                    }}
                  >
                    <i className="far fa-eye fa-fw fonr-extrabold"></i>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
      <section className="container my-30">
        <LiveForm
          formik={formik}
          imageUploaded={imageUploaded}
          setimageUploaded={setimageUploaded}
          submittingController={{ isSaving, setIsSaving }}
        />
      </section>
    </div>
  );
};
export default AddNewLiveCourse;
