import { useState } from "react";
import Modal from "./Modal";
import RadioGroup from "../Inputs/RadioGroup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
const CreateNewCourseModal = ({ isOpened, setIsOpened }) => {
  const [closeModal, setCloseModal] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      type: "recorded",
    },
    onSubmit: async (values) => {
      if (values.type == "recorded") {
        navigate("/courses/create");
      } else {
        navigate("/live-courses/create-live-course");
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
        setIsOpened(false);
      }}
    >
      <Modal
        id="create-new-course-modal"
        title={"إنشاء دورة جديدة"}
        subTitle={"من فضلك قم باختيار نوع الدورة المراد إنشائها"}
        mainBtn={"استمرار"}
        mainBtnOnclick={() => {
          document.getElementById("submit-btn").click();
        }}
        className="invite-employee-modal"
        maxWidth="500"
        closeModal={closeModal}
        onClose={() => {
          setCloseModal(true);
        }}
        isOpened={isOpened}
        hasCloseBtn
      >
        <RadioGroup
          values={[
            {
              id: 1,
              text: "دورة مباشرة",
              subtText: "",
              groupName: "type",
              value: "live",
            },
            {
              id: 2,
              text: "دورة مسجلة",
              subtText: "",
              groupName: "type",
              value: "recorded",
            },
          ]}
          formik={formik}
        />
        <button
          type="submit"
          id="submit-btn"
          style={{ display: "hidden" }}
        ></button>
      </Modal>
    </form>
  );
};
export default CreateNewCourseModal;
