import CourseBriefSection from "../Courses/MutationPages/Tabs/sections/CourseBriefSection";
import WhatWillYouLearnSection from "../Courses/MutationPages/Tabs/sections/WhatWillYouLearnSection";
import BookInfoSection from "./BookInfoSection";

const BookDetails = ({ values, form, setCoverUpload, setAttachmentUpload }) => {
  return (
    <div className="create-course layout-page">
      <BookInfoSection
        values={values}
        form={form}
        setCoverUpload={setCoverUpload}
        setAttachmentUpload={setAttachmentUpload}
      />
      <WhatWillYouLearnSection
        key_points={form.values.key_points}
        handleChange={form.handleChange}
        form={form}
        type="book"
      />
      <CourseBriefSection
        description={form.values.description}
        handleChange={form.setFieldValue}
        error={form.errors}
        touched={form.touched}
        type="book"
      />
    </div>
  );
};
export default BookDetails;
