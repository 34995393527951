import React from "react";

const Radio = ({ children, id, name, value, setValue, className }) => {
  return (
    <div
      className={`form-check checkbox-main ${className}`}
      onChange={(e) => setValue(value)}
    >
      <input
        type="radio"
        id={id}
        className="form-check-input form-check-input--radio"
        name={name}
        value={value}
        // defaultChecked={defaultChecked}
        // onChange={() => setChecked(!checked)}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

export default Radio;
