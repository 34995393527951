import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../Inputs/Avatar";
import { getCouponStatus } from "../../utils/helpers";
import moment from "moment";
import { useGetRevenuesQuery } from "../../services/revenues";
import Pagination from "../Pagination";

const tempData = [
  {
    name: "عيد تدرب السنوي",
    discount_amount: 20,
    type: "precentage",
    usage_count: 136,
    expiry_date: "14/02/2022",
    code_status: "Expired",
    code: "TADARAB",
  },
  {
    name: "السنة الجديدة",
    discount_amount: 30,
    type: "precentage",
    usage_count: 10789,
    expiry_date: "14/02/2022",
    code_status: "Active",
    code: "NEWYEAR2022",
  },
  {
    name: "كوبون ابدأ التعلم",
    discount_amount: 10,
    type: "precentage",
    usage_count: 456842,
    expiry_date: "14/02/2022",
    code_status: "Active",
    code: "LEARN20",
  },
];

const OperationHistoryTable = ({ usersList }) => {
  const [page, setPage] = useState(1);
  const { data: revenuesHistory } = useGetRevenuesQuery(
    {
      page: page,
      per_page: 10,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <>
      <Table className="table table-admin">
        <Thead className="table-header">
          <Tr>
            <Th className="font_12 font-color-grey font-bold">قيمة العملية</Th>
            <Th className="font_12 font-color-grey font-bold">حصة تابعة</Th>
            <Th className="font_12 font-color-grey font-bold">ربحك</Th>
            <Th className="font_12 font-color-grey font-bold">اسم الدورة</Th>
            <Th className="font_12 font-color-grey font-bold">اسم المستخدم</Th>
            <Th className="font_12 font-color-grey font-bold">التاريخ</Th>
          </Tr>
        </Thead>
        <Tbody className="table__content">
          {revenuesHistory?.data?.length
            ? revenuesHistory?.data?.map((element, key) => {
                let accountStatus = getCouponStatus(element?.status);
                return (
                  <>
                    <Tr key={key}>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.total_value} دك
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.type == "Revenue::AffiliationRevenue"
                              ? element?.trainer_cut
                              : 0}{" "}
                            دك
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.type == "Revenue::AffiliationRevenue"
                              ? 0
                              : element?.trainer_cut}{" "}
                            دك
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.course?.title}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.user?.name}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {moment(new Date(element?.created_at)).format(
                              "DD/MM/YYYY,hh:mmA"
                            )}
                          </span>
                        </div>
                      </Td>
                    </Tr>
                  </>
                );
              })
            : null}
        </Tbody>
      </Table>
      <Pagination
        current={revenuesHistory?.pagination?.current}
        pages={revenuesHistory?.pagination?.pages}
        handlePagination={(page) => setPage(page)}
      />
    </>
  );
};

export default OperationHistoryTable;
