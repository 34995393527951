import React from "react";
import "./change-password-modal.scss";
import PasswordInputFloat from "../../Inputs/PasswordInputFloat";
import { useFormik } from "formik";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../services/users";

const ChangePasswordModal = ({ userId, onClose }) => {
  const [ResetUserPassword] = useResetUserPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      console.log("submitted");
      console.log(values);
      const passwordData = new FormData();
      try {
        passwordData.append("user[password]", values.password);
        passwordData.append(
          "user[password_confirmation]",
          values.confirmPassword
        );
        await ResetUserPassword(userId, passwordData);

        resetForm();
        onClose();
      } catch (error) {
        console.log(error);
        return;
      } finally {
        setSubmitting(false);
      }
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(3, "كلمة المرور يجب ان تكون مكونة من 3 حروف كحد ادني")
        .required("كلمة المرور مطلوبة"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "الكلمتان غير متطابقتان")
        .required("اعادة كلمة المرور مطلوبة"),
    }),
  });

  return (
    <div className="change-password-modal">
      <p className="change-password-modal__title">تغيير كلمة المرور</p>
      <p className="change-password-modal__text">
        يمكنك تغيير كلمة المرور الخاص بحساب المستخدم{" "}
      </p>
      <form
        className="change-password-modal__form"
        onSubmit={formik.handleSubmit}
      >
        <div className="change-password-modal__form__input">
          <PasswordInputFloat
            name="password"
            label="كلمة المرور الجديدة"
            formik={formik}
            onChange={formik.handleChange}
            error={formik.errors.password}
            touched={formik.touched.password}
            helperText={formik.errors.password}
            value={formik.values.password}
          />
        </div>
        <div className="change-password-modal__form__input">
          <PasswordInputFloat
            name="confirmPassword"
            label="تأكيد كلمة المرور الجديدة"
            formik={formik}
            onChange={formik.handleChange}
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            helperText={formik.errors.confirmPassword}
            value={formik.values.confirmPassword}
          />
        </div>
        <div className="change-password-modal__buttons">
          <button className="btn btn-main-color" type="submit">
            تغيير كلمة المرور
          </button>
          <button
            className="btn btn-grey-color-border"
            type="button"
            onClick={onClose}
          >
            الغاء
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordModal;
