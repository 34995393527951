import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichText = ({ placeholder, htmlContent, setHtmlContent }) => {
  const [content, setContent] = useState(htmlContent ? htmlContent : "");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }, { font: [] }],
      [{ size: [] }],
      [{ align: [] }, { direction: "auto" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      // ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleChange = (html) => {
    setHtmlContent(html);
    setContent(html);
  };

  return (
    <ReactQuill
      theme="snow"
      onChange={handleChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      value={content}
      className="quill-editor-field"
    />
  );
};

export default RichText;
