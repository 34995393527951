import { useState, useEffect } from "react";
import InputFieldFloat from "../../../../../components/Inputs/InputFieldFloat";
import ImageInput from "../../../../../components/Inputs/ImageInput";
import { useGetMainTopicsQuery } from "../../../../../services/topics";

const InfoSection = ({
  values,
  error,
  touched,
  form,
  setimageUploaded,
  imageUploaded,
  submittingController,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const { data: topicsList } = useGetMainTopicsQuery();
  const [topicsSelected, setTopicsSelected] = useState(values.topic_id);
  const [topicSelectedName, setTopicSelectedName] = useState(values.topicName);
  const statusOptions = ["pending", "draft", "published", "unlisted"];
  const [status, setStatus] = useState(values.status);

  const addFile = (e) => {
    if (e?.target?.files[0]) {
      form.setFieldValue("image", e?.target?.files[0]);
      setimageUploaded(true);
    }
  };

  const topicSelected = (id) => {
    if (id) {
      const topic = topicsList.data.filter((topic) => topic.id == id);
      if (
        values.topic_id.filter((topic) => topic == parseInt(id)).length == 0
      ) {
        setTopicsSelected([...values.topic_id, parseInt(id)]);
        setTopicSelectedName([...values.topicName, topic[0].title]);
        const temp = document.getElementById(`option-${id}`);
        temp.style.background = "#ffcccb";
      } else {
        setTopicsSelected(
          values.topic_id.filter((topic) => topic != parseInt(id))
        );
        setTopicSelectedName(
          values.topicName.filter((name) => name != topic[0].title)
        );
        const temp = document.getElementById(`option-${id}`);
        temp.style.background = "transparent";
      }
    }
  };

  useEffect(() => {
    form.setFieldValue("topic_id", topicsSelected);
    form.setFieldValue("topicName", topicSelectedName);
  }, [topicsSelected]);

  useEffect(() => {
    setStatus(values.status);
  }, [values.status]);

  const mapTopicsList = () => {
    return topicsList?.data?.map((topic) => (
      <li
        id={`option-${topic.id}`}
        style={{
          background: `${
            values?.topic_id?.includes(topic.id) ? "#ffcccb" : "transparent"
          }`,
        }}
      >
        <p
          style={{ display: "flex" }}
          value={topic.id}
          className="checkbox dropdown-item"
        >
          {topic?.title}
        </p>
      </li>
    ));
  };

  const courseStatuses = () => {
    return statusOptions?.map((state) => (
      <li
        style={{
          background: `${values?.status == state ? "#ffcccb" : "transparent"}`,
        }}
      >
        <p
          style={{ display: "flex" }}
          value={state}
          className="checkbox dropdown-item"
        >
          {state}
        </p>
      </li>
    ));
  };

  return (
    <div className="mb-4 create-section create-section--info">
      <div className="d-flex justify-content-between">
        <h2 className="font_20 font-extrabold create-section__title">
          معلومات الدورة
        </h2>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>

      {sectionVisiblity && (
        <div>
          <div className="row">
            <div className="col">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                اسم الدورة
              </label>
              <InputFieldFloat
                type="text"
                name="title"
                value={values.title}
                onChange={(e) => form.setFieldValue("title", e?.target?.value)}
                error={error?.title}
                touched={touched?.title}
                marginBottom={24}
                placeholder="اكتب اسم الدورة هنا ..."
                disabled={disabled}
              />
            </div>
            <div className="col input-text">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                القسم
              </label>
              <button
                type="button"
                className="multiselect dropdown-toggle did-floating-input"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                multiple
                aria-label="multiple select example"
                disabled={disabled}
              >
                {values?.topicName.length > 0
                  ? values?.topicName?.join(" ، ")
                  : ""}
              </button>
              <ul
                role="tablist"
                aria-multiselectable={true}
                className="dropdown-menu multiselect-container"
                onClick={(e) => {
                  e.stopPropagation();
                  topicSelected(e?.target?.attributes?.value?.value);
                }}
              >
                {mapTopicsList()}
              </ul>
              {form.errors.topic_id && form.touched.topic_id ? (
                <div className="input-error">{form.errors.topic_id}</div>
              ) : null}
            </div>
          </div>

          <div className="flex-column">
            <div className="row">
              <div
                className="col-6 col-md-6"
                style={{ display: "inline-block" }}
              >
                <label className="font-color-grey-500 font_16 lh-17 mb-13">
                  {" "}
                  الفيديو التسويقي للدورة
                </label>
                <InputFieldFloat
                  name="promo_video"
                  value={values?.promo_video}
                  onChange={(e) =>
                    form.setFieldValue("promo_video", e?.target?.value)
                  }
                  disabled={disabled}
                  error={error?.promo_video}
                  touched={touched?.promo_video}
                  marginBottom={24}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-4 create-section--info__upload-image">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                صورة الدورة (cover photo)
              </label>
              <ImageInput
                id="course-cover-img"
                name="image"
                details="أرفع صورة الغلاف للدورة"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => addFile(e)}
                imageUrl={
                  values?.image != undefined
                    ? typeof values?.image === "object"
                      ? URL.createObjectURL(values?.image)
                      : values?.image
                    : ""
                }
                error="صورة الدورة مطلوبة"
                touched={!imageUploaded && submittingController.isSaving}
                className={`w-100`}
                disabled={disabled}
              />
              <p
                style={
                  !imageUploaded && submittingController.isSaving
                    ? { marginTop: "45px" }
                    : { marginTop: "0px" }
                }
                className="font_12 font-color-grey-light mb-0"
              >
                يجب ان تكون بجودة عالية 1200 * 800 على الأقل
              </p>
              <p className="mb-0 font_12 font-color-grey">
                <i className="fas fa-info-circle me-2" />
                يجب ان تكون الصورة اقل من 2MB
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="font-color-grey-500 font_16 lh-17 mb-13">
                  سعر الدورة
                </label>
                <InputFieldFloat
                  type="text"
                  name="price"
                  value={values.price}
                  onChange={(e) =>
                    form.setFieldValue("price", e?.target?.value)
                  }
                  error={error?.price}
                  touched={touched?.price}
                  marginBottom={0}
                  disabled={disabled}
                >
                  <span class="input-group-append currency-field">KWD</span>
                </InputFieldFloat>
              </div>
              {!disabled ? (
                <div className="col-md-6 input-text">
                  <label className="font-color-grey-500 font_16 lh-17 mb-13">
                    حالة الدورة
                  </label>
                  <button
                    type="button"
                    className="multiselect dropdown-toggle did-floating-input"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    multiple
                    aria-label="multiple select example"
                  >
                    {status}
                  </button>
                  <ul
                    role="tablist"
                    aria-multiselectable={true}
                    className="dropdown-menu multiselect-container"
                    style={{ direction: "ltr" }}
                    onClick={(e) => {
                      if (e?.target?.attributes?.value?.value)
                        form.setFieldValue(
                          "status",
                          e?.target?.attributes?.value?.value
                        );
                    }}
                  >
                    {courseStatuses()}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InfoSection;
