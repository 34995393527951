import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import UploadProfile from "../components/Inputs/UploadProfile";
import InputFieldFloat from "../components/Inputs/InputFieldFloat";
//import InputTextAreaFloat from "../components/Inputs/InputTextAreaFloat";

import InputSelectFloat from "../components/Inputs/InputSelectFloat";
// import { useListCountriesQuery } from "../services/public/countries";
// import { useListDepartmentsQuery } from "../services/public/departments";

import Spinner from "../components/Spinner";
//import { useTranslation } from "react-i18next";
import InputRichTextAreaFloat from "../components/Inputs/InputRichTextAreaFloat";
import {
  useUpdateProfileMutation,
  useShowProfileQuery,
} from "../services/auth";
import { useListCountriesQuery } from "../services/countries";

// import "../scss/pages/_my-profile.scss";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EditProfile = () => {
  const { data: profile } = useShowProfileQuery();
  const [updateProfile] = useUpdateProfileMutation();

  const validationSchema = yup.object({
    arabic_name: yup.string().required("خانة الاسم مطلوبة"),
    name: yup.string().required("خانة الاسم مطلوبة"),
    phone: yup
      .string()
      .matches(phoneRegExp, "رقم الهاتف غير صحيح")
      .required("خانة رقم الهاتف مطلوبة"),
    country_id: yup.number(),
    bio: yup.string().notRequired(),
    facebook: yup.string().notRequired(),
    linkedin: yup.string().notRequired(),
    twitter: yup.string().notRequired(),
    snapchat: yup.string().notRequired(),
    instagram: yup.string().notRequired(),
  });

  // const [cities, setCities] = useState([]);
  const [image, setImage] = useState(null);
  // const [skill, setSkill] = useState("");
  const [submmiting, setSubmitting] = useState(false);
  // const { data: countriesList } = useListCountriesQuery();
  const { data: countriesList } = useListCountriesQuery();

  //const { t } = useTranslation(["common", "employees"]);

  const formik = useFormik({
    initialValues: {
      arabic_name: profile?.data?.name_ar,
      name: profile?.data?.name_en,
      phone: profile?.data?.phone,
      country_id: profile?.data?.country?.id ? profile?.data?.country?.id : 0,
      bio: profile?.data?.bio,
      facebook: profile?.data?.facebook_url,
      linkedin: profile?.data?.linkedin_url,
      twitter: profile?.data?.twitter_url ? profile?.data?.twitter_url : "",
      snapchat: profile?.data?.snapchat_url,
      instagram: profile?.data?.instagram_url,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setSubmitting(true);
      const profileData = new FormData();
      profileData.append("name_ar", values?.arabic_name);
      profileData.append("name_en", values?.name);
      profileData.append("phone", values?.phone);
      profileData.append("bio", values?.bio);
      if (values?.country_id !== "0") {
        profileData.append("country_id", values?.country_id);
      }
      profileData.append("instagram_url", values?.instagram);
      profileData.append("twitter_url", values?.twitter);
      profileData.append("facebook_url", values?.facebook);
      profileData.append("linkedin_url", values?.linkedin);
      profileData.append("snapchat_url", values?.snapchat);
      if (image) profileData.append("avatar", image);
      const result = await updateProfile(profileData);
      setSubmitting(false);
      if (!result.error) {
        toast.success("تم التعديل بنجاح");
      } else {
        toast.error(result?.error?.data?.message);
      }
    },
  });

  return (
    <div className="layout-page profile-page">
      <div className="content-header">
        <h1 className="font_24 font-extrabold">تعديل الصفحة الشخصية</h1>
      </div>
      <form
        className="content-form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <div className="row">
          <h2 className="section_label font_20 font-extrabold">
            البيانات الشخصية
          </h2>
          <div className="d-flex mb-4">
            <UploadProfile
              avatarUrl={profile?.data?.avatar_url}
              image={image}
              setImage={setImage}
            />
            <div className="d-flex flex-column  justify-content-center mx-3">
              <h4 className="grey_title"> تغير الصورة الشخصية </h4>
              <p className="small_hint">من فضلك استخدم صورة حديثة تعبر عنك</p>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <InputFieldFloat
              id="arabic_name_profile"
              label={"الاسم (بالعربية)"}
              type="text"
              name="arabic_name"
              value={formik.values.arabic_name}
              onChange={formik.handleChange}
              error={formik.errors.arabic_name}
              touched={formik.touched.arabic_name}
              disabled={true}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <InputFieldFloat
              id="english_name_profile"
              label={"الاسم (بالإنجليزية)"}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              touched={formik.touched.name}
              disabled={true}
            />
          </div>

          <div className="col-lg-6 col-sm-12">
            <InputFieldFloat
              id="phone_number_profile"
              label={"رقم الموبايل"}
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
            />
          </div>

          <div className="col-lg-6 col-sm-12">
            <InputSelectFloat
              label={"البلد"}
              type="text"
              name="country_id"
              value={formik.values.country_id}
              onChange={formik.handleChange}
              error={formik.errors.country_id}
              touched={formik.touched.country_id}
            >
              <option value="0">{"إختر البلد"}</option>
              {countriesList?.data?.map((country) => (
                <option value={country?.id}>{country?.name}</option>
              ))}
            </InputSelectFloat>
          </div>
        </div>

        <div>
          <h2 className="section_label font_20 font-extrabold">
            {" "}
            السيرة الذاتية{" "}
          </h2>
          <InputRichTextAreaFloat
            label={"اكتب نبذه عنك"}
            type="text"
            id="bio"
            name="bio"
            value={formik.values.bio}
            setFieldValue={formik.setFieldValue}
            error={formik.errors.bio}
            touched={formik.touched.bio}
          />

          {/* <InputTextAreaFloat
            label={"اكتب نبذه عنك"}
            type="text"
            name="bio"
            rows={4}
            hint={``}
            value={formik.values.bio}
            onChange={formik.handleChange}
            error={formik.errors.bio}
            touched={formik.touched.bio}
          /> */}
        </div>

        <div className="row">
          <h2 className="section_label font_20 font-extrabold">
            عناوين التواصل الإجتماعي
          </h2>

          <InputFieldFloat
            id="facebook_input_profile"
            label={"الفيسبوك"}
            type="url"
            name="facebook"
            value={formik.values.facebook}
            onChange={formik.handleChange}
            error={formik.errors.facebook}
            touched={formik.touched.facebook}
          />
          <InputFieldFloat
            id="linkedin_input_profile"
            label={"لينكدان"}
            type="url"
            name="linkedin"
            value={formik.values.linkedin}
            onChange={formik.handleChange}
            error={formik.errors.linkedin}
            touched={formik.touched.linkedin}
          />
          <InputFieldFloat
            id="twitter_input_profile"
            label={"تويتر"}
            type="url"
            name="twitter"
            value={formik.values.twitter}
            onChange={formik.handleChange}
            error={formik.errors.twitter}
            touched={formik.touched.twitter}
          />
          <InputFieldFloat
            id="snapchat_input_profile"
            label={"سناب شات"}
            type="url"
            name="snapchat"
            value={formik.values.snapchat}
            onChange={formik.handleChange}
            error={formik.errors.snapchat}
            touched={formik.touched.snapchat}
          />
          <InputFieldFloat
            id="instagram_input_profile"
            label={"إنستاجرام"}
            type="url"
            name="instagram"
            value={formik.values.instagram}
            onChange={formik.handleChange}
            error={formik.errors.instagram}
            touched={formik.touched.instagram}
          />
        </div>
        {profile?.data?.affiliator && profile?.data?.referral_code ? (
          <div className="row">
            <h2 className="section_label font_20 font-extrabold">
              الرابط التابع
            </h2>
            <InputFieldFloat
              id="affiliator_link"
              label={"الرابط التابع"}
              value={`${process.env.REACT_APP_WEB_APP_URL}?aid=${profile?.data?.referral_code}`}
              disabled={true}
            />
          </div>
        ) : (
          <></>
        )}
        <button
          type="submit"
          className="btn btn-main-color btn-size-lg mb-3 mb-md-0 mx-auto mx-md-0"
          //   disabled={!checked}
          disabled={submmiting}
        >
          حفظ البيانات
          {submmiting && (
            <Spinner
              style={{ marginRight: "10px", width: "20px", height: "20px" }}
            />
          )}
        </button>
      </form>
    </div>
  );
};

export default EditProfile;
