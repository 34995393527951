import { useState } from "react";
import InputRichTextAreaFloat from "../../../../../components/Inputs/InputRichTextAreaFloat";
const CourseBriefSection = ({
  description,
  handleChange,
  error,
  touched,
  type,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  return (
    <div className="mb-4 create-section">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font_20 font-extrabold create-section__title">
          {type == "book" ? "نبذة عن  الكتيب" : "نبذة عن الدورة "}
        </h2>
        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <InputRichTextAreaFloat
          label="اكتب هنا..."
          type="text"
          id="CourseBrief"
          name="description"
          value={description}
          setFieldValue={handleChange}
          error={error?.description}
          touched={touched?.description}
          disabled={disabled}
        />
      )}
    </div>
  );
};
export default CourseBriefSection;
