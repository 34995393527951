import PropTypes from "prop-types";

const Dropdown = ({
  dropdownClassName,
  toggleBtnClassName,
  toggleLabel,
  toggleElement,
  hasToggleArrowDown,
  children,
  dropdownListClassName
}) => {
  return (
    <div className={`dropdown navbar-dropdown ${dropdownClassName}`}>
      <button
        className={`border-0 navbar-dropdown__toggle ${toggleBtnClassName}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {toggleLabel}
        {toggleElement}
        {hasToggleArrowDown && <i className="fas fa-chevron-down ms-2"></i>}
      </button>
      <ul className={`dropdown-menu ${dropdownListClassName}`}>{children}</ul>
    </div>
  );
};

export default Dropdown;
Dropdown.prototype = {
  dropdownListClassName: PropTypes.string,
  hasToggleArrowDown: PropTypes.bool
}

Dropdown.defaultProps = {
  dropdownClassName: "",
  toggleBtnClassName: "",
  toogleLabel: "",
  toggleElement: null,
  hasToggleArrowDown: false,
  dropdownListClassName: "",
};
