import { FieldArray } from "formik";
import { useState } from "react";
import InputFieldFloat from "../../../../Inputs/InputFieldFloat";

const FAQSection = ({ faqs, handleChange, form, disabled }) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  return (
    <div className="mb-4 create-section create-section--education">
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2 className="font_20 font-extrabold ">
            الاسئلة الشائعة لهذة الدورة؟
          </h2>
          <p className="font_16 font-color-grey mb-4">اضف كل سؤال منفصلا</p>
        </div>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <FieldArray name="array" validateOnChange={false}>
          <>
            {faqs?.map((faq, index) => {
              return (
                <div className="row">
                  <div className="col-6">
                    <InputFieldFloat
                      type="text"
                      name={`faqs.${index}.q`}
                      value={faq?.q}
                      onChange={(e) => handleChange(e)}
                      marginBottom={12}
                      additionalStyle={
                        faqs.length > 1 ? { paddingLeft: "42px" } : {}
                      }
                      placeholder="اكتب السؤال هنا..."
                      disabled={disabled}
                    ></InputFieldFloat>
                  </div>
                  <div className="col-6">
                    <InputFieldFloat
                      type="text"
                      name={`faqs.${index}.a`}
                      value={faq?.a}
                      onChange={(e) => handleChange(e)}
                      marginBottom={12}
                      additionalStyle={
                        faqs.length > 1 ? { paddingLeft: "42px" } : {}
                      }
                      placeholder="اكتب الاجابة هنا..."
                      disabled={disabled}
                    >
                      {faqs.length > 1 && (
                        <button
                          className="flex align-items-center justify-content-between remove-btn"
                          id="deleteDepartmentButton"
                          type="button"
                          onClick={() => {
                            let temp = [...faqs];
                            temp.splice(index, 1);
                            form.setFieldValue("faqs", temp);
                          }}
                          hidden={disabled}
                        >
                          <i className="fa-regular fa-trash-can font-color-grey-600 font_20" />
                        </button>
                      )}
                    </InputFieldFloat>
                  </div>
                </div>
              );
            })}

            <button
              className="font_15 text-link-red px-0 font-regular"
              type="button"
              onClick={() => {
                form.setFieldValue("faqs", [
                  ...faqs,
                  {
                    q: "",
                    a: "",
                  },
                ]);
              }}
              hidden={disabled}
            >
              <span className="fa fa-plus mx-2" />
              اضف سؤال جديد
            </button>
          </>
        </FieldArray>
      )}
    </div>
  );
};
export default FAQSection;
