import PropTypes from "prop-types";
import Dropdown from "../DropDown";
import {
  useDeleteBookMutation,
  useUpdateBookMutation,
} from "../../services/books";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const BookCard = ({ book }) => {
  const [deleteBook] = useDeleteBookMutation();
  const [updateBook] = useUpdateBookMutation();
  const handleDeleteBook = async () => {
    const result = await deleteBook({ id: book?.id });
    if (result.error) {
      toast.error(result?.error?.data?.message);
    } else {
      toast.success("تم حذف الكتيب بنجاح");
    }
  };
  const handleChangeBookStatus = async () => {
    const value = document.getElementById(
      `flexSwitchCheckDefault-${book?.id}`
    ).checked;
    let result;
    if (value) {
      result = await updateBook({
        id: book.id,
        body: { book: { status: "published" } },
      });
    } else {
      result = await updateBook({
        id: book.id,
        body: { book: { status: "unlisted" } },
      });
    }
    if (result.error) {
      toast.error(result?.error?.data?.message);
    } else {
      toast.success("تم تغيير حالة عرض الكتيب بنجاح");
    }
  };
  return (
    <div className="card border-0 book-card mb-3" key={book?.id}>
      <div className="card-header bg-transparent border-0 p-0 ">
        <img
          src={book?.cover_url}
          alt={book?.title}
          className="border-radius-10 shadow-3 book-card__image"
          height={300}
          width={211}
        />

        <Dropdown
          dropdownClassName="book-card__info"
          toggleElement={
            <span className="book-card__info__btn mb-10">
              <i className="fa-solid fa-ellipsis" />
            </span>
          }
          dropdownListClassName="py-12 px-19 book-card__info__list mt-10"
        >
          <li className="mb-3">
            <Link to={`/books/${book?.id}/edit`}>
              <i className="fa-regular fa-pen-to-square font_17 font-color-grey-200 me-15 " />
              <span className="font_14 font-bold font-color-dark">
                تعديل الكتيب
              </span>
            </Link>
          </li>

          <li
            onClick={() => {
              handleDeleteBook();
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fa-regular fa-trash-can font_17 font-color-grey-200 me-15" />
            <span className="font_14 font-bold font-color-dark">
              {" "}
              حذف الكتيب
            </span>
          </li>

          <hr className="mt-23 mb-15" />
          <li className="d-flex align-items-center justify-content-between">
            <span className="font_14 font-bold font-color-dark">
              {" "}
              عرض الكتيب
            </span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={`flexSwitchCheckDefault-${book?.id}`}
                onChange={() => {
                  handleChangeBookStatus();
                }}
                defaultChecked={book?.status == "published" ? true : false}
              />
            </div>
          </li>
        </Dropdown>
      </div>
      <div className="card-body p-18" style={{ width: "211px" }}>
        <h5 className="font_17 font-extrabold">{book?.title}</h5>
        <p className="font-color-grey-500 font_16 mb-0">{book?.topic?.title}</p>
      </div>
    </div>
  );
};

export default BookCard;

BookCard.prototype = {
  book: PropTypes.shape({
    id: PropTypes.string,
    cover_url: PropTypes.string,
    topic: PropTypes.object,
    title: PropTypes.string,
  }).isRequired,
};
