import { useState, useEffect } from "react";
import { WithContext as ReactTags } from "react-tag-input";

const TagsSection = ({ form, disabled }) => {
  const [tags, setTags] = useState([]);
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.enter];
  const handleDelete = (i) => {
    form.setFieldValue(
      "tags",
      form.values.tags.filter((tag, index) => index !== i)
    );
  };

  const handleAddition = (tag) => {
    form.setFieldValue("tags", [...form.values.tags, tag.text]);
  };
  useEffect(() => {
    if (form?.values?.tags) {
      const tempTags = [];
      form?.values?.tags?.map((tag) => {
        if (tag != "") {
          tempTags.push({ id: tag, text: tag });
        }
      });
      setTags(tempTags);
    }
  }, [form?.values?.tags]);

  return (
    <div className="mb-4 create-section">
      <div className="d-flex align-items-start justify-content-between">
        <div className="create-section__title">
          <h2 className=" font_20 font-extrabold">الكلمات المفتاحية</h2>
          <p className="font_16 font-color-grey-light mb-0">
            يمكنك إضافة حتى 10 كلمات مفتاحية
          </p>
          <p className="font_12 font-color-grey-light mb-0">
            اضغط Enter بعد كتابة الكلمة المفتاحية لتسجيلها{" "}
          </p>
        </div>
        <button
          className="btn-link text-link-grey"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <div>
          <ReactTags
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            inputFieldPosition="inline"
            allowDeleteFromEmptyInput={true}
            autofocus={false}
            allowDragDrop={false}
            classNames={`${
              tags.length === 10 ? { tagInputField: "hide-input-tags" } : ""
            }`}
            placeholder="اكتب هنا..."
            readOnly={disabled}
          />
          {/*           {form.errors.tags && form.touched.tags ? (
            <div className="font-color-red font_14">{form.errors.tags}</div>
          ) : null} */}
        </div>
      )}
    </div>
  );
};
export default TagsSection;
