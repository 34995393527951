import { api } from "./index";

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/register`,
        method: "POST",
        body,
      }),
    }),

    login: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/login`,
        method: "POST",
        body,
      }),
    }),

    logout: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/logout`,
        method: "DELETE",
      }),
    }),
    sendResetPasswordEmail: builder.mutation({
      query: (body) => {
        return {
          url: "/api/v1/tutor/password",
          method: "POST",
          body,
        };
      },
    }),
    sendUpdatedPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/tutor/password",
        method: "PUT",
        body,
      }),
    }),

    showProfile: builder.query({
      query: () => `/api/v1/tutor/profile`,
      providesTags: ["Profile"],
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/profile`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),

    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: "/api/v1/tutor/profile/password",
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useSendResetPasswordEmailMutation,
  useSendUpdatedPasswordMutation,
  useShowProfileQuery,
  useLazyShowProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
} = auth;
