import { FieldArray } from "formik";
import { useState } from "react";
import InputFieldFloat from "../../../../Inputs/InputFieldFloat";
const WhatWillYouLearnSection = ({
  key_points,
  handleChange,
  form,
  type,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  return (
    <div className="mb-4 create-section create-section--education">
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2 className="font_20 font-extrabold ">
            {type == "book"
              ? "ماذا سوف تتعلم من الكتيب؟"
              : "ماذا سوف تتعلم في الدورة؟ "}
          </h2>
          <p className="font_16 font-color-grey mb-4">اضف كل نقطة منفصلة</p>
        </div>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <FieldArray name="array" validateOnChange={false}>
          <>
            {key_points?.map((point, index) => {
              return (
                <div>
                  <InputFieldFloat
                    type="text"
                    name={`key_points.${index}`}
                    value={point}
                    onChange={(e) => handleChange(e)}
                    marginBottom={12}
                    additionalStyle={
                      key_points.length > 1 ? { paddingLeft: "42px" } : {}
                    }
                    placeholder="اكتب هنا..."
                    disabled={disabled}
                  >
                    {key_points.length > 1 && (
                      <button
                        className="flex align-items-center justify-content-between remove-btn"
                        id="deleteDepartmentButton"
                        type="button"
                        onClick={() => {
                          let temp = [...key_points];
                          temp.splice(index, 1);
                          form.setFieldValue("key_points", temp);
                        }}
                        hidden={disabled}
                      >
                        <i className="fa-regular fa-trash-can font-color-grey-600 font_20" />
                      </button>
                    )}
                  </InputFieldFloat>
                </div>
              );
            })}

            <button
              className="font_15 text-link-red px-0 font-regular"
              type="button"
              onClick={() => {
                form.setFieldValue("key_points", [...key_points, ""]);
              }}
              hidden={disabled}
            >
              <span className="fa fa-plus mx-2" />
              اضف نقطة جديدة
            </button>
          </>
        </FieldArray>
      )}
    </div>
  );
};
export default WhatWillYouLearnSection;
