import React from "react";
import "./circular-progress.scss";

const CircularProgress = ({ percent, circleWidth }) => {
  const radius = 32;
  const dashArray = Math.PI * 2 * radius;
  return (
    <div style={{ position: "relative" }}>
      <svg
        width={circleWidth}
        height={circleWidth}
        viewBox={`0 0 ${circleWidth} ${circleWidth}`}
      >
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="8px"
          r={radius}
          className="circle-bg"
        />
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="8px"
          r={radius}
          className="circle-progress"
          style={{
            strokeDasharray: `${(percent / 100) * dashArray} ${dashArray}`,
          }}
        />
      </svg>
      <p className="percent">{percent}%</p>
    </div>
  );
};

export default CircularProgress;
