import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
// import "moment/locale/ar";
import { LANGUAGE_COOKIE_NAME, FALLBACK_LANGUAGE } from "./constants";

const setLanguageCookie = (lng) => {
  document.cookie = `${LANGUAGE_COOKIE_NAME}=${lng}`;
};

const setDocumentLang = (lng) => {
  const direction = lng === "ar" ? "rtl" : "ltr";
  const htmlTag = document.querySelector("html");

  // assign multiple attributes at once to an <html> tag
  Object.assign(htmlTag, { lang: lng, id: lng, dir: direction });
};

const getLanguageCookie = () => {
  const match = document.cookie.match(
    new RegExp(`(^| )${LANGUAGE_COOKIE_NAME}=([^;]+)`)
  );

  // returns fallback language if language cookie not found
  return match ? match[2] : FALLBACK_LANGUAGE;
};

i18n.on("initialized", (options) => {
  // sets the language cookie value on initialization to be read later
  setLanguageCookie("ar");
  // sets initial document language, id and direction
  setDocumentLang("ar");
  moment.locale("en");
});

i18n.on("languageChanged", (lng) => {
  // update language cookie on change
  setLanguageCookie(lng);
  // update document lang, direction and id attr for styling purpose
  setDocumentLang(lng);
  moment.locale("en");
});

i18n.use(initReactI18next).init({
  lng: getLanguageCookie(),
  fallbackLng: FALLBACK_LANGUAGE,
  supportedLngs: ["en", "ar"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
