import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogCard from "../components/Blogs/BlogCard/BlogCard";
import { toast } from "react-toastify";
import "../scss/blogs.scss";
import { useSelector } from "react-redux";
import { useDeleteBlogMutation, useGetBlogsQuery } from "../services/blogs";
import Pagination from "../components/Pagination";
import Search from "../components/Search/Search";
import { useGetSearchResultsQuery } from "../services/search";

const Blogs = () => {
  const currentUser = useSelector((state) => state?.auth?.currentUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [deleteBlog] = useDeleteBlogMutation();
  const { data: blogs } = useGetBlogsQuery({ page: currentPage });

  const handleDelete = async (blogId) => {
    await deleteBlog({ id: blogId });
    toast.success("تم حذف المقالة بنجاح", {
      autoClose: 3000,
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main className="blogs-page">
      <header className="blogs-page__header">
        <div className="blogs-page__header__title-subtitle">
          <p className="blogs-page__header__title">المقالات</p>
          <span className="blogs-page__header__subtitle">
            قم بإضافة مقالات الخاصة على منصات تدرب
          </span>
        </div>
        <div className="blogs-page__header__left-side">
          <Link to={"/blogs/create"} className="btn btn-main-color">
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              +
            </span>
            أضف مقالة جديدة
          </Link>
        </div>
      </header>

      {/* Blogs */}

      {/* <section className="blogs-page__search">
        <Search
          id={"search-blogs"}
          name={"search"}
          url={"blogs"}
          placeholder={"ابحث عن مقالة"}
          type={"blogs"}
          setSearchResults={setSearchResults}
        />
      </section> */}

      <section className="blogs-page__my-blogs-section">
        <h6>مقالاتي</h6>
        {/* blog card */}
        {blogs !== undefined ? (
          blogs?.data?.map((blog) => (
            <BlogCard
              key={blog.blog.id}
              item={blog}
              onDelete={() => handleDelete(blog.blog.id)}
            />
          ))
        ) : (
          <p>يتم تحميل البيانات</p>
        )}
      </section>
      {blogs?.pagination?.pages > 1 && (
        <Pagination
          current={blogs?.pagination?.current}
          handlePageClick={handlePageClick}
          pages={blogs?.pagination?.pages}
        />
      )}
    </main>
  );
};

export default Blogs;
