import React from "react";
import SocialLinks from "../components/PreviewBlog/SocialLinks/SocialLinks";
import TagWrapper from "../components/Blogs/TagWrapper/TagWrapper";
import "../scss/pages/preview-blog.scss";

const PreviewBlog = ({ data }) => {
  const { blog, blog_sections_attributes, image, tag_ids, topic_ids } = data;
  console.log(blog_sections_attributes);
  return (
    <div className="preview-blog-wrapper">
      <div className="preview-blog">
        <div className="preview-blog__content">
          <div className="preview-blog__content-hero-img">
            <img
              src={
                image === null
                  ? "/images/great-manager.png"
                  : URL.createObjectURL(image)
              }
              alt="blog-image"
              loading="lazy"
            />
          </div>
          {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            <TagWrapper bgColor={"red"} textColor={"#fff"} title={category} />
          </div> */}
          <div
            className="preview-blog__content-date"
            style={{ marginTop: "12px" }}
          >
            <span className="preview-blog__content-date__date">
              14 نوفمبر 2023
            </span>
            <span className="preview-blog__content-date__dot" />
            <span className="preview-blog__content-date__read">
              8 دقائق قراءة
            </span>
          </div>
          <p className="preview-blog__content-title">{blog.title}</p>
          <div className="preview-blog__content__author">
            <img
              src="/images/tadarab logo@2x.png"
              width={50}
              height={50}
              alt="كاتب المقالة"
            />
            <div>
              <span>تم الكتابة بواسطة</span>
              <div className="preview-blog__content__author-name">
                منصة تدرب التعليمية
              </div>
            </div>
          </div>
          <div
            className="preview-blog__content__description"
            dangerouslySetInnerHTML={{ __html: blog.introduction }}
          />
          <div
            className="preview-blog__content__description"
            dangerouslySetInnerHTML={{ __html: blog.conclusion }}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {tag_ids.map((tag, index) => (
              <TagWrapper
                key={index}
                bgColor={"#F2F2F2"}
                textColor={"#888888"}
                title={tag}
              />
            ))}
          </div>
          <div style={{ margin: "12px 0" }}>
            <img
              src="/images/best-trainers.png"
              alt="best trainers"
              width={800}
              height={500}
            />
          </div>
          <div>
            {blog_sections_attributes.map((section, index) => (
              <div
                key={index}
                className="preview-blog__content__description"
                dangerouslySetInnerHTML={{ __html: section.body }}
              />
            ))}
          </div>
          <div style={{ margin: "12px 0" }}>
            <img
              src="/images/related-blogs.png"
              alt="best trainers"
              width={"100%"}
              height={700}
            />
          </div>
        </div>
        <div className="social-links-wrapper">
          <SocialLinks />
        </div>
      </div>
    </div>
  );
};

export default PreviewBlog;
