import {api} from "./index";

export const categories = api.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => '/api/v1/public/topics',
            providesTags: ['Categories'],
        }),
        getMainCategories: builder.query({
            query: () => '/api/v1/public/topics/main',
            providesTags: ['Categories'],
        }),
        createCategory: builder.mutation({
            query: (body) => ({
                url: '/api/v1/topics',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Categories'],
        }),
        updateCategory: builder.mutation({
            query: ({ id, body }) => ({
                url: `/api/v1/topics/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Categories'],
        }),
        deleteCategory: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/topics/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Categories'],
        })
    }),
});

export const {
    useGetCategoriesQuery,
    useGetMainCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
} = categories