import React from "react";
import "./TextField.scss";

const TextField = ({
  id,
  label,
  formik,
  value,
  placeholder,
  error,
  errorMessage,
}) => {
  return (
    <div className="text-field">
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        name={id}
        type="text"
        className="text-field__input"
        placeholder={placeholder}
        value={value}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {error ? <div className="error">{errorMessage}</div> : null}
    </div>
  );
};

export default TextField;
