import React, { useRef, useState } from "react";
import "../../scss/components/imageupload.scss";
import { FaUpload } from "react-icons/fa6";

const ImageUpload = ({ id, url, formik, error, errorMessage }) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(
    url !== undefined
      ? url
      : formik?.values?.image === null
      ? null
      : URL.createObjectURL(formik?.values?.image)
  );

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    formik.setFieldValue(id, file);
    setImage(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue(id, file);
    setImage(URL.createObjectURL(file));
  };

  const handleEditClick = () => {
    formik.setFieldValue(id, null);
    setImage(null);
    fileInputRef?.current?.click();
  };

  const handleRemoveClick = () => {
    formik.setFieldValue(id, null);
    setImage(null);
  };

  return (
    <>
      <div
        className={`image-upload ${image ? "has-image" : ""}`}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {!image ? (
          <>
            <label htmlFor="fileInput" className="upload-label">
              <span aria-hidden="true">
                قم بالسحب و الاسقاط أو اضغط لرفع صورة المقالة
              </span>
              <FaUpload color="#ccc" size={60} />
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </>
        ) : (
          <div className="image-preview">
            <img src={image} alt="Uploaded Image" />
            <div className="remove-edit-image">
              <div className="image-actions">
                <button onClick={handleEditClick} type="button">
                  تعديل
                </button>

                <button onClick={handleRemoveClick} type="button">
                  حذف
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {error && <p className="error-message">{errorMessage}</p>}
    </>
  );
};

export default ImageUpload;
