import WhatWillYouLearnSection from "./Tabs/sections/WhatWillYouLearnSection";
import CourseBriefSection from "./Tabs/sections/CourseBriefSection";
import LiveCourseInfo from "./LiveCourseInfo";
const LiveForm = ({
  formik,
  imageUploaded,
  setimageUploaded,
  submittingController,
}) => {
  return (
    <div className="create-course layout-page">
      <h1 className="create-course__title font_24 font-extrabold">
        تفاصيل الدورة
      </h1>
      <LiveCourseInfo
        values={formik.values}
        form={formik}
        imageUploaded={imageUploaded}
        setimageUploaded={setimageUploaded}
        submittingController={submittingController}
      />
      <WhatWillYouLearnSection
        key_points={formik.values.key_points}
        handleChange={formik.handleChange}
        form={formik}
        type="course"
      />
      <CourseBriefSection
        description={formik.values.description}
        handleChange={formik.setFieldValue}
        error={formik.errors}
        touched={formik.touched}
        type="course"
      />
    </div>
  );
};
export default LiveForm;
