import OperationHistoryTable from "../components/tables/OperationHistoryTable";

const History = () => {
  return (
    <div className="sticky-header header-table no-padding-b">
      <span className="bold font-extrabold font_24">العمليات السابقة</span>
      <OperationHistoryTable />
    </div>
  );
};

export default History;
