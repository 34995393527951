import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../components/Icon/Icon";
import variables from "../scss/helpers/_export-theme-variables.scss";

const SideBar = ({ items, handleLogout }) => {
  const [sideBarShown, setSideBarShown] = useState(false);
  const toggleSideBarVisiblity = () => {
    setSideBarShown(sideBarShown ? false : true);
  };

  return (
    <div className={`col-lg-3 sidebar-main ${sideBarShown ? "show" : "hide"}`}>
      <button className="sidebar-main-toggle" onClick={toggleSideBarVisiblity}>
        <div className={`hamburger ${sideBarShown ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </button>
      <div className="sidebar-main__content">
        <ul>
          {items.filter((item) => item.visible == true).map((item) => (
            <li key={item?.id} className={`${item?.active ? "active" : ""}`}>
              <Link to={item?.link}>
                <Icon
                  iconName={item?.icon}
                  iconSize={24}
                  iconColor={
                    item.active
                      ? variables.colorPrimary
                      : variables.colorgrey200
                  }
                  isStroke={item?.isStrokeIcon}
                />
                <p>{item?.text}</p>
              </Link>
            </li>
          ))}
        </ul>

        <Link onClick={handleLogout} className="sign-out-link">
          <Icon
            iconName="signout-filled"
            iconSize={24}
            iconColor={variables.colorgrey200}
          />
          <span className="font-bold"> تسجيل الخروج</span>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
