// Import Swiper React components
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import PropTypes from "prop-types";
import { useListTutorsQuery } from "../../services/tutors";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/effect-creative";

const JoinTadarabSlider = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  let navigate = useNavigate();
  const { data: tutors } = useListTutorsQuery();

  return (
    <div className="home-page__join-tadrab bg-color-grey-900-opacity-4 mt-121 pt-70 pb-116 slider-page px-50 ">
      <section className="text-center mb-33">
        <h1 className="font_32  font-extrabold lh-30 home-page__our-numbers__title mb-22">
          <span className="font-color-dark">أنضم لتدرب وكن واحد من </span>
          <span className="font-color-primary">مدربيننا</span>
        </h1>
        <p className="font-color-grey-500 font_16">
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما لتدريب
          افراد شركتك
        </p>
      </section>

      <section>
        <Swiper
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={5}
          breakpoints={{
            // when window width is >= 200px
            200: {
              slidesPerView: 1,
            },
            // when window width is >= 576px
            576: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 1,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 5,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          className="home-page__join-tadrab__swiper"
        >
          <div>
            <button
              ref={navigationPrevRef}
              className="home-page__join-tadrab__swiper__action prev"
            >
              <i class="fa-solid fa-angle-right " />
            </button>
            <button
              ref={navigationNextRef}
              className="home-page__join-tadrab__swiper__action next"
            >
              <i class="fa-solid fa-angle-left " />
            </button>
          </div>
          {tutors?.data?.length
            ? tutors?.data?.map((trainer) => (
                <SwiperSlide key={trainer.id} className="">
                  <img
                    src={trainer.avatar_url}
                    alt={trainer.name}
                    height={320}
                    className="w-100 border-radius-12"
                  />
                  <div className="swiper-slide__info text-white w-100 text-center p-13">
                    <h5 className="font_18 font-bold">{trainer.name}</h5>
                    <p className="font_13 mb-6">{trainer.title}</p>
                    <p className="line-clamp-1 font_13 mb-0">{trainer.bio}</p>
                  </div>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
        <button
          className="btn btn-main-color shadow-none mt-22 font_16 font-bold py-13 px-55 mx-auto"
          onClick={() => navigate("/signup")}
        >
          {" "}
          ابدأ الآن مجاناَ
        </button>
      </section>
    </div>
  );
};

export default JoinTadarabSlider;

JoinTadarabSlider.prototype = {
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      desc: PropTypes.string,
    })
  ),
};
