import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FaCalendarMinus } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";

const DateTimePicker = ({ name, value, onChange, label }) => {
  const [startDate, setStartDate] = useState(value);
  return (
    <div className="wrapper">
      <p>{label}</p>
      <div className="container">
        <DatePicker
          name={name}
          selected={value}
          onChange={(date) => {
            onChange(date);
            setStartDate(date);
          }}
          className="date-picker"
          placeholderText="يوم/شهر/سنة"
          value={value}
        />
        <FaCalendarMinus color="#ccc" className="icon" />
      </div>
    </div>
  );
};

export default DateTimePicker;
