import { useState } from "react";
import MainCouponsTable from "../components/tables/MainCouponsTable";
import CreateNewCouponModal from "../components/Modals/CreateNewCouponModal";

const Coupons = () => {
  const TABS = {
    COUPONS: 1,
    STATS: 2,
    SUMMARY: 3,
    DATE: 4,
  };
  const [selectedTab, setSelectedTab] = useState(TABS.COUPONS);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="sticky-header header-table no-padding-b pt-0">
        <div
          className="nav nav-tabs mb-4 align-items-center"
          id="nav-tab"
          role="tablist"
        >
          <button
            className="nav-link active font-extrabold font_16 py-4"
            id="learning-track-details-tab"
            data-bs-toggle="tab"
            data-bs-target="#learning-track-details"
            type="button"
            role="tab"
            aria-controls="learning-track-details"
            aria-selected="true"
            onClick={() => {
              setSelectedTab(TABS.COUPONS);
            }}
          >
            الكوبونات
          </button>
          <button
            type="submit"
            className="btn btn-main-color font_15 ms-auto"
            onClick={() => setShowModal(true)}
            data-bs-toggle="modal"
            data-bs-target="#create-new-coupon"
          >
            <i className="fa-solid fa-plus pe-12"></i>
            إضافة كوبون جديد
          </button>
        </div>
      </div>

      <div className="layout-pages pt-0">
        {selectedTab === TABS.COUPONS && <MainCouponsTable />}
      </div>

      <CreateNewCouponModal isOpened={showModal} setIsOpened={setShowModal} />
    </>
  );
};

export default Coupons;
