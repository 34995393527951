import { api } from "./index";

export const tags = api.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query({
            query: () => `/api/v1/public/tags`,
            providesTags: ["Tags"],
        }),
        createTag: builder.mutation({
            query: (body) => ({
                url: "/api/v1/tutor/tags",
                method: "POST",
                body
            }),
            invalidatesTags: ["Tags"],
        }),
        updateTag: builder.mutation({
            query: ({ id, body }) => ({
                url: `/api/v1/tutor/tags/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["Tags"],
        }),
        deleteTag: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/tutor/tags/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Tags"],
        })
    }),
})

export const {
    useGetTagsQuery,
    useCreateTagMutation,
    useUpdateTagMutation,
    useDeleteTagMutation
} = tags;
