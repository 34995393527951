import { api } from "./index";

export const revenues = api.injectEndpoints({
  endpoints: (builder) => ({
    getRevenues: builder.query({
      query: ({ page, per_page }) => `/api/v1/tutor/revenues?page=${page}&per_page=${per_page}`,
    }),

    getYearlyRevenues: builder.query({
      query: (year) => `/api/v1/tutor/revenues/yearly?year=${year}`,
    }),

    getMonthlyRevenues: builder.query({
      query: (date) => `/api/v1/tutor/revenues/monthly?date=${date}`,
    }),

    getTotalRevenues: builder.query({
      query: (date) => `/api/v1/tutor/revenues/total`,
    }),
  }),
});

export const {
  useGetRevenuesQuery,
  useGetYearlyRevenuesQuery,
  useGetMonthlyRevenuesQuery,
  useGetTotalRevenuesQuery,
} = revenues;
