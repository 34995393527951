import React, { useState } from "react";
import { userProfileTabs } from "../../../data";
import { Link, useNavigate } from "react-router-dom";

const UserProfileTabs = ({ user }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <nav>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {userProfileTabs.map((tab) => (
          <Link
            to={tab.route}
            key={tab.id}
            className={`nav-item nav-link font_16 font-color-black font-weight-500`}
            style={{
              borderBottom:
                selectedTab == tab.id ? "2px solid #AF151F" : "none",
              color: selectedTab == tab.id ? "#AF151F" : "#000000",
              fontWeight: selectedTab == tab.id ? "bold" : "normal",
            }}
            onClick={() => {
              setSelectedTab(tab.id);
              navigate(tab.route, { state: { user: user } });
            }}
          >
            {tab.title}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default UserProfileTabs;
