import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaRegTrashAlt } from "react-icons/fa";
import Avatar from "../../Inputs/Avatar";
import { TbEdit } from "react-icons/tb";
import { IoIosMore } from "react-icons/io";
import { toast } from "react-toastify";
import MyModalWithActions from "../../Modals/MyModalWithActions";
import { TiStarFullOutline } from "react-icons/ti";
import "./BlogCard.scss";
import { Link } from "react-router-dom";

const BlogCard = ({ onDelete, item }) => {
  const currentUser = useSelector((state) => state?.auth?.currentUser);
  const { blog, topics, image, tutor } = item;
  const [showActionsPopup, setShowActionsPopup] = useState(false);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [checked, setChecked] = useState(true);

  const modalRef = useRef(null);

  const handleShowActionsPopup = () => {
    setShowActionsPopup((prev) => !prev);
  };

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowActionsPopup(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const getStatusInArabic = () => {
    if (blog?.status === "published") return "تم النشر";
    if (blog?.status === "draft") return "مسودة";
    if (blog?.status === "rejected") return "مرفوضة";
  };

  return (
    <div
      className="my-blogs-section__card"
      style={{ backgroundColor: `${!checked && "#ccc"}` }}
    >
      <div className="my-blogs-section__card__back-drop" />
      <div className="my-blogs-section__card__blog-details">
        <div className="my-blogs-section__card__blog-details__authorName-authorImg">
          <Avatar width="36" height="36" avatarUrl={currentUser?.avatarUrl} />
          <p className="blogs-page__my-blogs-section__card__blog-details__authorName-authorImg__authorName">
            {tutor?.name_ar}
          </p>
        </div>

        <h2 className="my-blogs-section__card__blog-details__title">
          {blog?.title}
        </h2>
        <div className="my-blogs-section__card__blog-details__subtitle">
          <div dangerouslySetInnerHTML={{ __html: blog?.introduction }} />
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "1rem",
            flexWrap: "wrap",
          }}
        >
          {topics?.map((topic) => (
            <div className="my-blogs-section__card__blog-details__category">
              {topic?.title}
            </div>
          ))}
        </div>
        <span className="my-blogs-section__card__blog-details__date">
          14 نوفمبر 2023{" "}
          <span style={{ fontSize: "1.8rem", fontWeight: "bold" }}>.</span> 8
          دقائق قراءة
        </span>
      </div>
      <div className="my-blogs-section__card__image">
        <div className="my-blogs-section__card__image__image-holder">
          <img
            src={image.url ? image.url : "/images/placeholder-image.jpg"}
            // src={"/images/placeholder-image.png"}
            alt="blog image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </div>
        <div className="my-blogs-section__card__image__actions">
          <div
            className="my-blogs-section__card__image__actions__featured"
            style={{
              backgroundColor: currentUser?.is_admin ? "#fff" : "transparent",
            }}
          >
            {currentUser?.is_admin && (
              <TiStarFullOutline size={24.5} color="#AF151F" />
            )}
          </div>
          <div className="my-blogs-section__card__image__actions__icon-status">
            <span className="my-blogs-section__card__image__actions__icon-status__status">
              {/* {getStatusInArabic()} */}
              تم النشر
            </span>
            <button onClick={handleShowActionsPopup}>
              <IoIosMore size={22} />
            </button>
          </div>
          {showActionsPopup && (
            <div
              className="my-blogs-section__card__image__actions__modal"
              ref={modalRef}
              style={{ backgroundColor: "#fff" }}
            >
              <ul className="my-blogs-section__card__image__actions__modal__list">
                <li>
                  <Link to={`/blogs/${blog?.id}/edit`}>
                    <TbEdit color="#777" size={24} /> تعديل المقالة
                  </Link>
                </li>
                <li onClick={() => setShowActionsModal(true)}>
                  <FaRegTrashAlt color="#777" size={24} /> حذف المقالة
                </li>
              </ul>
              <div className="my-blogs-section__card__image__actions__modal__displayCard">
                <p>عرض المقالة</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                      toast.success("تم تغيير حالة المقالة بنجاح");
                    }}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      {showActionsModal && (
        <MyModalWithActions
          icon={<FaRegTrashAlt color="#AF151F" size={32} />}
          title="حذف المقالة"
          text="هل أنت متأكد من حذف هذه المقالة؟ هذا سوف يحذفها بشكل نهائي من المنصة"
          onAction={onDelete}
          actionText="حذف"
          cancelText="الغاء"
          setShowModal={setShowActionsModal}
        />
      )}
    </div>
  );
};

export default BlogCard;
