import { api } from "./index";

export const dashboard = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardInfo: builder.query({
      query: () => '/api/v1/tutor/dashboard',
      providesTags: ["Course", 'LiveCourses'],
    })
  })
})

export const { useGetDashboardInfoQuery } = dashboard;