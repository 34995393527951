import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Avatar from "../Inputs/Avatar";
import { getCouponStatus } from "../../utils/helpers";
import moment from "moment";
import { useListCouponsQuery } from "../../services/coupons";
import Pagination from "../Pagination";

const MainCouponsTable = ({ usersList }) => {
  const [page, setPage] = useState(1);
  const { data: couponsList } = useListCouponsQuery({
    page: page,
    per_page: 10,
  });

  return (
    <>
      <Table className="table table-admin">
        <Thead className="table-header">
          <Tr>
            <Th className="font_12 font-color-grey font-bold">الاسم</Th>
            <Th className="font_12 font-color-grey font-bold">نسبة الخصم</Th>
            <Th className="font_12 font-color-grey font-bold">
              مرات الاستخدام
            </Th>
            <Th className="font_12 font-color-grey font-bold">
              تاريخ الانتهاء
            </Th>
            <Th className="text-center font_12 font-color-grey font-bold">
              حالة الكود
            </Th>
            <Th className="text-center font_12 font-color-grey font-bold">
              كود الخصم
            </Th>
          </Tr>
        </Thead>
        <Tbody className="table__content">
          {couponsList?.data?.length
            ? couponsList?.data?.map((element, key) => {
                let accountStatus = getCouponStatus(element?.status);
                return (
                  <>
                    <Tr key={key}>
                      <Td>
                        <div className="item me-2">
                          {/* <span className="bold font-extrabold font_14">
                          {++key}
                        </span> */}
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.name}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.discount}{" "}
                            {element?.is_percentage ? "%" : "دك"}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.usage_count}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.expires_at}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{
                              marginRight: 10,
                              color: accountStatus?.color,
                            }}
                          >
                            {accountStatus?.name}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item coupon-item">
                          <span
                            className="bold font-extrabold font_14"
                            style={{ marginRight: 10 }}
                          >
                            {element?.coupon}
                          </span>
                          <img
                            className="main-logo mx-2"
                            alt=""
                            src="/images/copy.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(element?.coupon);
                              toast.success("تم نسخ الكود بنجاح");
                            }}
                          />
                          <p className="coupon-text">نسخ</p>
                        </div>
                      </Td>
                    </Tr>
                  </>
                );
              })
            : null}
        </Tbody>
      </Table>
      <Pagination
        current={couponsList?.pagination?.current}
        pages={couponsList?.pagination?.pages}
        handlePagination={(page) => setPage(page)}
      />
    </>
  );
};

export default MainCouponsTable;
