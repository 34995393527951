import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import Notification from "../components/Notification";
import SideBar from "../components/SideBar";
import SideBarMobile from "../components/SideBarMobile";
import { getNavbarItems, getSidebarItems } from "./navigationItems";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { toast } from "react-toastify";
import { useLogoutMutation } from "../services/auth";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const handleResize = () => { setIsMobile(window.innerWidth <= 1300); };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isMobile;
};

const Layout = ({ activeNavbar, activeSidebar }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.auth?.currentUser)

  const [logout] = useLogoutMutation();
  const isMobile = useIsMobile();

  const notification = {
    type: "warning",
    head: "تنبيه: تحديث أنظمة الاشتراكات والشراء",
    message: "نعمل حاليًا على تحديث نظام الاشتراكات لتقديم خدمة أفضل، شكرًا لصبركم وتفهمكم."
  };

  const handleLogout = async () => {
    const result = await logout();
    if (!result.error || result?.error?.status === 401) {
      localStorage.removeItem("token");
      dispatch(setCurrentUser(null));
      navigate("/");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };

  return (
    <div className="admin-layout">
      <Navbar
        items={getNavbarItems(activeNavbar)}
      // oraganizationLogo={myProfile?.data ? myProfile.data.image_url : ""}
      ></Navbar>
      
      {/* Notification Component */}
      {!isMobile && <Notification notification={notification}/>}

      <div className="container-fluid">
        <div className="row">
          {activeSidebar && (
            <SideBar
              items={getSidebarItems(activeSidebar, currentUser?.is_admin)}
              handleLogout={handleLogout}
            />
          )}

          <SideBarMobile
            items={getNavbarItems(activeNavbar)}
            additionalItems={getSidebarItems(activeSidebar, currentUser?.is_admin)}
            handleLogout={handleLogout}
          />
          <div className="col content-page">
            {isMobile && <Notification notification={notification}/>}
            {/* This element will render child of AdminLayout */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
