import { useEffect, useState } from "react";
import InputFieldFloat from "../../Inputs/InputFieldFloat";
import ImageInput from "../../Inputs/ImageInput";
import DatePickerInput from "../../Inputs/DatePickerInput";
import TimePickerInput from "../../Inputs/TimePickerInput";
import moment from "moment";
import { useGetMainTopicsQuery } from "../../../../src/services/topics";
const LiveCourseInfo = ({
  values,
  form,
  imageUploaded,
  setimageUploaded,
  submittingController,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const [fileUrl, setFileUrl] = useState(
    values?.image != undefined
      ? typeof values?.image === "object"
        ? URL.createObjectURL(values?.image)
        : values?.image
      : ""
  );
  const [startTime, setStartTime] = useState(
    values.start_at ? values.start_at : null
  );
  const [endTime, setEndTime] = useState(values.end_at ? values.end_at : null);
  const [timeDuration, setTimeDuration] = useState(0);
  const { data: topicsList } = useGetMainTopicsQuery();
  const [topicsSelected, setTopicsSelected] = useState(values.topic_id);
  const [topicSelectedName, setTopicSelectedName] = useState(values.topicName);

  useEffect(() => {
    if (endTime && startTime) {
      const duration = moment.duration(endTime.diff(startTime));
      setTimeDuration(duration?._data?.hours);
    }
  }, [endTime, startTime]);

  const addFile = (e) => {
    if (e?.target?.files[0]) {
      setFileUrl(URL.createObjectURL(e?.target?.files[0]));
      form.setFieldValue("image", e?.target?.files[0]);
      setimageUploaded(true);
    }
  };

  const topicSelected = (id) => {
    if (id) {
      const topic = topicsList.data.filter((topic) => topic.id == id);
      setTopicsSelected(id);
      setTopicSelectedName(topic[0].title);
    }
  };

  const mapTopicsList = () => {
    return topicsList?.data?.map((topic) => (
      <li key={topic.id}>
        <p
          style={{ display: "flex" }}
          value={topic.id}
          className="checkbox dropdown-item"
        >
          {topic?.title}
        </p>
      </li>
    ));
  };

  useEffect(() => {
    form.setFieldValue("topic_id", topicsSelected);
    form.setFieldValue("topicName", topicSelectedName);
  }, [topicsSelected]);

  useEffect(() => {
    setFileUrl(
      values?.image != undefined
        ? typeof values?.image === "object"
          ? URL.createObjectURL(values?.image)
          : values?.image
        : ""
    );
    setStartTime(values.start_at ? values.start_at : null);
    setEndTime(values.end_at ? values.end_at : null);
  }, [values]);
  return (
    <div className="mb-4 create-section create-section--info">
      <div className="d-flex justify-content-between">
        <h2 className="font_20 font-extrabold create-section__title">
          معلومات الدورة
        </h2>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <div>
          <div className="row">
            <div className="col">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                اسم الدورة
              </label>
              <InputFieldFloat
                type="text"
                name="title"
                value={values.title}
                onChange={(e) => form.setFieldValue("title", e?.target?.value)}
                error={form?.errors?.title}
                touched={form?.touched?.title}
                marginBottom={24}
                placeholder="اكتب اسم الدورة هنا ..."
              />
            </div>
            <div className="col input-text">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                القسم
              </label>
              <button
                type="button"
                className="multiselect dropdown-toggle did-floating-input"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                multiple
                aria-label="multiple select example"
              >
                {values?.topicName ? values?.topicName : ""}
              </button>
              <ul
                role="tablist"
                aria-multiselectable={true}
                className="dropdown-menu multiselect-container"
                onClick={(e) => {
                  topicSelected(e?.target?.attributes?.value?.value);
                }}
              >
                {mapTopicsList()}
              </ul>
              {form.errors.topic_id && form.touched.topic_id ? (
                <div className="input-error">{form.errors.topic_id}</div>
              ) : null}
            </div>
          </div>

          <div className="row flex-column">
            <div className="col-12 col-md-6 col-lg-4">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                {" "}
                لينك الدورة (Live Stream URL)
              </label>
              <InputFieldFloat
                type="text"
                name="broadcast_url"
                value={values.broadcast_url}
                onChange={(e) =>
                  form.setFieldValue("broadcast_url", e?.target?.value)
                }
                error={form?.errors?.broadcast_url}
                touched={form?.touched?.broadcast_url}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                {" "}
                عنوان الدورة (Slug)
              </label>
              <InputFieldFloat
                type="text"
                name="preorder_course_id"
                value={values.preorder_course_id}
                onChange={(e) =>
                  form.setFieldValue("preorder_course_id", e?.target?.value)
                }
                error={form?.errors?.preorder_course_id}
                touched={form?.touched?.preorder_course_id}
              />
            </div>

            <div className="col-md-6 col-lg-4 create-section--info__upload-image">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                صورة الدورة (cover photo)
              </label>
              <ImageInput
                id="course-cover-img"
                name="image"
                details="أرفع صورة الغلاف للدورة"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => addFile(e)}
                imageUrl={fileUrl}
                error="صورة الدورة مطلوبة"
                touched={!imageUploaded && submittingController.isSaving}
                className={`w-100`}
              />
              <p
                style={
                  !imageUploaded && submittingController.isSaving
                    ? { marginTop: "45px" }
                    : { marginTop: "0px" }
                }
                className="font_12 font-color-grey-light mb-0"
              >
                يجب ان تكون بجودة عالية 1200 * 800 على الأقل
              </p>
              <p className="mb-0 font_12 font-color-grey">
                <i className="fas fa-info-circle me-2" />
                يجب ان تكون الصورة اقل من 2MB
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                {" "}
                لينك البرومو (Promo Video URL)
              </label>
              <InputFieldFloat
                type="text"
                name="promo_video_url"
                value={values.promo_video_url}
                onChange={(e) =>
                  form.setFieldValue("promo_video_url", e?.target?.value)
                }
                error={form?.errors?.promo_video_url}
                touched={form?.touched?.promo_video_url}
              />
            </div>
            <div style={{ width: "65%" }}>
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                تاريخ الدورة
              </label>
              <DatePickerInput
                marginBottom={7}
                name="start_date"
                minDate={new Date()}
                incomevalue={values.start_date}
                setFieldValue={form.setFieldValue}
                onBlur={form.handleBlur}
                error={form?.errors.start_date}
                touched={form?.touched.start_date}
                hasIcon
              />
            </div>
            <div className="row">
              <div className="col">
                <label className="font-color-grey-500 font_16 lh-17 mb-13">
                  من
                </label>
                <TimePickerInput
                  setTime={setStartTime}
                  error={form?.errors.start_at}
                  touched={form?.touched.start_at}
                  setFieldValue={form?.setFieldValue}
                  name="start_at"
                  threshold={{}}
                  time={startTime}
                  date={values.start_date}
                />
              </div>
              <div className="col">
                {" "}
                <label className="font-color-grey-500 font_16 lh-17 mb-13">
                  إلى
                </label>
                <TimePickerInput
                  setTime={setEndTime}
                  error={form?.errors.end_at}
                  touched={form?.touched.end_at}
                  setFieldValue={form?.setFieldValue}
                  name="end_at"
                  threshold={startTime}
                  time={endTime}
                  date={values.start_date}
                />
              </div>
              <div className="col duration-info">
                {" "}
                <label className="font-color-grey-500 font_16 lh-17 ">
                  {` مدة الدورة : ${timeDuration} ${
                    timeDuration >= 2 && timeDuration <= 10 ? "ساعات" : "ساعة"
                  }`}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default LiveCourseInfo;
