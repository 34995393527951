import React, { useEffect, useRef, useState } from "react";
import { useGetSearchResultsQuery } from "../../services/search";
import "./Search.scss";
import { IoIosSearch } from "react-icons/io";
import { LuSlidersHorizontal } from "react-icons/lu";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { encodeQueryString } from "../../utils/convertUrls";

const Search = ({ id, name, url, placeholder, type, setSearchResults }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const filterPopupRef = useRef(null);
  const navigate = useNavigate();
  const { data: searchResults } = useGetSearchResultsQuery({
    url,
    query: searchQuery,
  });

  if (searchResults) {
    // setSearchResults(searchResults.data);
    // console.log(searchResults.data);
    // console.log(searchQuery);
  }

  const filterItems = [
    { id: 0, title: "الأقدم" },
    { id: 1, title: "الأحدث" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterPopupRef.current &&
        !filterPopupRef.current.contains(event.target)
      ) {
        setShowPopup(false);
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery !== "")
      navigate(`/search/${encodeQueryString(searchQuery)}`, {
        state: { type },
      });
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit} className="search-form" autoComplete="off">
        <button type="submit">
          <IoIosSearch color="#ccc" size={24} />
        </button>
        <input
          id={id}
          name={name}
          type="text"
          value={searchQuery || ""}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={placeholder}
          className="search-input"
        />
      </form>
      {/* dropdown filters */}
      {/*<div
        className="sort-container"
        onClick={() => setShowPopup((prev) => !prev)}
      >
        <div className="sort-container__right-side">
          <LuSlidersHorizontal color="#ccc" size={24} />
          <span>{selectedFilter || "ترتيب حسب"}</span>
        </div>
        <div>
          <MdOutlineKeyboardArrowDown color="#ccc" size={24} />
        </div>
        {showPopup && (
          <div className="filter-popup" ref={filterPopupRef}>
            <ul className="filters-list">
              {filterItems.map((item) => (
                <li key={item.id} onClick={() => setSelectedFilter(item.title)}>
                  {item.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div> 
      */}
    </div>
  );
};

export default Search;
