import PropTypes from "prop-types";

const  RadioGroup = ({ values, formik }) => {
  return (
    <ul className='radio-group'>
      {values.map((value) =>
        <li key={value.id}>
          <input
            id={`radio-${value.id}`}
            className="radio-custom"
            name={value.groupName}
            type="radio"
            checked={formik.values.type === value.value}
            value={formik.values.type}
            onClick={() => formik.setFieldValue('type', value.value)}
          />
          <label htmlFor={`radio-${value.id}`} className="radio-custom-label">
            <div className='label-text'>
              <p>
                {value.text}
              </p>
              {value.subtText
                && <span>
                  {value.subtText}
                </span>}
            </div>
          </label>
        </li>
      )}
    </ul>
  )
}

export default RadioGroup;

RadioGroup.prototype = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      groupName: PropTypes.string,
      value:PropTypes.string,
      text:PropTypes.string,
      subtText: PropTypes.string
    })
  )
}

RadioGroup.defaultProps = {
  values: []
}
