import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import InputFieldFloat from "../components/Inputs/InputFieldFloat";
import PasswordInputFloat from "../components/Inputs/PasswordInputFloat";
import { useSignupMutation } from "../services/auth";
import Checkbox from "../components/Inputs/Checkbox";

const validationSchema = yup.object({
  name: yup.string().required("الاسم مطلوب"),
  phone: yup
    .string()
    .min(6, "الهاتف يجب ان يكون مكون من 6 ارقام كحد ادني")
    .required("رقم الهاتف مطلوب"),
  email: yup
    .string()
    .email("من فضلك ادخل بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
  password: yup
    .string()
    .min(8, "كلمة المرور يجب ان تكون مكونة من 8 حروف كحد ادني")
    .required("كلمة المرور مطلوبة")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "يجب ان تحتوي كلمة المرور علي حرف كبير وحرف صغير ورقم ورمز مميز"
    ),
});

const Signup = () => {
  const [checked, setChecked] = useState(false);

  const [signup] = useSignupMutation();
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const result = await signup(values);
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        localStorage.setItem("token", result?.data?.data?.token);
        navigate("/dashboard");
      }
    },
  });

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <h1
                  style={{
                    textAlign: "right",
                    fontSize: "30px",
                    fontWeight: 800,
                  }}
                >
                  انضم كمدرب بتدرب
                </h1>
                <p style={{ textAlign: "right" }}>
                  قم بتسجيل بياناتك وسيقوم فريق العمل بالتواصل معك في اقرب وقت
                  ممكن
                </p>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  label="الاسم"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  touched={formik.touched.name}
                />

                <InputFieldFloat
                  label="البريد الالكتروني"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />

                <InputFieldFloat
                  label="رقم الهاتف"
                  type="number"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.errors.phone}
                  touched={formik.touched.phone}
                />

                <PasswordInputFloat
                  label="كلمة المرور"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />

                <Checkbox id="terms" checked={checked} setChecked={setChecked}>
                  بمجرد التسجيل فأنت توافق على{" "}
                  <Link style={{ color: "#AF151F", fontWeight: 600 }}>
                    الاحكام والشروط
                  </Link>
                </Checkbox>

                <button
                  className="btn btn-main-color d-block w-100"
                  disabled={!checked}
                  type="submit"
                >
                  تسجيل حساب جديد
                </button>
                <p>
                  لديك حساب عندنا؟
                  <Link
                    to="/login"
                    style={{ color: "#AF151F", fontWeight: 600 }}
                  >
                    تسجيل الدخول
                  </Link>
                </p>
              </form>
            </div>
          </div>

          <img
            src="/images/signup.png"
            alt="signup-image"
            className="col-lg-6 login-page__banner"
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
