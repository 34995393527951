import { useEffect, useState } from "react";
import InputFieldFloat from "../Inputs/InputFieldFloat";
import SelectFile from "../Inputs/SelectFile";
import { useGetMainTopicsQuery } from "../../services/topics";
const BookInfoSection = ({
  values,
  form,
  setCoverUpload,
  setAttachmentUpload,
}) => {
  const { data: topicsList } = useGetMainTopicsQuery();
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const [topicName, setTopicName] = useState(values.topicName);
  const [bookAttachment, setBookAttachment] = useState("");
  const [bookName, setBookName] = useState(
    typeof values?.attachment == "string"
      ? values?.attachment?.substring(
          values?.attachment?.lastIndexOf("/") + 1,
          values?.attachment?.lastIndexOf("?")
        )
      : ""
  );
  const [bookCover, setBookCover] = useState("");
  const [coverName, setcoverName] = useState(
    typeof values?.cover == "string"
      ? values?.cover?.substring(
          values?.cover?.lastIndexOf("/") + 1,
          values?.cover?.lastIndexOf("?")
        )
      : ""
  );

  useEffect(() => {
    if (bookAttachment != "") {
      setBookName(bookAttachment?.name);
      form.setFieldValue("attachment", bookAttachment);
      setAttachmentUpload(true);
    }
  }, [bookAttachment]);

  useEffect(() => {
    if (bookCover != "") {
      setcoverName(bookCover?.name);
      form.setFieldValue("cover", bookCover);
      setCoverUpload(true);
    }
  }, [bookCover]);

  useEffect(() => {
    if (bookAttachment == "") {
      setBookName(
        values?.attachment?.substring(
          values?.attachment?.lastIndexOf("/") + 1,
          values?.attachment?.lastIndexOf("?")
        )
      );
    }
  }, [values?.attachment]);
  useEffect(() => {
    if (bookCover == "") {
      setcoverName(
        values?.cover?.substring(
          values?.cover?.lastIndexOf("/") + 1,
          values?.cover?.lastIndexOf("?")
        )
      );
    }
  }, [values?.cover]);

  const mapTopicsList = () => {
    return topicsList?.data?.map((topic) => (
      <li>
        <p style={{ display: "flex" }} value={topic.id} class="dropdown-item">
          {topic.title}
        </p>
      </li>
    ));
  };

  const topicSelected = (id) => {
    const topic = topicsList.data.filter((topic) => topic.id == id);
    setTopicName(topic[0].title);
    form.setFieldValue("topic_id", topic[0].id);
  };

  useEffect(() => {
    setTopicName(values.topicName);
  }, [values.topicName]);

  return (
    <div className="mb-4 create-section create-section--info">
      <div className="d-flex justify-content-between">
        <h2 className="font_20 font-extrabold create-section__title">
          معلومات الكتيب
        </h2>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <div>
          <div className="row">
            <div className="col">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                اسم الكتيب
              </label>
              <InputFieldFloat
                type="text"
                name="title"
                value={values.title}
                onChange={(e) => form.setFieldValue("title", e?.target?.value)}
                error={form?.errors?.title}
                touched={form?.touched?.title}
                marginBottom={24}
                placeholder="اكتب اسم الكتيب هنا ..."
              />
            </div>
            <div className="col input-text">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                القسم
              </label>
              <button
                type="button"
                className="dropdown-toggle did-floating-input"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {topicName}
              </button>
              <ul
                className="dropdown-menu"
                onClick={(e) => {
                  topicSelected(e?.target?.attributes?.value?.value);
                }}
              >
                {mapTopicsList()}
              </ul>
              {form.errors.topic_id && form.touched.topic_id ? (
                <div className="input-error">{form.errors.topic_id}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                ملف الكتيب
              </label>
              <SelectFile
                id="book-upload"
                value={bookName}
                setFile={setBookAttachment}
                accept="application/pdf"
                error={form.errors.attachment}
                touched={form.touched.attachment}
              />
            </div>
            <div className="col">
              <label className="font-color-grey-500 font_16 lh-17 mb-13">
                صورة غلاف الكتيب
              </label>
              <SelectFile
                id="book-cover-upload"
                value={coverName}
                setFile={setBookCover}
                accept="image/png, image/gif, image/jpeg"
                error={form.errors.cover}
                touched={form.touched.cover}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BookInfoSection;
