import moment from "moment";

export const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const displayDate = (dateTime) => {
  let createdAt = new Date(dateTime);
  return (`${createdAt.getDate().toString().padStart(2, 0)} ${arabicMonths[createdAt.getMonth()]} ${createdAt.getFullYear()}`)

};
