import React, { useState } from "react";
import UsersTable from "../components/tables/UsersTable";
import { IoIosSearch } from "react-icons/io";
import "../scss/pages/_users.scss";

const Users = () => {
  const [query, setQuery] = useState("");

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="sticky-header header-table">
      <div className="users-header">
        <span className="bold font-extrabold font_24">المستخدمين</span>
        <form onSubmit={(e) => e.preventDefault()} className="search-form">
          <button type="submit">
            <IoIosSearch className="search-form__icon" size={24} color="#ccc" />
          </button>
          <input
            type="text"
            name="search"
            id="search"
            onChange={handleSearchChange}
            onBlur={handleSearchChange}
            value={query}
            placeholder="ابحث هنا ..."
            className="search-form__input"
          />
        </form>
      </div>
      <UsersTable isSearch={query !== ""} searchQuery={query} />
    </div>
  );
};

export default Users;
