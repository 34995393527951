import { useNavigate } from "react-router-dom";
import BookCard from "../components/cards/BookCard";
import { useGetBooksQuery } from "../services/books";
import Pagination from "../components/Pagination";
import { useState } from "react";

const Books = () => {
  const [page, setPage] = useState(1);
  const { data: books } = useGetBooksQuery(page);
  const navigate = useNavigate();

  return (
    <div className="books layout-pages">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h1 className="font_24 font-extrabold"> الكتيبات</h1>
          <p className="font_16 font-color-grey">
            قم بإضافة ملخصات الكتيبات على منصات تدرب
          </p>
        </div>
        <button
          type="button"
          className="btn btn-main-color font-15 font-extrabold dashboard__create-course"
          onClick={() => navigate("/books/create")}
        >
          <i className="fa-solid fa-plus dashboard__create-course__icon" />
          إنشاء كتيب جديد
        </button>
      </div>

      <section className="mt-48">
        <div className="books__list">
          {books?.data?.map((book) => (
            <div className="list__item">
              <BookCard book={book} />
            </div>
          ))}
        </div>
      </section>
      {books?.data?.length > 0 && (
        <Pagination
          current={books?.pagination?.current}
          pages={books?.pagination?.pages}
          handlePagination={(page) => setPage(page)}
        />
      )}
    </div>
  );
};

export default Books;
