// import HomeIcon from "../components/icons/HomeIcon";
// import Leaderboard from "../components/icons/leaderboard";
// import LearningPaths from "../components/icons/learningPaths";
// import Rreports from "../components/icons/reports";
// import Settings from "../components/icons/settings";
// import Users from "../components/icons/users";
// import LeaderboardIcon from "../components/icons/leaderboard";
// import LearningPathsIcon from "../components/icons/learningPaths";

export const getNavbarItems = (active) => [
  {
    text: "استكشف",
    link: "/discover-courses",
    icon: "",
    active: active === "home" ? true : false,
  },
];

export const getSidebarItems = (active, isAdmin) => {
  return [
    {
      id: "1",
      text: "الرئيسية",
      link: "/dashboard",
      icon: "home-filled",
      active: active === "dashboard" ? true : false,
      visible: true,
    },
    {
      id: "313",
      text: "المستخدمين",
      link: "/users",
      icon: "three-users-filled",
      active: active === "users" ? true : false,
      visible: isAdmin ? true : false,
    },
    {
      id: "2",
      text: "المقالات",
      link: "/blogs",
      icon: "articles-filled",
      active: active === "blogs" ? true : false,
      isStrokeIcon: true,
      visible: true,
    },
    // {
    //   id: "20",
    //   text: "الأقسام و المواضيع",
    //   link: "/tags-and-topics",
    //   icon: "setting-filled",
    //   active: active === "tags-and-topics" ? true : false,
    //   visible: isAdmin ? true : false,
    // },
    {
      id: "3",
      text: "أرباحي",
      link: "/revenue",
      icon: "dollar-sign-stroke",
      active: active === "revenue" ? true : false,
      isStrokeIcon: true,
      visible: true,
    },
    {
      id: "4",
      text: "تاريخ العمليات السابقة",
      link: "/history",
      icon: "history-stroke",
      isStrokeIcon: true,
      active: active === "history" ? true : false,
      visible: true,
    },
    // {
    //   id: "4",
    //   text: "الصفحة الشخصية",
    //   link: "/edit-profile",
    //   icon: "home-filled",
    //   active: active === "edit-profile" ? true : false,
    // },
    // {
    //   id: "5",
    //   text: "تحليل البيانات",
    //   link: "/analysis",
    //   icon: "analytics-filled",
    //   active: active === "analyst" ? true : false,
    // },
    // {
    //   id: "6",
    //   text: "انشاء دورة جديدة",
    //   link: "/create",
    //   icon: "plus-filled",
    //   active: active === "NewCourse" ? true : false,
    // },
    {
      id: "22",
      text: "الكتيبات",
      link: "/books",
      icon: "book-filled",
      active: active === "books" ? true : false,
      visible: isAdmin ? true : false,
    },
    {
      id: "7",
      text: "الإعدادات",
      link: "/settings",
      icon: "setting-filled",
      active: active === "settings" ? true : false,
      visible: isAdmin ? true : false,
    },
    {
      id: "8",
      text: "كوبونات الخصم",
      link: "/coupons",
      icon: "disscount-coupon-filled",
      active: active === "coupons" ? true : false,
      visible: true,
    },
    // {
    //   id: "9",
    //   text: "مواعيد الاستشارات",
    //   link: "/consultations",
    //   icon: "consultations-filled",
    //   active: active === "consultations" ? true : false,
    // },
    {
      id: "10",
      text: "الدعم الفني",
      link: "/support",
      icon: "question-circle-stroke",
      isStrokeIcon: true,
      active: active === "support" ? true : false,
      visible: true,
    },
  ];
};
