import { api } from "./index";

export const blogs = api.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: ({ page }) =>
        `/api/v1/tutor/blogs?page=${page}`,
      providesTags: ["Blogs"],
    }),
    getBlog: builder.query({
      query: ({ slug }) => `/api/v1/tutor/blogs/${slug}`,
      providesTags: ["Blogs"],
    }),
    createBlog: builder.mutation({
      query: (body) => ({
        url: "/api/v1/tutor/blogs",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Blogs"],
    }),
    updateBlog: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/v1/tutor/blogs/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Blogs"],
    }),
    deleteBlog: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/tutor/blogs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Blogs"],
    }),
    getBlogComments: builder.query({
      query: ({ blogId, page, per_page }) =>
        `/api/v1/public/blogs/${blogId}/comments?page=${page}&per_page=${per_page}`,
      providesTags: ["Blogs"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useGetBlogCommentsQuery,
} = blogs;