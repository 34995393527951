import PropTypes from "prop-types";
import { useEffect } from "react";
export default function InputFieldFloat({
  name,
  label,
  type,
  disabled,
  marginBottom,
  value,
  onChange,
  error,
  touched,
  additionalStyle,
  children,
  placeholder,
  inputClassName,
  isWhiteInput,
  className,
  id,
}) {
  useEffect(() => {
    if (value && id) document.getElementById(id).focus();
  }, [value]);

  return (
    <div className={`input-text ${className}`}>
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <input
          id={id}
          name={name}
          className={`did-floating-input ${inputClassName} ${
            isWhiteInput ? "white-input" : ""
          }`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={additionalStyle}
        />
        {children && children}
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}

InputFieldFloat.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.number,
  error: PropTypes.string,
  touched: PropTypes.bool,
  direction: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  isWhiteInput: PropTypes.bool,
};

InputFieldFloat.defaultProps = {
  placeholder: "",
  label: "",
  type: "text",
  disabled: false,
  marginBottom: null,
  error: "",
  touched: false,
  direction: "rtl",
  children: null,
  onChange: () => {},
  inputClassName: "",
  isWhiteInput: false,
  className: "",
};
