import { api } from "./index";

export const users = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page, per_page }) => ({
        url: `/api/v1/tutor/users?page=${page}&per_page=${per_page}`,
      }),
    }),
    getUserById: builder.query({
      query: ({ userId }) => ({
        url: `/api/v1/tutor/users/${userId}`,
      }),
    }),
    // getUserCourses: builder.query({
    //   query: ({ userId }) => ({
    //     url: `/api/v1/tutor/users/${userId}/courses`,
    //   }),
    // }),
    searchUser: builder.query({
      query: ({ searchQuery, page, per_page }) => ({
        url: `/api/v1/tutor/users?filters[query]=${searchQuery}&page=${page}&per_page=${per_page}`,
      }),
    }),
    resetUserPassword: builder.mutation({
      query: (userId,body) => ({
        url: `/api/v1/tutor/users/${userId}/reset_password`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useSearchUserQuery,
  useResetUserPasswordMutation,
} = users;
