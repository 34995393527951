import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetSearchResultsQuery } from "../services/search";
import "../scss/pages/_search-results.scss";
import DiscoverCourseCard from "../components/cards/DiscoverCourseCard";
import Pagination from "../components/Pagination";
import BlogCard from "../components/Blogs/BlogCard/BlogCard";
import { decodeQueryString } from "../utils/convertUrls";
const SearchResults = () => {
  const { searchQuery } = useParams();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("asc");
  const { state } = useLocation();

  const { data: searchResults, isLoading } = useGetSearchResultsQuery({
    url: state?.type,
    query: decodeQueryString(searchQuery),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchResults]);

  return (
    <div className="search-results">
      <div className="search-results__hero">
        <img
          src="/images/search-results.jpg"
          alt="search-results"
          className="search-results__hero-img"
        />
        <h1 className="search-results__hero-title">
          نتائج البحث عن {searchQuery}
        </h1>
      </div>
      {searchResults?.data?.length === 0 && (
        <p
          style={{
            color: "#000",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
            marginTop: "100px",
          }}
        >
          ما تبحث عنه لا يبحث عنك !!
        </p>
      )}
      {/* will add filters banner */}
      {!isLoading ? (
        <div className="search-results__content">
          <div className="row">
            {searchResults?.data?.map((result) => (
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                {state?.type === "courses" ? (
                  <DiscoverCourseCard
                    key={result.id}
                    course={result}
                    isLive={false}
                  />
                ) : (
                  <BlogCard key={result.id} onDelete={() => {}} blog={result} />
                )}
              </div>
            ))}
          </div>
          {searchResults?.data?.length > 1 && (
            <Pagination
              current={searchResults?.pagination?.current}
              pages={searchResults?.pagination?.pages}
              handlePagination={(page) => setPage(page)}
            />
          )}
        </div>
      ) : (
        <p className="search-results__content" style={{ color: "#000" }}>
          يتم تحميل البيانات ..
        </p>
      )}
    </div>
  );
};

export default SearchResults;
