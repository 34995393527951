import React from "react";

const ImageInput = ({
  details,
  id,
  name,
  accept,
  onChange,
  imageUrl,
  imageAlt = "img",
  className,
  error,
  touched,
  onBlur,
  marginError,
  disabled = false,
}) => {
  function openFileSelector(e) {
    e.preventDefault();
    const fileInput = document.getElementById(id);
    fileInput.click();
  }

  if (imageUrl) {
    return (
      <div className="position-relative">
        {!disabled ? (
          <div className="position-absolute image-input_image-options ">
            <i
              className="fas fa-edit fa-lg image-input_image-options_icon"
              onClick={openFileSelector}
            ></i>
          </div>
        ) : (
          <></>
        )}
        <img className={className} src={imageUrl} alt={imageAlt} />
        <input
          type="file"
          id={id}
          name={name}
          accept={accept}
          onChange={onChange}
          style={{ display: "none" }}
        />
      </div>
    );
  }
  return (
    <div className="input-text">
      <label htmlFor={id} className={`image-input ${className} `}>
        <img className="icon me-2" alt="" src="/images/icons/upload.svg" />

        <div className="image-input__btn">
          <p>{details}</p>
        </div>

        <input
          type="file"
          id={id}
          name={name}
          accept={accept}
          onChange={onChange}
          onBlur={onBlur}
        />
      </label>
      {error && touched ? (
        <p style={{ marginTop: marginError }} className="input-error">
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default ImageInput;
