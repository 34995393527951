import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import "./topics.scss";
import MyModalWithInputs from "../../Modals/MyModalWithInputs";
import MyModalWithActions from "../../Modals/MyModalWithActions";
import {
  useDeleteTopicMutation,
  useGetMainTopicsQuery,
} from "../../../services/topics";

const Topics = () => {
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const { data: topics, refetch } = useGetMainTopicsQuery({
    refetchOnMountOrArgChange: true,
  });
  const [deleteTopic] = useDeleteTopicMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleAddNewCategory = () => {
    setShowAddNewModal(true);
  };

  const handleDelete = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const _renderAddNewCategoryModal = () => {
    if (showAddNewModal) {
      return (
        <MyModalWithInputs
          title="إضافة قسم جديد"
          text="ادخل بيانات القسم الجديد الذي تريد إضافته لأقسام المقالات"
          setShowModal={setShowAddNewModal}
          label="عنوان القسم"
          status="add"
        />
      );
    } else return <></>;
  };

  const _renderEditModal = () => {
    if (showEditModal) {
      return (
        <MyModalWithInputs
          title="تعديل القسم"
          text={"ادخل بيانات القسم الجديد الذي تريد تعديله لأقسام المقالات"}
          setShowModal={setShowEditModal}
          label="عنوان القسم"
          item={selectedItem}
          status="edit"
        />
      );
    } else return <></>;
  };

  const _renderDeleteModal = () => {
    if (showDeleteModal) {
      return (
        <MyModalWithActions
          icon={<FaRegTrashAlt color="#AF151F" size={32} />}
          title="حذف القسم"
          text="هل أنت متأكد من حذف هذا القسم؟"
          actionText="حذف"
          setShowModal={setShowDeleteModal}
          cancelText="الغاء"
          onAction={() => {
            deleteTopic({ id: deletedId });
            setShowDeleteModal(false);
          }}
        />
      );
    } else return <></>;
  };

  return (
    <>
      <div className="blog-categories">
        <header className="blog-categories__header">
          <div className="blog-categories__header__right">
            <p className="blog-categories__header__right__title">
              الأقسام({topics?.data?.length})
            </p>
            <p className="blog-categories__header__right__subtitle">
              قم بإضافة أقسام المقالات على منصة تدرب
            </p>
          </div>
          <button
            className="btn btn-main-color"
            onClick={() => handleAddNewCategory()}
          >
            إضافة قسم جديد
          </button>
        </header>
        <div className="blog-categories__body">
          <ul className="blog-categories__body__list pb-10">
            {topics?.data?.map((category) => (
              <li
                key={category.id}
                className="blog-categories__body__list__item"
              >
                <span>{category.id}.</span>
                <div
                  className="blog-categories__body__list__item__category"
                  style={{ backgroundColor: category.color || "#000000" }}
                >
                  {category.title}
                </div>
                <div
                  className="blog-categories__body__list__item__icon"
                  onClick={() => handleEdit(category)}
                >
                  <MdEdit size={24} />
                </div>
                <div
                  className="blog-categories__body__list__item__icon"
                  onClick={() => handleDelete(category.id)}
                >
                  <FaRegTrashAlt size={24} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {_renderAddNewCategoryModal()}
      {_renderEditModal()}
      {_renderDeleteModal()}
    </>
  );
};

export default Topics;
