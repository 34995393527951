import React, { useState, useRef, useEffect } from "react";
import { RiArrowDownSFill } from "react-icons/ri";
import { toArabicNumerals } from "../../../utils/helpers";
import "./SelectWithCheckboxes.scss";
import { useLazyGetTopicByIdQuery } from "../../../services/topics";

const SelectWithCheckboxOptions = ({
  options,
  id,
  formik,
  placeholder,
  isCategory = true,
  error,
  errorMessage,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const checkboxGroupRef = useRef(null);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setSelectedCheckboxes((prevSelected) => {
      if (checked) {
        return [...prevSelected, name];
      } else {
        return prevSelected.filter((item) => item !== name);
      }
    });

    const formattedSelectedCheckboxes = selectedCheckboxes.map((checkbox) =>
      parseInt(checkbox)
    );

    formik.setFieldValue(
      id,
      selectedCheckboxes.includes(name)
        ? formattedSelectedCheckboxes
        : [...selectedCheckboxes, name]
    );
  };

  useEffect(() => {
    const formattedSelectedCheckboxes = selectedCheckboxes.map((checkbox) =>
      parseInt(checkbox)
    );

    formik.setFieldValue(id, formattedSelectedCheckboxes);
  }, [id, selectedCheckboxes]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (
      checkboxGroupRef.current &&
      !checkboxGroupRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const handleOptionClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="wrapper">
      <p>{placeholder}</p>
      <div className="select-with-checkbox" onClick={handleToggle}>
        <div className="select-with-checkbox__value">
          <p>
            {selectedCheckboxes.length === 0
              ? placeholder
              : `${toArabicNumerals(selectedCheckboxes.length)} ${
                  isCategory ? "أقسام" : "مواضيع"
                } ${" "}محددة`}
          </p>
          <RiArrowDownSFill
            color="#ccc"
            size={24}
            style={{ marginBottom: "12px" }}
          />
        </div>
        {open && (
          <div
            ref={checkboxGroupRef}
            className="select-with-checkbox__options"
            onClick={handleOptionClick}
          >
            {options.map((option, index) => (
              <label key={index}>
                <input
                  type="checkbox"
                  name={option.name}
                  onChange={handleCheckboxChange}
                  value={option.name}
                  checked={selectedCheckboxes
                    .map((key) => parseInt(key))
                    .includes(option.name)}
                  onBlur={formik.handleBlur}
                />
                <span style={{ marginRight: "8px" }}>{option.label}</span>
                {/* <br /> */}
              </label>
            ))}
          </div>
        )}
        <ul className="select-with-checkbox__selected-items">
          {!isCategory &&
            selectedCheckboxes.map((key) => {
              const checkedItem = options.find((item) => item.name === key);
              if (checkedItem) {
                return (
                  <li
                    key={key}
                    className="select-with-checkbox__selected-items__item"
                  >
                    {checkedItem.label}
                    <span
                      className="select-with-checkbox__selected-items__item__remove"
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        formik.setFieldValue(key, false);
                        setSelectedCheckboxes((prevSelected) =>
                          prevSelected.filter((item) => item !== key)
                        );
                      }}
                    >
                      X
                    </span>
                  </li>
                );
              }
              return null;
            })}
        </ul>
      </div>
      {error ? <div className="error">{errorMessage}</div> : null}
    </div>
  );
};

export default SelectWithCheckboxOptions;
