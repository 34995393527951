import React from "react";
import "../../scss/components/my-modal.scss";
const MyModal = ({ setShowModal, children }) => {
  return (
    <div className="my-modal">
      <div className="modal-backdrop" onClick={() => setShowModal(false)}></div>
      <div className="my-modal__content">{children}</div>
    </div>
  );
};

export default MyModal;
