const Support = () => {
  return (
    <div className="settings layout-pages">
      <h1 className="font_24 font-extrabold"> الدعم الفني للمدربين </h1>
      <p className="font_16 font-color-grey">
        تواصل مع الدعم الفني لمساعدتك وسيتم التواصل معاك في أقرب وقت ممكن
      </p>

      <section className="mt-33">
        <div className="d-flex align-items-center mb-30">
          <i className="fa-solid fa-envelope font-color-grey-200 font_24" />

          <div className="ps-12">
            <label className="font-color-grey font-bold font_14 lh-22">
              البريد الإلكتروني
            </label>
            <h5 className="font-extrabold font_18 font-color-grey-700 lh-20">
              support@tadarab.com
            </h5>
          </div>
        </div>

        <div className="d-flex align-items-center mb-30">
          <i class="fa-solid fa-phone font-color-grey-200 font_24" />

          <div className="ps-12">
            <label className="font-color-grey font-bold font_14 lh-22">
              {" "}
              رقم الموبايل
            </label>
            <h5 className="font-extrabold font_18 font-color-grey-700 lh-20">
              50364308 00965
            </h5>
          </div>
        </div>
      </section>

      <hr />

      <section className="mt-30">
        <p className="font-color-grey font-bold font_14 lh-22 mb-14">
          {" "}
          أو يمكنك النقر على الزر للتحدث مع الدعم الفني لمساعدتك
        </p>
        <a href="https://wa.me/96550364308">
          <button className="btn btn-whats-app-color font_16">
            <i class="fa-brands fa-whatsapp pe-12 font_26" />
            <span className="font-bold"> تواصل مع الدعم الفني</span>
          </button>
        </a>
      </section>
    </div>
  );
};

export default Support;
