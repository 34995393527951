import { api } from "./index";

export const tutors = api.injectEndpoints({
  endpoints: (builder) => ({
    listTutors: builder.query({
      query: () => `/api/v1/public/tutors/all`,
    }),
  }),
});

export const { useListTutorsQuery } = tutors;
