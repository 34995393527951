import React from "react";
import PropTypes from "prop-types";
import Icons from "./sprite.svg";
import variables from "../../scss/helpers/_export-theme-variables.scss";

export default function Icon(
  {
    iconName,
    iconSize,
    className,
    style,
    iconColor,
    isStroke,
    iconHeight,
  },
) {
  return (
    <svg
      className={`icon icon-${iconName} ${className}`}
      width={iconSize}
      height={iconHeight ? iconHeight:  iconSize}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      fill={!isStroke ? iconColor: 'none'}
      strok={isStroke ? iconColor : 'none'}
    >
      <use xlinkHref={`${Icons}#${iconName}`} stroke={isStroke ? iconColor : 'none'} />
    </svg>
  );
}
Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  iconHeight: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  iconColor: PropTypes.string,
  isStroke: PropTypes.bool
};

Icon.defaultProps = {
  iconSize: 20,
  className: "",
  style: null,
  iconColor:  variables.colorGray500,
  isStroke: false,
  iconHeight: null
};
