const Dropdown = ({
  dropdownClassName,
  toggleBtnClassName,
  toggleLabel,
  toggleElement,
  hasToggleArrowDown,
  children,
}) => {
  return (
    <div className={`dropdown  ${dropdownClassName}`}>
      <button
        className={`btn border-0 ${toggleBtnClassName}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {toggleLabel}
        {toggleElement}
        {hasToggleArrowDown && <i className="fas fa-chevron-down ms-2"></i>}
      </button>
      <ul className="dropdown-menu">{children}</ul>
    </div>
  );
};

export default Dropdown;

Dropdown.defaultProps = {
  dropdownClassName: "",
  toggleBtnClassName: "",
  toogleLabel: "",
  toggleElement: null,
  hasToggleArrowDown: false,
};
