
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";

const StatsCard = ({
  img,
  title,
  num,
  totalNum,
  bgColor,
  text,
  iconName,
  iconSize,
  iconColor,
  titleClassName,
  titleFontSize,
  numFontSize,
  children,
  textFontSize,
  textFontWeight,
  containerClassName,
  numClassName,
  titleFontcolor
}) => {
  return (
    <div
      className={`card-style home-stats-card ${containerClassName}`}
      style={{ backgroundColor: bgColor }}
    >
      {img && <img className="mb-2" alt="" src={`/images/icons/${img}`} />}
      {iconName && <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} className="mb-2" />}
      <div>
        <h1 className={`font_${titleFontSize} font-color-${titleFontcolor} mb-0 font-bold ${titleClassName}`}>{title}</h1>
        <p className={`font_${numFontSize} font-extrabold mt-1 ${numClassName}`}>
          <span>{num}</span>

          {totalNum && (
            <span className="font_18 font-color-grey"> /{totalNum}</span>
          )}

          {text && <span className={`font_${textFontSize} ${textFontWeight} me-1 font-bold`}>{text}</span>}{" "}
        </p>
        {children && children}
      </div>
    </div>
  );
};

export default StatsCard;

StatsCard.prototype = {
  img: PropTypes.string,
  title: PropTypes.string,
  num: PropTypes.number,
  iconSize: PropTypes.number,
  totalNum: PropTypes.number,
  bgColor: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  numFontSize: PropTypes.number,
  titleClassName: PropTypes.string,
  textFontWeight: PropTypes.string,
  titleFontSize: PropTypes.number,
  textFontSize: PropTypes.number,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  numClassName: PropTypes.string,
  titleFontcolor: PropTypes.string
}

StatsCard.defaultProps = {
  img: "",
  iconName: "",
  titleClassName: "",
  titleFontSize: 14,
  numFontSize: 30,
  children: null,
  textFontSize: 18,
  textFontWeight: "",
  containerClassName: "",
  numClassName: "",
  titleFontcolor: "grey",
  iconSize: 30
}
