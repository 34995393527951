import Icon from "../../../Icon/Icon";
import CourseSyllabusSection from "./sections/CourseSyllabusSection";
import { FieldArray } from "formik";
import { LessonTypeModal } from "../../../../components/Modals/LessonTypeModal";
import { useState } from "react";
import { useSelector } from "react-redux";

const Syllabus = ({ formik }) => {
  const [showModal, setShowModal] = useState(false);
  const [triggerSection, setTriggerSection] = useState();
  const [section, setSection] = useState();
  const currentUser = useSelector((state) => state.auth.currentUser);
  return (
    <div className="create-course layout-page">
      <div className="create-content mt-4">
        <h1 className="create-course__title font_24 font-extrabold">
          {" "}
          منهج الدورة{" "}
        </h1>
        <FieldArray name="array" validateOnChange={false}>
          <div>
            {formik?.values?.sections?.map((section, index) => {
              return (
                <CourseSyllabusSection
                  section={section}
                  form={formik}
                  index={index}
                  setSection={setSection}
                  setTriggerSection={setTriggerSection}
                  setShowModal={setShowModal}
                  disabled={!currentUser?.is_admin}
                />
              );
            })}

            <div className="create-section mb-4">
              <button
                onClick={() => {
                  formik.setFieldValue("sections", [
                    ...formik?.values?.sections,
                    {
                      name: "",
                      lessons: [
                        {
                          name: "",
                          is_free: false,
                          type: "Video",
                          video: "",
                          video_id: "",
                          video_source: [
                            {
                              src: "",
                            },
                          ],
                        },
                      ],
                    },
                  ]);
                }}
                className="font_18"
                disabled={!currentUser?.is_admin}
              >
                <Icon
                  iconName={"add-filled"}
                  iconSize={12}
                  className={"me-2"}
                />
                إضافة مجموعة جديدة
              </button>
            </div>
          </div>
        </FieldArray>
      </div>
      <LessonTypeModal
        isOpened={showModal}
        setIsOpened={setShowModal}
        form={formik}
        section={section}
        index={triggerSection}
      />
    </div>
  );
};
export default Syllabus;
