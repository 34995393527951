import { useState } from "react";
import Modal from "./Modal";
import RadioGroup from "../Inputs/RadioGroup";
import { useFormik } from "formik";
export const LessonTypeModal = ({
  isOpened,
  setIsOpened,
  form,
  index,
  section,
}) => {
  const [closeModal, setCloseModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      type: "video",
    },
    onSubmit: async (values) => {
      if (values.type == "file") {
        form.setFieldValue(`sections.${index}.lessons`, [
          ...section?.lessons,
          {
            name: "",
            is_free: false,
            file: "",
            type: "ExplanationFile",
            file_url: "",
          },
        ]);
      } else {
        form.setFieldValue(`sections.${index}.lessons`, [
          ...section?.lessons,
          {
            name: "",
            is_free: false,
            type: "Video",
            video: "",
            video_id: "",
            video_source: [
              {
                src: "",
              },
            ],
          },
        ]);
      }
    },
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
        setIsOpened(false);
      }}
    >
      <Modal
        id="create-new-lesson-modal"
        title={"إنشاء درس جديد"}
        subTitle={"من فضلك قم باختيار نوع الدرس  "}
        mainBtn={"استمرار"}
        mainBtnOnclick={() => {
          document.getElementById("submit-btn").click();
        }}
        className="invite-employee-modal"
        maxWidth="500"
        closeModal={closeModal}
        onClose={() => {
          setCloseModal(true);
        }}
        isOpened={isOpened}
        hasCloseBtn
      >
        <RadioGroup
          values={[
            {
              id: 1,
              text: "فيديو",
              subtText: "",
              groupName: "type",
              value: "video",
            },
            {
              id: 2,
              text: "ملف شرح",
              subtText: "",
              groupName: "type",
              value: "file",
            },
          ]}
          formik={formik}
        />
        <button
          type="submit"
          id="submit-btn"
          style={{ display: "hidden" }}
        ></button>
      </Modal>
    </form>
  );
};
