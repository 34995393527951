import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { tagsMock } from "../../../data";
import MyModalWithActions from "../../Modals/MyModalWithActions";
import MyModalWithInputs from "../../Modals/MyModalWithInputs";
import "./tags.scss";
import { useDeleteTagMutation, useGetTagsQuery } from "../../../services/tags";

const Tags = () => {
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const { data: tags, refetch } = useGetTagsQuery({
    refetchOnMountOrArgChange: true,
  });
  const [deleteTag] = useDeleteTagMutation();

  const handleAddNewTag = () => {
    setShowAddNewModal(true);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleDelete = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const _renderAddNewTag = () => {
    if (showAddNewModal) {
      return (
        <MyModalWithInputs
          title="اضافة موضوع جديد"
          text="ادخل بيانات الموضوع الذي تريد إضافته لمواضيع المقالات"
          setShowModal={setShowAddNewModal}
          label="عنوان الموضوع"
          status="add"
          hasColor={false}
          type="tag"
        />
      );
    } else return <></>;
  };

  const _renderEditModal = () => {
    if (showEditModal) {
      return (
        <MyModalWithInputs
          setShowModal={setShowEditModal}
          label="عنوان القسم"
          item={selectedItem}
          hasColor={false}
          status="edit"
          type="tag"
        />
      );
    } else return <></>;
  };

  const _renderDeleteModal = () => {
    if (showDeleteModal) {
      return (
        <MyModalWithActions
          icon={<FaRegTrashAlt color="#AF151F" size={32} />}
          title="حذف المقالة"
          text="هل أنت متأكد من حذف هذه المقالة؟ هذا سوف يحذفها بشكل نهائي من المنصة"
          onAction={() => {
            deleteTag({ id: deletedId });
            setShowDeleteModal(false);
          }}
          actionText="حذف"
          cancelText="الغاء"
          setShowModal={setShowDeleteModal}
        />
      );
    } else return <></>;
  };

  return (
    <>
      <div className="blog-tags">
        <header className="blog-tags__header">
          <div className="blog-tags__header__right">
            <p className="blog-tags__header__right__title">
              المواضيع({tags?.data?.length})
            </p>
            <p className="blog-tags__header__right__subtitle">
              قم بإضافة مواضيع المقالات على منصة تدرب
            </p>
          </div>
          <button
            className="btn btn-main-color"
            onClick={() => handleAddNewTag()}
          >
            إضافة موضوع جديد
          </button>
        </header>
        <div className="blog-tags__body">
          <ul className="blog-tags__body__list pb-10">
            {tags?.data?.map((tag) => (
              <li className="blog-tags__body__list__item" key={tag.id}>
                <span>{tag.id + 1}.</span>
                <div className="blog-tags__body__list__item__category">
                  {tag.title}
                </div>
                <div
                  className="blog-tags__body__list__item__icon"
                  onClick={() => handleEdit(tag)}
                >
                  <MdEdit size={24} />
                </div>
                <div
                  className="blog-tags__body__list__item__icon"
                  onClick={() => handleDelete(tag.id)}
                >
                  <FaRegTrashAlt size={24} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {_renderDeleteModal()}
      {_renderAddNewTag()}
      {_renderEditModal()}
    </>
  );
};

export default Tags;
