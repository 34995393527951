import { createApi } from "@reduxjs/toolkit/query/react";
import { staggeredBaseQueryWithBailOut } from "../utils/serviceUtilities";

export const api = createApi({
  reducerPath: "api",
  baseQuery: staggeredBaseQueryWithBailOut(process.env.REACT_APP_API_URL),
  tagTypes: [
    "Coupons",
    "Books",
    "Book",
    "Course",
    "LiveCourses",
    "Profile",
    "Blogs",
    "Topics",
    "Tags",
  ],
  endpoints: () => ({}),
});
