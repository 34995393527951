import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function InputRichTextAreaFloat({
  id,
  name,
  label,
  marginBottom,
  hint,
  error,
  touched,
  value,
  onChange,
  setFieldValue,
  disabled,
}) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }, { direction: "rtl" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "direction",
  ];

  function setValue(e) {
    e = e.replace(/\s+/g, " ");
    if (setFieldValue && name) {
      setFieldValue(name, e);
    } else {
      onChange(e);
    }
  }

  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{
          marginBottom: marginBottom ? marginBottom + "px" : "27px",
        }}
      >
        <ReactQuill
          id={id}
          name={name}
          value={value}
          onChange={setValue}
          placeholder={label}
          theme="snow"
          modules={modules}
          formats={formats}
          className="quill-editor-field"
          readOnly={disabled}
        />
        <p className="input-hint">{hint}</p>
        {error && touched ? <div className="input-error">{error}</div> : null}
      </div>
    </div>
  );
}
