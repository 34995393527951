import React, { useRef, useEffect, useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";
import "./SelectWithRadioOptions.scss";

const SelectWithRadioOptions = ({
  options,
  id,
  formik,
  placeholder,
  error,
  errorMessage,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const radioGroupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      radioGroupRef.current &&
      !radioGroupRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const handleOptionClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="wrapper" ref={radioGroupRef}>
      <p>{placeholder}</p>
      <div className="select-with-radio" onClick={handleToggle}>
        <div className="select-with-radio__value">
          <p
            style={
              formik.values.tutor_id
                ? { color: "#000", fontWeight: "bold", fontSize: "16px" }
                : undefined
            }
          >
            {selectedRadio || placeholder}
          </p>
          <RiArrowDownSFill
            color="#ccc"
            size={24}
            style={{ marginBottom: "12px" }}
          />
        </div>
        {open && (
          <div
            className="select-with-radio__options"
            onClick={handleOptionClick}
          >
            {options.map((option, index) => (
              <label key={index} className="custom-radio">
                <input
                  type="radio"
                  name={id}
                  value={option.value}
                  checked={formik.values.tutor_id === option.name}
                  onChange={(e) => {
                    formik.setFieldValue(id, option.name);
                    setSelectedRadio(option.tutorName);
                  }}
                />
                <span className="custom-radio__checkmark"></span>
                {option.label}
                <br />
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectWithRadioOptions;
