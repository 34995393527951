import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../app/store/reducers/authSlice";
// import { displayDate } from "../utils/arabicDatesUtilities";
import { useLogoutMutation } from "../services/auth";
import Dropdown from "./DropDown";
import Avatar from "./Inputs/Avatar";
import { useShowProfileQuery } from "../services/auth";

import Logout from "./Icon/logout";
import Icon from "./Icon/Icon";
import v from "../scss/helpers/_export-theme-variables.scss";
// import SwitchLang from "./SwitchLang/SwitchLang";
import { api } from "../services";

const Navbar = ({ items, isAdmin, oraganizationLogo, isGuest }) => {
  const [isUnreadNotifications, setIsUnreadNotifications] = useState(false);

  const { data: profile } = useShowProfileQuery();

  const currentUser = useSelector((state) => state?.auth?.currentUser);

  const [logout] = useLogoutMutation();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile?.error) dispatch(setCurrentUser(profile?.data));
  }, [profile]);

  const handleLogout = async () => {
    const result = await logout();
    if (!result.error || result?.error?.status === 401) {
      dispatch(setCurrentUser(null));
      dispatch(api.util.resetApiState());
      localStorage.removeItem("token");
      navigate("/");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };

  return (
    <nav className="navbar navbar-main navbar-expand-lg">
      <div className="container-fluid">
        {/* logos */}
        <ul className="logos" onClick={() => navigate(`/`)}>
          {oraganizationLogo && (
            <li>
              <Link className="navbar-brand" to="#">
                <img className="main-logo" alt="" src={oraganizationLogo} />
              </Link>
            </li>
          )}

          <li>
            <Link className="navbar-brand" to="#">
              <img className="main-logo" alt="" src="/images/logo.svg" />
            </Link>
          </li>
        </ul>
        {/* main-menu */}
        <div
          className="collapse navbar-collapse main-menu"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav mx-5">
            {items.map((item, key) => (
              <li key={key} className="nav-item">
                <Link
                  className={`nav-link font-bold ${
                    item.active ? "active" : ""
                  }`}
                  aria-current="page"
                  to={item.link}
                  style={{ color: "black" }}
                >
                  {item.icon && <span className={`me-2`}>{item.icon}</span>}
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {!isGuest && (
          <div className="d-flex align-items-center">
            {/* setting */}
            <Dropdown
              dropdownClassName="setting-menu"
              toggleElement={
                <>
                  <span className="font-extrabold me-12">
                    {" "}
                    {currentUser?.name}
                  </span>
                  <Avatar
                    avatarUrl={currentUser?.avatar_url}
                    width="36"
                    height="36"
                  />
                </>
              }
              hasToggleArrowDown
            >
              <li className="setting-menu__item">
                <Link
                  to={"/profile"}
                  className="font_20 font-extrabold text-black"
                >
                  {currentUser?.name}
                </Link>
                <p className="mb-0 font-color-grey font-regular">
                  {currentUser?.email}
                </p>
              </li>
              <li className="setting-menu__item">
                <Link to="/edit-profile" className="setting-menu__item__link">
                  <i className="fa-regular fa-user font-color-grey-light font_16 pe-18" />
                  تعديل حسابي الشخصي
                </Link>
              </li>

              <li className="setting-menu__item">
                <Link
                  className="setting-menu__item__link"
                  to="/employee/change-password"
                >
                  <i className="fa-solid fa-lock" />
                  تغير كلمة المرور
                </Link>
              </li>
              <li className="setting-menu__item">
                <button
                  className="setting-menu__item__link"
                  onClick={handleLogout}
                >
                  <Logout />
                  تسجيل الخروج
                </button>
              </li>
            </Dropdown>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
