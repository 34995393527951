import { Link } from "react-router-dom";
import Icon from "./Icon/Icon";
import variables from "../scss/helpers/_export-theme-variables.scss";

export default function SideBarMobile({
  items,
  additionalItems,
  isAdmin,
  handleLogout,
}) {
  return (
    <div className={`col-lg-3 col-md-12 sidebar-main sidebar-mobile`}>
      <button
        className="sidebar-main-toggle"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#sideBar"
        aria-controls="sideBar"
      >
        <div className={`hamburger`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </button>
      <div
        className={`offcanvas offcanvas-end`}
        tabIndex="-1"
        id="sideBar"
        aria-labelledby="sideBarLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close opacity-100"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body sidebar-main__content sidebar-mobile__content p-0">
          <div className="sidebar-mobile__content__list">
            <ul>
              {items.map((item, key) => (
                <li key={key} className={`${item.active ? "active" : ""}`}>
                  <Link to={item.link}>
                    {item.icon}
                    <p>{item.text}</p>
                  </Link>
                </li>
              ))}
            </ul>
            {additionalItems?.length ? (
              <ul className="border-bottom-0">
                {additionalItems
                  .filter((item) => item.visible == true)
                  .map((item, key) => (
                    <li key={key} className={`${item.active ? "active" : ""}`}>
                      <Link to={item.link}>
                        <Icon
                          iconName={item.icon}
                          iconSize={24}
                          iconColor={variables.colorgrey200}
                        />

                        <p>{item.text}</p>
                      </Link>
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>

          <Link onClick={handleLogout} className="sign-out-link">
            <Icon
              iconName="signout-filled"
              iconSize={24}
              iconColor={variables.colorgrey200}
            />
            <span className="font-bold">تسجيل الخروج</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
