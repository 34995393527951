import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute, ProtectedLogin, RouteToHome } from "./auth";

import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Layout from "../layout/Layout";

import AddNewCourse from "../pages/AddNewCourse";
import Coupons from "../pages/Coupons";
import Dashboard from "../pages/Dashboard";
import Revenue from "../pages/Revenue";
import History from "../pages/History";
import Profile from "../pages/Profile";
import AnalyzeData from "../pages/AnalyzeData";
import Settings from "../pages/Settings";
import ResetPassword from "../pages/ResetPassword";
import ResetPasswordViaEmail from "../pages/ResetPasswordViaEmail";
import EditProfile from "../pages/EditProfile";
import Consultations from "../pages/Consultations";
import Support from "../pages/Support";
import Books from "../pages/Books";
import BookForm from "../pages/BookForm";
import Home from "../pages/Home";
import AddNewLiveCourse from "../pages/AddNewLiveCourse";
import AllCourses from "../pages/AllCourses";
import ChangePassword from "../pages/ChangePassword";
import Blogs from "../pages/Blogs";
import AddNewBlog from "../pages/AddNewBlog";
import PreviewBlog from "../pages/PreviewBlog";
import BlogSettings from "../pages/TagsAndTopics";
import EditBlog from "../pages/EditBlog";
import SearchResults from "../pages/Search";
import Users from "../pages/Users";
import UserProfile from "../pages/UserProfile";
import UserTransactions from "../components/User/UserProfileTabs/UserTransactions/UserTransactions";
import UserPersonalDetails from "../components/User/UserProfileTabs/UserPersonalDetails/UserPersonalDetails";
import UserTrainingCourses from "../components/User/UserProfileTabs/UserTrainingCourses/UserTrainingCourses";
import TagsAndTopics from "../pages/TagsAndTopics";

const SystemRoutes = () => {
  return (
    <BrowserRouter>
      {/* {routeToHome()} */}
      <div id="maincontent" className="main">
        <Routes>
          <Route path="/" element={<ProtectedLogin />}>
            <Route path="/" element={<Login />} />
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="dashboard" />}>
              <Route path="/discover-courses" element={<AllCourses />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="dashboard" />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedLogin />}>
            <Route
              path="/reset-password/email-confirmation"
              element={<ResetPasswordViaEmail />}
            />
            <Route path="/reset" element={<ResetPassword />} />
          </Route>

          <Route path="/login" element={<ProtectedLogin />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/signup" element={<ProtectedLogin />}>
            <Route path="/signup" element={<Signup />} />
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="revenue" />}>
              <Route path="/revenue" element={<Revenue />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="history" />}>
              <Route path="/history" element={<History />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="profile" />}>
              <Route path="/profile" element={<Profile />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="analyst" />}>
              <Route path="/analysis" element={<AnalyzeData />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="NewCourse" />}>
              <Route path="/courses/create" element={<AddNewCourse />} />
              <Route
                path="/courses/:courseSlug/edit"
                element={<AddNewCourse />}
              />
              <Route
                path="/live-courses/create-live-course"
                element={<AddNewLiveCourse />}
              />
              <Route
                path="/live-courses/:courseSlug/edit"
                element={<AddNewLiveCourse />}
              ></Route>
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="books" />}>
              <Route path="/books" element={<Books />} />
              {/* <Route path="/books/:booksId/edit" element={<BookForm />} /> */}
              <Route path="/books/create" element={<BookForm />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="blogs" />}>
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/create" element={<AddNewBlog />} />
              <Route path="/blogs/settings" element={<BlogSettings />} />
              <Route path="/blogs/:blogId/edit" element={<EditBlog />} />
              <Route path="/blogs/preview-blog" element={<PreviewBlog />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="users" />}>
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<UserProfile />}>
                <Route
                  path="personal-details"
                  element={<UserPersonalDetails />}
                />
                <Route
                  path="training-courses"
                  element={<UserTrainingCourses />}
                />
                <Route
                  path="transactions-bills"
                  element={<UserTransactions />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="home" />}>
              <Route path="/search/:searchQuery" element={<SearchResults />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="support" />}>
              <Route path="/support" element={<Support />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="coupons" />}>
              <Route path="/coupons" element={<Coupons />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="settings" />}>
              <Route path="/settings" element={<Settings />} />
            </Route>
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="tags-and-topics" />}>
              <Route path="/tags-and-topics" element={<TagsAndTopics />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout activeSidebar="edit-profile" />}>
              <Route path="/edit-profile" element={<EditProfile />} />
            </Route>
          </Route>

          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route
                path="/employee/change-password"
                element={<ChangePassword />}
              />
            </Route>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default SystemRoutes;
