const SelectFile = ({
  name,
  label,
  marginBottom,
  value,
  accept,
  error,
  touched,
  id,
  setFile,
  disabled = false,
}) => {
  function openFileSelector(e) {
    e.preventDefault();
    const fileInput = document.getElementById(id);
    fileInput.click();
  }
  return (
    <>
      <div className="input-text">
        <div
          className="did-floating-label-content"
          style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
        >
          <input
            name={name}
            className="did-floating-input"
            value={value}
            disabled={true}
          />
          <input
            type="file"
            id={id}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e?.target?.files[0]);
            }}
            accept={accept}
          />
          {!disabled ? (
            <span
              class="input-group-append currency-field"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                openFileSelector(e);
              }}
            >
              select file
            </span>
          ) : (
            <></>
          )}
          <label className="did-floating-label">{label}</label>
          {error && touched ? <div className="input-error">{error}</div> : null}
        </div>
      </div>
    </>
  );
};
export default SelectFile;
