import { api } from "./index";

export const countries = api.injectEndpoints({
  endpoints: (builder) => ({
    listCountries: builder.query({
      query: () => `/api/v1/public/countries`,
    }),
  }),
});

export const { useListCountriesQuery } = countries;
