import { api } from "./index";

export const books = api.injectEndpoints({
  endpoints: (builder) => ({
    getBooks: builder.query({
      query: (page) => `/api/v1/tutor/books?page=${page}`,
      providesTags: ["Books"],
    }),
    deleteBook: builder.mutation(
      {
        query: ({ id }) => ({
          url: `/api/v1/tutor/books/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Books"],
      },
    ),
    updateBook: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/v1/tutor/books/${id}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: ["Books", "Book"],
    }),
    getBook: builder.query({
      query: (id) => `/api/v1/tutor/books/${id}`,
      providesTags: ["Book"],
    }),
    addBook: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/books`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Books", "Book"],
    }),
  }),

})

export const { useGetBooksQuery, useDeleteBookMutation, useUpdateBookMutation, useGetBookQuery, useAddBookMutation } = books;