import React from "react";
import { FaFacebookF, FaLinkedinIn, FaXTwitter, FaLink } from "react-icons/fa6";
import './social-links.scss'
const SocialLinks = () => {
  const socialLinks = [
    {
      id: 0,
      icon: <FaFacebookF color="#888888" />,
    },
    {
      id: 1,
      icon: <FaLinkedinIn color="#888888" />,
    },
    {
      id: 2,
      icon: <FaXTwitter color="#888888" />,
    },
    {
      id: 3,
      icon: <FaLink color="#888888" />,
    },
  ];
  return (
    <div className="social-links">
      <div>
        <div className="social-links__title">شارك المقالة</div>
        {socialLinks.map((item) => (
          <div className="social-links__item" key={item.id}>
            {item.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialLinks;
