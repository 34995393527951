import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const TimePickerInput = ({
  setTime,
  label,
  error,
  touched,
  setFieldValue,
  name,
  threshold,
  time,
  date,
}) => {
  const handleTimeChange = (value) => {
    let duration;
    if (threshold && Object.keys(threshold).length == 0) {
      setTime(value);
      setFieldValue(name, value);
    } else {
      duration = moment.duration(value.diff(moment(threshold)));
      if (duration?._milliseconds >= 0) {
        setTime(value);
        setFieldValue(name, value);
      } else {
        let inputField = document.getElementsByClassName(
          "rc-time-picker-input"
        );
        inputField.value = "";
      }
    }
  };

  const getDisabledHours = () => {
    let hours = [];
    if (threshold && !Object.keys(threshold).length == 0) {
      for (var i = 0; i < moment(threshold)?.hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    let minutes = [];
    if (!Object.keys(threshold).length == 0) {
      if (selectedHour == moment(threshold)?.hour()) {
        for (let i = 0; i < moment(threshold)?.minute(); i++) {
          minutes.push(i);
        }
      } else if (selectedHour < moment(threshold)?.hour()) {
        for (let i = 0; i < 60; i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  return (
    <div className="input-text inline-flex">
      <TimePicker
        disabledHours={
          threshold && !Object.keys(threshold).length == 0
            ? getDisabledHours
            : () => []
        }
        disabledMinutes={
          threshold && !Object.keys(threshold).length == 0
            ? getDisabledMinutes
            : () => []
        }
        value={time}
        showSecond={false}
        format="hh:mm a"
        use12Hours={true}
        popupClassName="time-picker"
        allowEmpty={false}
        inputIcon={
          <span className="icon-span">
            <i
              class="fa-solid fa-caret-down"
              style={{ color: "#777777", marginRight: "auto" }}
            ></i>
          </span>
        }
        onChange={(value) => {
          handleTimeChange(value);
        }}
      />
      <label className="did-floating-label">{label}</label>
      {error && touched ? <div className="input-error">{error}</div> : null}
    </div>
  );
};
export default TimePickerInput;
