import * as Yup from "yup";

export const addNewBlogSchema = Yup.object().shape({
  blog: Yup.object().shape({
    status: Yup.string().required('حالة المدونة مطلوبة'),
    title: Yup.string().required('عنوان المدونة مطلوب'),
    introduction: Yup.string().required('مقدمة المدونة مطلوبة'),
    conclusion: Yup.string().required('ختام المدونة مطلوب'),
    meta_title: Yup.string().required('العنوان الوصفي مطلوب').max(120, 'العنوان الوصفي طويل جداً'),
    meta_description: Yup.string().required('الوصف الوصفي مطلوب'),
  }),
  blog_sections_attributes: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('عنوان القسم مطلوب'),
      body: Yup.string().required('نص القسم مطلوب'),
    })
  ),
  topic_ids: Yup.array().required('معرّفات المواضيع مطلوبة').min(1, "لابد من اختيار قسم واحد على الأقل"),
  tag_ids: Yup.array().required('معرّفات الوسوم مطلوبة').min(1,"لابد من اختيار قسم واحد على الأقل"),
  image: Yup.mixed()
  .required('صورة المدونة مطلوبة')
  .test('fileSize', 'حجم الملف كبير جداً', value => value && value.size <= 100000)
  .test('fileType', 'نوع الملف غير صالح', value => value && ['image/jpeg', 'image/png'].includes(value.type)),});