const Profile = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center p-4">
        <div className="col-lg-12 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mt-3 font_24 font-extrabold ">
                مواعيد الاستشارات
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
