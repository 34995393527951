import DiscoverCourseCard from "../components/cards/DiscoverCourseCard";
import { useGetCoursesQuery } from "../services/courses";
import Pagination from "../components/Pagination";
import { useState } from "react";
const AllCourses = () => {
  const [page, setPage] = useState(1);
  const { data: coursesList } = useGetCoursesQuery({ page: page, per_page: 9 });
  return (
    <div className="container-fluid">
      <div className="row justify-content-center layout-page">
        <div className="col-lg-10 col-md-12">
          <div className="d-flex flex-wrap">
            <div className="mb-4">
              <h1 className="mt-3 font_24 font-extrabold "> تصفح الدورات</h1>
            </div>
          </div>
          {coursesList?.data?.length ? (
            <div className="discover-courses">
              <div className="row row-cols-1 row-cols-md-3 g-4">
                {coursesList?.data?.map((course) =>
                  <div key={course.id} className="col-12 col-md-4">
                    <DiscoverCourseCard course={course} />
                  </div>
                )}
              </div>
              <Pagination
                current={coursesList?.pagination?.current}
                pages={coursesList?.pagination?.pages}
                handlePagination={(page) => setPage(page)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
