import React from "react";
import Header from "../components/BlogSettings/Header/Header";

const TagsAndTopics = () => {
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundColor: "transparent",
        position: "relative",
      }}
    >
      <Header />
    </div>
  );
};

export default TagsAndTopics;
