import React, { useState } from "react";
import "./user-personal-details.scss";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../../services/users";
import CircularProgress from "../../../Shared/CircularProgress/CircularProgress";
import { subtractDays } from "../../../../utils/helpers";
import moment from "moment";
import ChangePasswordModal from "../../ChangePasswordModal/ChangePasswordModal";

const UserPersonalDetails = () => {
  let { id } = useParams();
  const { data: user, isLoading } = useGetUserByIdQuery({ userId: id });
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const _gridItem = (title, value) => {
    return (
      <div className="user-personal-details__grid-item">
        <span className="font_12 font-color-grey-500">{title}</span>
        <div className="font_16 font-color-dark font-extrabold mb-0">
          {value}
        </div>
      </div>
    );
  };

  return (
    <div className="user-personal-details">
      {isLoading || !user ? (
        <p className="font_16 font-color-dark font-extrabold mb-0">
          يتم تحميل بيانات المستخدم
        </p>
      ) : (
        <>
          <div className="user-personal-details__grid">
            {_gridItem("الاسم", user.data.name)}
            {_gridItem("اسم المستخدم", "غير معرف")}
            {_gridItem(
              "تاريح الالتحاق بتدرب",
              moment(user.data.created_at).format("YYYY-MM-DD")
            )}
            {_gridItem("البريد الالكتروني", user?.data?.email)}
            {_gridItem("رقم الموبايل", user?.data?.phone)}
            {_gridItem(
              "الدولة",
              user?.data?.country_code === "KW" ? "الكويت" : "غير معرف"
            )}
            {_gridItem(
              "تدرب بلا حدود",
              user.data.subscriptions.length
                ? `مشترك حتى ${user.data.subscriptions[0].renew_on}`
                : "غير مشترك"
            )}
            {_gridItem("عدد الدورات", user.data.courses_number || 0)}
            {_gridItem("عدد الشهادات", user.data.certificates_count || 0)}
            {_gridItem(
              "كلمة المرور",
              <button
                className="d-flex align-items-center"
                onClick={() => setShowChangePasswordModal(true)}
              >
                <img src="/images/lock.png" />
                <p className="font_12 mb-0 ms-2">تغيير كلمة المرور</p>
              </button>
            )}
          </div>
          {user.data.subscriptions.length > 0 && (
            <div className="user-personal-details__subscription">
              <p className="font_24 font-color-dark font-extrabold mt-5">
                تفاصيل الاشتراك
              </p>
              <div className="user-personal-details__subscription-info">
                <div className="user-personal-details__subscription-info__type">
                  <span className="font_12 font-color-dark">نوع الاشتراك</span>
                  <p className="user-personal-details__subscription-info__type-text">
                    تدرب بلا حدود
                  </p>
                  <span className="font_14 font-color-grey-500">
                    إشتراك سنوي
                  </span>
                </div>
                <div className="user-personal-details__subscription-info__price">
                  <span className="font_12 font-color-dark">سعر الاشتراك</span>
                  <p className="font_16 font-color-dark font-extrabold mb-0">
                    {
                      user.data.subscriptions[0].subscription_plan
                        .discounted_price
                    }{" "}
                    {user.data.subscriptions[0].subscription_plan.currency_code}
                  </p>
                  <div className="d-flex align-items-center gap-1">
                    <span className="font_14 font-color-grey-500">
                      تاريخ الدفع القادم
                    </span>
                    <span className="font_14 font-color-grey-500 font-extrabold">
                      {user.data.subscriptions[0].renew_on}
                    </span>
                  </div>
                </div>
                <div className="user-personal-details__subscription-info__duration">
                  <CircularProgress
                    percent={Math.abs(
                      (subtractDays(user.data.subscriptions[0].renew_on) /
                        365) *
                        100
                    ).toFixed(2)}
                    circleWidth={80}
                  />
                  <div className="user-personal-details__subscription-info__duration-info">
                    <span className="font_12 font-color-dark">
                      مدة الاشتراك
                    </span>
                    <p className="font_16 font-color-dark font-extrabold mb-0">
                      {subtractDays(user.data.subscriptions[0].renew_on)} يوم
                      متبقي
                    </p>
                    <span className="font_12 font-color-grey-500">
                      من 365 يوم
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {showChangePasswordModal && (
        <ChangePasswordModal
          userId={id}
          onClose={() => setShowChangePasswordModal(false)}
        />
      )}
    </div>
  );
};

export default UserPersonalDetails;
