import React from "react";
import PropTypes from "prop-types";
import Icon from "../components/Icon/Icon";
import "../scss/helpers/_notification-bar.scss";

const Notification = ({ notification }) => {
  return (
    <div className={`notification-bar`}>
      <div className={`notification-mssage ${notification.type}`}>
        <Icon iconName="icon-warning" iconSize={18} iconColor="#cf8600" className="icon" />
        <div className="text">
          <div className="head"><b>{notification.head}</b></div>
          <div className="inner">{notification.message}</div>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error"]).isRequired,
  head: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Notification;