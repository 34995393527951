import { api } from "./index";

export const coupons = api.injectEndpoints({
  endpoints: (builder) => ({
    createCoupon: builder.mutation({
      query: (body) => ({
        url: `/api/v1/tutor/coupons`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Coupons" }],
    }),

    listCoupons: builder.query({
      query: ({ page, per_page }) =>
        `/api/v1/tutor/coupons?page=${page}&per_page=${per_page}`,
      providesTags: [{ type: "Coupons" }],
    }),
  }),
});

export const { useCreateCouponMutation, useListCouponsQuery } = coupons;
