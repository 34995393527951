import { api } from "./index";

export const topics = api.injectEndpoints({
  endpoints: (builder) => ({
    getMainTopics: builder.query({
      query: () => '/api/v1/public/topics',
      providesTags: ["Topics"],
    }),
    createTopic: builder.mutation({
      query: (body) => ({
        url: '/api/v1/tutor/topics',
        method: 'POST',
        body
      }),
      invalidatesTags: ["Topics"],
    }),
    updateTopic: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/v1/tutor/topics/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ["Topics"],
    }),
    deleteTopic: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/tutor/topics/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Topics"],
    })
  }),
})

export const { useGetMainTopicsQuery, useCreateTopicMutation, useUpdateTopicMutation, useDeleteTopicMutation } = topics;