export const getCouponStatus = (status) => {
  switch (status) {
    case "active":
      return {
        color: "#01A60A",
        name: "Active",
        type: "active",
      };
    case "expired":
      return {
        color: "#F0142F",
        name: "Expired",
        type: "expired",
      };
    case "draft":
      return {
        color: "#FF7900",
        name: "Soon",
        type: "draft",
      };
  }
};

export const months = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const toArabicNumerals = (number) => {
  const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  return number
    .toString()
    .split("")
    .map((digit) => arabicNumerals[parseInt(digit)])
    .join("");
};

export const getBlogInitialValue = (isAdmin, selectedDate) => {
  let initialValues = {
    blog: {
      status: "published",
      title: "",
      introduction: "",
      conclusion: "",
      meta_title: "",
      meta_description: "",
    },
    blog_sections_attributes: [
      {
        title: "",
        body: "",
      },
    ],
    topic_ids: [],
    tag_ids: [],
    image: null,
  };

  if (isAdmin) {
    initialValues = {
      ...initialValues,
      tutor_id: "",
      published_at: "",
    };
  }

  return initialValues;
};

export const subtractDays = (date) => {
  const currentDate = new Date();
  const futureDate = new Date(date);
  const timeDifference = futureDate.getTime() - currentDate.getTime();
  const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return remainingDays;
};

export const paymentMethod = (method) => {
  console.log(method.toLowerCase());
  switch (method.toLowerCase()) {
    case "mastercard":
      return "/images/mastercard@2x.png";
    case "paypal":
      return "/images/paypal@2x.png";
    case "KNET":
      return "/images/KNETLogo@2x.png";
  }
};
