import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BookDetails from "../components/Books/BookDetails";
import {
  useGetBookQuery,
  useUpdateBookMutation,
  useAddBookMutation,
} from "../services/books";
import * as yup from "yup";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  title: yup.string().required("اسم الكتيب مطلوب"),
  topic_id: yup.number().required("قسم الكتيب مطلوب"),
});

const BookForm = () => {
  const { booksId: bookID } = useParams();
  const { data: bookInfo } = useGetBookQuery(bookID);
  const [updateBook] = useUpdateBookMutation();
  const [createBook] = useAddBookMutation();
  const [coverUpload, setCoverUpload] = useState(false);
  const [attachmentUpload, setAttachmentUpload] = useState(false);
  const [submmiting, setSubmmiting] = useState(false);
  const [editMode, setEditMode] = useState(bookID == undefined ? false : true);
  const navigate = useNavigate();

  const sendToReview = async () => {
    const Create_data = new FormData();
    Create_data.append("[book][status]", "pending");
    const response = await updateBook({ id: bookID, body: Create_data });
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("تم إرسال الكتيب للمراجعة");
      navigate("/books");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: bookInfo?.data?.title || "",
      topic_id: bookInfo?.data?.topic?.id,
      topicName: bookInfo?.data?.topic?.title,
      attachment: bookInfo?.data?.attachment_url || "",
      cover: bookInfo?.data?.cover_url || "",
      key_points: bookInfo?.data?.key_points || [""],
      description: bookInfo?.data?.description || "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors = {};

      if (
        values.description === "" ||
        values.description === " " ||
        values.description === undefined ||
        values.description === "<p><br></p>" ||
        values.description === '<p class="ql-align-right"><br></p>'
      ) {
        errors.description = " النبذة عن الكتيب مطلوبة";
      }

      if (!coverUpload && !editMode) {
        errors.cover = " صورة غلاف الكتيب مطلوبة";
      }

      if (!attachmentUpload && !editMode) {
        errors.attachment = " ملف الكتيب مطلوب";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSubmmiting(true);
      let result;
      const Create_data = new FormData();
      if (!coverUpload && editMode) {
        delete values.cover;
      } else {
        Create_data.append("[book][cover]", values.cover);
      }
      if (!attachmentUpload && editMode) {
        delete values.attachment;
      } else {
        Create_data.append("[book][attachment]", values.attachment);
      }
      Create_data.append("[book][title]", values.title);
      values.key_points.forEach((item, index) => {
        Create_data.append("[book]key_points[]", item);
      });
      Create_data.append("[book][description]", values.description);
      Create_data.append("[book][topic_id]", values.topic_id);
      if (editMode) {
        result = await updateBook({ id: bookID, body: Create_data });
      } else {
        result = await createBook(Create_data);
      }
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        toast.success("تمت الحفظ بنجاح");
        navigate("/books");
      }
      setSubmmiting(false);
    },
  });

  return (
    <div>
      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="left-side">
            <h1 className="title-page mt-1">إضافة كتيب جديد</h1>
          </div>
          <form
            className="content-form mt-3 mt-md-0"
            onSubmit={(e) => {
              formik.handleSubmit(e);
              e.preventDefault();
            }}
          >
            <div className="right-side">
              <ul className="list-filter">
                <li style={{ marginLeft: "16px" }}>
                  <button
                    type="submit"
                    className="btn btn-main-color font-15 min-w-140"
                    disabled={submmiting}
                  >
                    حفظ الكتيب{" "}
                    {submmiting && (
                      <Spinner
                        style={{
                          marginRight: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    style={{ marginLeft: "16px" }}
                    type="button"
                    className="btn btn-main-color-border font-15 px-4"
                    disabled={!editMode || bookInfo?.data?.status != "draft"}
                    onClick={() => {
                      sendToReview();
                    }}
                  >
                    إرسال للمراجعة{" "}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
      <BookDetails
        values={formik.values}
        form={formik}
        setCoverUpload={setCoverUpload}
        setAttachmentUpload={setAttachmentUpload}
      />
    </div>
  );
};
export default BookForm;
