import { api } from "./index";

export const courses = api.injectEndpoints({
  endpoints: (builder) => ({
    createCourse: builder.mutation({
      query: (body) => ({
        url: "/api/v1/tutor/courses",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Course"],
    }),

    updateCourse: builder.mutation({
      query: ({ courseID, body }) => ({
        url: `/api/v1/tutor/courses/${courseID}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Course"],
    }),

    createSection: builder.mutation({
      query: ({ courseID, body }) => ({
        url: `/api/v1/tutor/courses/${courseID}/sections`,
        method: "POST",
        body,
      }),
    }),

    createLesson: builder.mutation({
      query: ({ sectionID, body }) => ({
        url: `/api/v1/tutor/sections/${sectionID}/lessons`,
        method: "POST",
        body,
      }),
    }),
    getCourses: builder.query({
      query: ({ page, per_page }) => `/api/v1/tutor/courses?page=${page}&per_page=${per_page}`,
      providesTags: ["Course"],
    }),
    getCourse: builder.query({
      query: ({ slug }) => `/api/v1/tutor/courses/${slug}`,
      providesTags: ["Course"],
    }),

    updateSection: builder.mutation({
      query: ({ section_id, body }) => ({
        url: `/api/v1/tutor/sections/${section_id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ["Course"],
    }),

    deleteSection: builder.mutation({
      query: ({ sectionId }) => ({
        url: `/api/v1/tutor/sections/${sectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Course"],
    }),

    deleteLesson: builder.mutation({
      query: ({ lessonId }) => ({
        url: `/api/v1/tutor/lessons/${lessonId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Course"],
    }),
    updateLesson: builder.mutation({
      query: ({ lessonId, body }) => ({
        url: `/api/v1/tutor/lessons/${lessonId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ["Course"],
    })
  }),
});

export const {
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useCreateSectionMutation,
  useCreateLessonMutation,
  useGetCoursesQuery,
  useGetCourseQuery,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
  useDeleteLessonMutation,
  useUpdateLessonMutation,
} = courses;
