import { useState } from "react";
import Spinner from "../components/Spinner";
import Details from "../components/Courses/MutationPages/Tabs/Details";
import Syllabus from "../components/Courses/MutationPages/Tabs/Syllabus";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useCreateCourseMutation,
  useCreateSectionMutation,
  useCreateLessonMutation,
  useGetCourseQuery,
  useUpdateCourseMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
  useDeleteLessonMutation,
  useUpdateLessonMutation,
} from "../services/courses";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const validationSchema = yup.object({
  title: yup.string().required("اسم الدورة (بالعربية) مطلوب"),
  promo_video: yup.string().required("الفيديو التسويقي للدورة مطلوب"),
  topic_id: yup.array().min(1, "قسم الدورة مطلوب"),
  price: yup
    .number()
    .required("سعر الدورة مطلوب")
    .typeError("يجب ادخال ارقام فقط"),
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().required("اسم المجموعة مطلوب"),
      lessons: yup.array().of(
        yup.object().shape({
          name: yup.string().required(" اسم الدرس مطلوب"),
        })
      ),
    })
  ),
  level: yup.string().required("مستوى الدورة مطلوب"),
});

const AddNewCourse = () => {
  const TABS = {
    DETAILS: 1,
    SYLLABUS: 2,
  };
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [selectedTab, setSelectedTab] = useState(TABS.DETAILS);
  const [submmiting, setSubmmiting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [imageUploaded, setimageUploaded] = useState(false);
  const [createNewCourse] = useCreateCourseMutation();
  const [createNewSection] = useCreateSectionMutation();
  const [createNewLesson] = useCreateLessonMutation();
  const { courseSlug } = useParams();
  const { data: courseInfo } = useGetCourseQuery({ slug: courseSlug });
  const [editMode, setEditMode] = useState(
    courseSlug == undefined ? false : true
  );
  const [updateCourse] = useUpdateCourseMutation();
  const [updateSection] = useUpdateSectionMutation();
  const [deleteSection] = useDeleteSectionMutation();
  const [deleteLesson] = useDeleteLessonMutation();
  const [updateLesson] = useUpdateLessonMutation();

  const sendToReview = async () => {
    const Create_data = new FormData();
    Create_data.append("[course][status]", "pending");
    const response = await updateCourse({
      courseID: courseInfo?.data?.id,
      body: Create_data,
    });
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("تم إرسال الدورة للمراجعة");
      navigate("/dashboard");
    }
  };

  const checkLessonsMode = (formSection, sectionResponse) => {
    sectionResponse?.data?.data?.lessons.map((courseLesson) => {
      const temp = formSection.lessons?.filter(
        (lesson) => lesson.id == courseLesson.id
      );
      if (temp.length == 0) {
        deleteLessonMode(courseLesson.id);
      }
    });
    formSection?.lessons?.map((formLesson, index) => {
      const temp = sectionResponse?.data?.data?.lessons?.filter(
        (lesson) => lesson.id == formLesson.id
      );
      if (temp.length > 0) {
        editLessonMode(formLesson, index);
      } else {
        addLessonMode(formLesson, sectionResponse?.data?.data?.id, index);
      }
    });
  };

  const addSectionMode = async (formSection, index) => {
    const sectionInfo = new FormData();
    sectionInfo.append("name", formSection.name);
    sectionInfo.append("position", index + 1);
    const sectionResponse = await createNewSection({
      courseID: courseInfo?.data?.id,
      body: sectionInfo,
    });
    checkLessonsMode(formSection, sectionResponse);
  };
  const editSectionMode = async (formSection, index) => {
    const sectionInfo = new FormData();
    sectionInfo.append("name", formSection.name);
    sectionInfo.append("position", index + 1);
    const sectionResponse = await updateSection({
      section_id: formSection.id,
      body: sectionInfo,
    });
    checkLessonsMode(formSection, sectionResponse);
  };
  const deleteSectionMode = async (sectionId) => {
    const sectionResponse = await deleteSection({ sectionId: sectionId });
  };
  const addLessonMode = async (formLesson, sectionId, index) => {
    const lesson_data = new FormData();
    lesson_data.append("name", formLesson?.name);
    lesson_data.append("type", formLesson?.type);
    lesson_data.append("is_free", formLesson?.is_free);
    lesson_data.append("position", index + 1);
    if (
      currentUser?.is_admin == true &&
      formLesson?.video_id != "" &&
      formLesson?.video_id != "null" &&
      formLesson?.video_id != "undefined"
    ) {
      lesson_data.append("video_id", formLesson?.video_id);
    }
    if (formLesson?.type == "ExplanationFile") {
      if (formLesson?.file) lesson_data.append("file", formLesson?.file);
    } else {
      if (formLesson?.video) lesson_data.append("video", formLesson?.video);
      if (formLesson.video_source.length > 0) {
        lesson_data.append(
          "video_source[][src]",
          formLesson.video_source[0].src
        );
      }
    }

    const lessonResponse = await createNewLesson({
      sectionID: sectionId,
      body: lesson_data,
    });
  };
  const editLessonMode = async (formLesson, index) => {
    const lesson_data = new FormData();
    lesson_data.append("name", formLesson?.name);
    lesson_data.append("position", index + 1);
    lesson_data.append("type", formLesson?.type);
    lesson_data.append("is_free", formLesson?.is_free);
    if (
      currentUser?.is_admin == true &&
      formLesson?.video_id != "" &&
      formLesson?.video_id != "null" &&
      formLesson?.video_id != "undefined"
    ) {
      lesson_data.append("video_id", formLesson?.video_id);
    }
    if (formLesson?.type == "ExplanationFile" && formLesson?.file) {
      lesson_data.append("file", formLesson?.file);
    } else {
      if (formLesson?.video) lesson_data.append("video", formLesson?.video);
      if (formLesson?.video_source?.length > 0) {
        lesson_data.append(
          "video_source[][src]",
          formLesson.video_source[0].src
        );
      }
    }
    const lessonResponse = await updateLesson({
      lessonId: formLesson.id,
      body: lesson_data,
    });
  };

  const deleteLessonMode = async (lessonId) => {
    const sectionResponse = await deleteLesson({ lessonId: lessonId });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: courseInfo?.data?.title || "",
      topic_id:
        courseInfo?.data?.topics.length > 0
          ? courseInfo?.data?.topics.map((topic) => topic.id)
          : [],
      topicName:
        courseInfo?.data?.topics.length > 0
          ? courseInfo?.data?.topics.map((topic) => topic.title)
          : [],
      promo_video: courseInfo?.data?.promo_video_url,
      price: courseInfo?.data?.price || "",
      key_points: courseInfo?.data?.key_points || [""],
      faqs: courseInfo?.data?.faqs?.length
        ? courseInfo?.data?.faqs
        : [
            {
              q: "",
              a: "",
            },
          ],
      description: courseInfo?.data?.description || "",
      tags: courseInfo?.data?.tags || [],
      prerequisites: courseInfo?.data?.prerequisites || [""],
      level: courseInfo?.data?.level || "",
      sections: courseInfo?.data?.sections || [],
      image: courseInfo?.data?.image_url || "",
      status: courseInfo?.data?.status || "draft",
      details: courseInfo?.data?.details || "",
    },

    validationSchema: validationSchema,

    validate: (values) => {
      const errors = {};

      if (
        values.description === "" ||
        values.description === " " ||
        values.description === undefined ||
        values.description === "<p><br></p>"
      ) {
        errors.description = " النبذة عن الدورة مطلوبة";
      }

      if (!imageUploaded && !editMode) {
        errors.image = "صورة الدورة مطلوبة";
      }

      errors.sections = {};
      if (values.sections) {
        const unique = [...new Set(values.sections.map((item) => item.name))];
        if (unique.length < values.sections.length) {
          unique.map((word) => {
            let result = values.sections.reduce((acc, curr, i) => {
              if (curr.name === word) acc.push(i);
              return acc;
            }, []);
            if (result.length > 1) {
              errors.sections = new Array(values.sections.length)
                .fill()
                .map(() => ({}));
              result.map((i) => {
                errors.sections[i].name = "اسم المجموعة مكرر";
              });
            }
          });
        }
      }
      if (Object.keys(errors.sections).length == 0) {
        delete errors.sections;
      }
      return errors;
    },

    onSubmit: async (values) => {
      setSubmmiting(true);
      try {
        const Create_data = new FormData();
        if (!imageUploaded && editMode) {
          delete values.image;
        } else {
          Create_data.append("[course][image]", values.image);
        }
        if (currentUser?.is_admin == true) {
          Create_data.append("[course][status]", values.status);
        }
        Create_data.append("[course][title]", values.title);
        Create_data.append("[course][promo_video_url]", values.promo_video);
        values.topic_id.forEach((item, index) => {
          Create_data.append("[course]topic_ids[]", item);
        });
        values.key_points.forEach((item, index) => {
          Create_data.append("[course]key_points[]", item);
        });
        values.faqs.forEach((item, index) => {
          Create_data.append("[course]faqs[][q]", item.q);
          Create_data.append("[course]faqs[][a]", item.a);
        });
        Create_data.append("[course][price]", values.price);
        Create_data.append("[course][description]", values.description);
        values.tags.forEach((item, index) => {
          Create_data.append("[course]tags[]", item);
        });
        values.prerequisites.forEach((item, index) => {
          Create_data.append("[course]prerequisites[]", item);
        });
        Create_data.append("[course][level]", values.level);
        Create_data.append("[course][details]", values.details);
        let response;
        if (!editMode) {
          response = await createNewCourse(Create_data);
          if (values.sections.length != 0) {
            values.sections.map(async (section, index) => {
              const create_section = new FormData();
              create_section.append("name", section.name);
              create_section.append("position", index + 1);
              const sectionResponse = await createNewSection({
                courseID: response?.data?.data?.id,
                body: create_section,
              });
              section.lessons?.map((lesson, index) => {
                addLessonMode(lesson, sectionResponse?.data?.data?.id, index);
              });
            });
          }
        } else {
          response = await updateCourse({
            courseID: courseInfo?.data?.id,
            body: Create_data,
          });
          if (values.sections.length != 0) {
            courseInfo.data.sections.map((courseSection) => {
              const temp = values.sections?.filter(
                (section) => section.id == courseSection.id
              );
              if (temp.length == 0) {
                deleteSectionMode(courseSection.id);
              }
            });
            values.sections.map((formSection, index) => {
              const temp = courseInfo?.data?.sections?.filter(
                (section) => section.id == formSection.id
              );
              if (temp.length > 0) {
                editSectionMode(formSection, index);
              } else {
                addSectionMode(formSection, index);
              }
            });
          }
        }
        if (response?.error) {
          toast.error(response?.error?.data?.message);
        } else {
          toast.success("تمت الحفظ بنجاح");
          navigate("/dashboard");
        }
        setSubmmiting(false);
      } catch (e) {
        setSubmmiting(false);
        toast.error(e?.data?.message);
      }
    },
  });

  return (
    <div>
      <div className="sticky-header header-table no-padding-b">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="left-side">
            <h1 className="title-page mt-1">إنشاء دورة جديدة</h1>
          </div>

          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link create__tab active font_15 d-flex align-items-center"
              id="learning-track-details-tab"
              data-bs-toggle="tab"
              data-bs-target="#create-new-course-modal"
              type="button"
              role="tab"
              aria-controls="learning-track-details"
              aria-selected="true"
              onClick={() => {
                setSelectedTab(TABS.DETAILS);
              }}
            >
              <span className="create__tab__icon me-12"> 1 </span>
              <span className="create__tab__text"> تفاصيل الدورة</span>
            </button>
            <button
              className="nav-link create__tab font_15 d-flex align-items-center"
              id="learning-track-courses-tab"
              data-bs-toggle="tab"
              data-bs-target="#create-new-course-modal"
              type="button"
              role="tab"
              aria-controls="learning-track-courses"
              aria-selected="false"
              onClick={() => {
                setSelectedTab(TABS.SYLLABUS);
              }}
            >
              <span className="create__tab__icon me-12"> 2 </span>
              <span className="create__tab__text">منهج الدورة</span>
            </button>
          </div>
          <form
            className="content-form mt-3 mt-md-0"
            onSubmit={(e) => {
              formik.handleSubmit(e);
              e.preventDefault();
            }}
          >
            <div className="right-side">
              <ul className="list-filter">
                <li>
                  <button
                    type="submit"
                    className="btn btn-main-color font-15 px-30 font-extrabold shadow-none"
                    disabled={submmiting || currentUser?.is_admin == false}
                    onClick={() => setIsSaving(true)}
                  >
                    حفظ الدورة
                    {submmiting && (
                      <Spinner
                        style={{
                          marginRight: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn btn-main-color-border font-15 px-18 font-extrabold shadow-none mx-10"
                    disabled={
                      editMode && courseInfo?.data?.status == "draft"
                        ? false
                        : true
                    }
                    onClick={() => {
                      sendToReview();
                    }}
                  >
                    إرسال للمراجعة
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn btn-main-color-border font-24 px-10 shadow-none"
                    disabled={editMode ? false : true}
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_WEB_APP_URL}/course/${courseInfo?.data?.slug}?preview=true`;
                    }}
                  >
                    <i className="far fa-eye fa-fw fonr-extrabold"></i>
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>

      {selectedTab === TABS.DETAILS && (
        <Details
          formik={formik}
          submittingController={{ isSaving, setIsSaving }}
          setimageUploaded={setimageUploaded}
          imageUploaded={imageUploaded}
        />
      )}

      {selectedTab === TABS.SYLLABUS && <Syllabus formik={formik} />}
    </div>
  );
};

export default AddNewCourse;
