import { useState } from "react";
import { FieldArray } from "formik";
import InputFieldFloat from "../../../../Inputs/InputFieldFloat";
const PrerequisitesSection = ({
  prerequisites,
  handleChange,
  form,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  return (
    <div className="mb-4 create-section">
      <div className="d-flex justify-content-between align-items-start">
        <div className="create-section__title">
          <h2 className="font_20 font-extrabold ">متطلبات الدورة </h2>
        </div>
        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <div style={{ visibility: sectionVisiblity ? "visible" : "hidden" }}>
          <FieldArray name="array" validateOnChange={false}>
            <div>
              {prerequisites?.map((prerequisite, index) => {
                return (
                  <>
                    {/* <label className="font-color-grey-500 font_16 lh-17 mb-13"> متطلبات الدورة</label> */}
                    <InputFieldFloat
                      // label={"متطلبات الدورة"}
                      type="text"
                      name={`prerequisites.${index}`}
                      value={prerequisite}
                      onChange={(e) => handleChange(e)}
                      placeholder="اكتب هنا..."
                      disabled={disabled}
                    >
                      {prerequisites.length > 1 && (
                        <button
                          className="flex align-items-center justify-content-between remove-btn"
                          id="deleteDepartmentButton"
                          type="button"
                          onClick={() => {
                            let temp = [...prerequisites];
                            temp.splice(index, 1);
                            form.setFieldValue("prerequisites", temp);
                          }}
                          hidden={disabled}
                        >
                          <i className="fa-regular fa-trash-can font-color-grey-600 font_20" />
                        </button>
                      )}
                    </InputFieldFloat>
                  </>
                );
              })}

              <button
                type="button"
                className="font_15 text-link-red px-0 font-regular"
                onClick={() => {
                  form.setFieldValue("prerequisites", [...prerequisites, ""]);
                }}
                hidden={disabled}
              >
                <span className="fa fa-plus mx-2" />
                <span>اضف نقطة جديدة</span>
              </button>
            </div>
          </FieldArray>
        </div>
      )}
    </div>
  );
};
export default PrerequisitesSection;
