import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import TextField from "../Inputs/TextField/TextField";
import * as yup from "yup";
import MyModal from "./MyModal";
import { categoriesMock } from "../../data";
import "../../scss/components/my-modal-with-inputs.scss";
import {
  useCreateTopicMutation,
  useUpdateTopicMutation,
} from "../../services/topics";
import {
  useCreateTagMutation,
  useUpdateTagMutation,
} from "../../services/tags";

const MyModalWithInputs = ({
  title,
  text,
  label,
  setShowModal,
  item,
  status = "add",
  hasColor = true,
  type = "topic",
}) => {
  const [createTopic] = useCreateTopicMutation();
  const [updateTopic] = useUpdateTopicMutation();
  const [createTag] = useCreateTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const [color, setColor] = useState("#000000");
  const getInitialValues = () => {
    return {
      title: status === "add" ? "" : item?.title,
      selectedColor: status === "add" ? "" : hasColor ? item?.color : "#ccc",
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: yup.object({
      title: yup.string().required("الأسم مطلوب").max(20, "الأسم طويل"),
      color: yup.string().optional(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (status === "add") {
        const formData = new FormData();
        if (type === "topic") {
          formData.append("topic[title]", values.title);
          formData.append("topic[color]", values.selectedColor);
          createTopic(formData);
        } else {
          formData.append("tag[title]", values.title);
          createTag(formData);
        }
        setSubmitting(false);
        setShowModal(false);
      } else {
        const formData = new FormData();
        if (type === "topic") {
          formData.append("topic[title]", values.title);
          formData.append("topic[color]", values.selectedColor);
          updateTopic({ id: item.id, body: formData });
          setSubmitting(false);
          setShowModal(false);
        } else {
          formData.append("tag[title]", values.title);
          updateTag({ id: item.id, body: formData });
          setSubmitting(false);
          setShowModal(false);
        }
      }
    },
  });

  const handleColorChange = (e) => {
    formik.handleChange(e);
    const newColor = e.target.value;
    setColor(newColor);
    formik.setFieldValue("selectedColor", newColor);
  };

  return (
    <MyModal setShowModal={setShowModal}>
      <div className="modal-header">
        <p className="modal-header__title">{title}</p>
        <p className="modal-header__subtitle">{text}</p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="modal-form"
        autoComplete="off"
      >
        <div className="modal-form__inputs">
          <div style={{ width: "100%" }}>
            <TextField
              id="title"
              formik={formik}
              placeholder={"الأسم"}
              error={formik.touched.title && formik.errors.title}
              errorMessage={formik.errors.title}
              value={formik.values.title}
              label={label}
            />
          </div>
          {hasColor && (
            <div className="modal-form__inputs__color">
              <p style={{ margin: "0" }}>{formik.values.selectedColor}</p>
              <input
                id="selectedColor"
                name="selectedColor"
                type="color"
                onChange={handleColorChange}
                onClick={() => formik.setFieldValue("selectedColor", color)}
                value={formik.values.selectedColor}
              />
            </div>
          )}
        </div>
        <div className="modal-form__buttons">
          <button type="submit" className="btn btn-main-color w-100">
            حفظ
          </button>
          <button
            type="button"
            className="btn btn-main-color-border w-100"
            onClick={() => setShowModal(false)}
          >
            إلغاء
          </button>
        </div>
      </form>
    </MyModal>
  );
};

export default MyModalWithInputs;
