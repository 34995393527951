import { useState } from "react";
import InputTextAreaFloat from "../../../../Inputs/InputTextAreaFloat";

const DetailsSection = ({ form, disabled }) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);

  return (
    <div className="mb-4 create-section">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font_20 font-extrabold create-section__title">
          تفاصيل الدورة
        </h2>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <InputTextAreaFloat
          type="text"
          name="details"
          rows="6"
          value={form?.values?.details}
          onChange={(e) => form?.setFieldValue("details", e?.target?.value)}
          marginBottom={24}
          inputClassName="did-floating-input__detailed"
          disabled={disabled}
        />
      )}
    </div>
  );
};
export default DetailsSection;
