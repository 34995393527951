import React from "react";
import MyModal from "./MyModal";
import "../../scss/components/my-modal-with-actions.scss";
const MyModalWithActions = ({
  icon: Icon,
  title,
  text,
  onAction,
  actionText,
  cancelText,
  setShowModal,
}) => {
  return (
    <MyModal setShowModal={setShowModal}>
      <div className="modal-with-actions-content">
        <div className="modal-with-actions-content__image">
          {Icon}
        </div>
        <p className="modal-with-actions-content__title">{title}</p>
        <p className="modal-with-actions-content__text">{text}</p>
        <div className="modal-with-actions-content__buttons">
          <button
            className="btn btn-main-color-border font-15 px-18 font-extrabold shadow-none mx-10"
            onClick={() => setShowModal(false)}
          >
            {cancelText}
          </button>
          <button
            className="btn btn-main-color font-15 px-30 font-extrabold shadow-none"
            onClick={onAction}
          >
            {actionText}
          </button>
        </div>
      </div>
    </MyModal>
  );
};

export default MyModalWithActions;
