import {api} from "./index";

export const search = api.injectEndpoints({
    endpoints: (builder) => ({
        getSearchResults: builder.query({
            query: ( {url, query}) => `/api/v1/tutor/${url}?filters[query]=${query}&sort=-sort`,
        }),
    }),
})

export const {useGetSearchResultsQuery} = search;