import React from "react";
import PropTypes from "prop-types";

export default function Checkbox({
  children,
  id,
  checked,
  setChecked,
  labelClassName,
  variant,
  error,
  disabled,
}) {
  return (
    <div className={`form-check checkbox-main`}>
      {/* checked */}
      <input
        type="checkbox"
        className={`form-check-input form-check-input--${variant}`}
        id={id}
        name="option1"
        value="something"
        checked={checked}
        onChange={() => setChecked(!checked)}
        disabled={disabled}
      />
      <div>
        <label
          className={`form-check-label ${labelClassName} mt-1 mb-2`}
          htmlFor={id}
        >
          {children}
        </label>
        {error && <div class={`font-color-red font_14 font-bold`}>{error}</div>}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  labelClassName: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Checkbox.defaultProps = {
  labelClassName: "",
  variant: "",
  error: null,
};
