import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputFieldFloat from "../components/Inputs/InputFieldFloat";
import { useSendResetPasswordEmailMutation } from "../services/auth";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("من فضلك ادخل بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
});

const ResetPasswordViaEmail = () => {
  let navigate = useNavigate();
  const [sendResetEmail] = useSendResetPasswordEmailMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const result = await sendResetEmail({
        email: values.email,
      });
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        toast.success("تم ارسال رسالة لبريدك");
        navigate("/login");
      }
    },
  });
  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              backgroundImage: `url('/images/admin-bg.png')`,
            }}
          >
            <div className="content-admin">
              <h1>
                تعلم كل يوم
                <span>درس جديد...</span>
              </h1>
              <p>استثمر وقتك في تعلم دورات أون لاين</p>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <h1 className="font-extrabold">اعادة تعيين كلمة المرور</h1>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  marginBottom={0}
                  label="البريد الالكتروني"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />

                <button
                  type="submit"
                  className="btn btn-main-color d-block w-100 mt-4"
                >
                  ارسال
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordViaEmail;
