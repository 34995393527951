import React, { useState, useEffect } from "react";
import RichText from "../../RichTextEditor/RichText";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";
import "./CreateBlogSection.scss";
import TextField from "../../Inputs/TextField/TextField";

const CreateBlogSection = ({
  id,
  sectionTitle,
  inputLabel = "عنوان المقالة",
  inputPlaceholder = "اكتب عنوان المقالة هنا",
  editorLabel = "النص",
  editorPlaceholder = "اكتب النص هنا",
  isOpen = true,
  hasDelete = true,
  onDelete,
  hasTitle = true,
  formik,
  index,
  formikTitle = "",
  formikBody,
}) => {
  const [showSection, setShowSection] = useState(isOpen || false);
  const [title, setTitle] = useState(formikTitle);
  const getInitialHtmlContent = () => {
    if (id === "blog.introduction") {
      return formik.values.blog.introduction;
    } else if (id === "blog.conclusion") {
      return formik.values.blog.conclusion;
    } else {
      return formikBody;
    }
  };

  const [htmlContent, setHtmlContent] = useState(getInitialHtmlContent());
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    if (
      formik &&
      id &&
      (id === "blog.introduction" || id === "blog.conclusion")
    ) {
      formik.setFieldValue(id, htmlContent);
    }

    if (formik && id && id.includes("section")) {
      formik.setFieldValue(id, {
        title: title,
        body: htmlContent,
      });
    }
  }, [title, htmlContent, id]);

  const handleShowSection = () => {
    setShowSection((prev) => !prev);
  };

  return (
    <div
      className="section"
      style={{ height: !showSection && "6.25rem" }}
      key={id}
    >
      <div className="section__header">
        <div className="section__header-title">
          <p className="section__header-title-text">{sectionTitle}</p>
          {hasTitle && (
            <p className="section__header-title-sectionTitle">{title}</p>
          )}
        </div>
        <div className="section__header-icons-container">
          {hasDelete && (
            <button
              className="section__header-icons-container__icon"
              onClick={onDelete}
              type="button"
            >
              <FaRegTrashCan size={24} color="#9E9DA4" />
            </button>
          )}
          <button
            className="section__header-icons-container__icon"
            onClick={handleShowSection}
            type="button"
          >
            {showSection ? (
              <MdKeyboardArrowUp color="#9E9DA4" size={52} />
            ) : (
              <MdKeyboardArrowDown color="#9E9DA4" size={52} />
            )}
          </button>
        </div>
      </div>
      {hasTitle && (
        <div
          className="section__input"
          style={{ display: showSection ? "block" : "none" }}
        >
          <label className="section__input__label">{inputLabel}</label>
          <input
            id={id}
            className="section__input__field"
            type="text"
            name={`${id}.title`}
            placeholder={inputPlaceholder}
            value={title}
            onChange={handleTitleChange}
          />
        </div>
      )}
      <div
        className="section__editor"
        style={{ display: showSection ? "block" : "none" }}
      >
        <label className="section__editor__label">{editorLabel}</label>
        <div className="section__editor__editor">
          <RichText
            placeholder={editorPlaceholder}
            setHtmlContent={setHtmlContent}
            htmlContent={htmlContent}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateBlogSection;
