import { useState } from "react";
const CourseLevelSection = ({ value, form, error, touched, disabled }) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const courseLevels = [
    { value: "beginner", name: "مبتدئ" },
    { value: "intermediate", name: "متوسط" },
    { value: "advanced", name: "متقدم" },
    { value: "all_levels", name: "كل المستويات" },
  ];
  return (
    <div className="mb-4 create-section create-section--level">
      <div className="d-flex alig-items-center justify-content-between">
        <h2 className="font_20 font-extrabold mb-0">مستوى الدورة</h2>

        <button
          className="text-link-grey btn-link"
          onClick={() => {
            setSectionVisibility(!sectionVisiblity);
          }}
        >
          <i
            class={`fas fa-angle-${
              sectionVisiblity ? "up" : "down"
            } font_24 font-color-grey`}
          />
        </button>
      </div>
      {sectionVisiblity && (
        <div
          className={`form-check checkbox-main input-text flex-wrap flex-md-nowrap create-section--level__menu-check`}
        >
          {courseLevels?.map((level) => {
            return (
              <div
                className="d-flex mb-3 mb-md-0"
                onChange={(e) => {
                  form.setFieldValue("level", e.target.value);
                }}
              >
                {form.values.level == level.value ? (
                  <input
                    name="course-level"
                    className="form-check-input form-check-input--radio"
                    type="radio"
                    id={`level-${level.value}`}
                    value={level.value}
                    checked
                    disabled={disabled}
                  />
                ) : (
                  <input
                    name="course-level"
                    className="form-check-input form-check-input--radio"
                    type="radio"
                    id={`level-${level.value}`}
                    value={level.value}
                    disabled={disabled}
                  />
                )}

                <label className="form-check-label mt-1">{level.name}</label>
              </div>
            );
          })}

          {error.level && touched.level ? (
            <div style={{ marginBottom: "25px" }} className="input-error">
              {error.level}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default CourseLevelSection;
