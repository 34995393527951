import { useState, useEffect } from "react";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import Radio from "../Inputs/Radio";
import Modal from "./Modal";
import InputFieldFloat from "../Inputs/InputFieldFloat";
import DatePickerInput from "../Inputs/DatePickerInput";
import { useCreateCouponMutation } from "../../services/coupons";

const validationSchema = yup.object({
  name: yup.string().required("الاسم مطلوب"),
  date: yup.string().required("التاريخ مطلوب"),
  amount: yup
    .number()
    .required("الرقم مطلوب")
    .positive("يجب ان يكون الرقم اكبر من 0"),
  code: yup.string().required("الكود مطلوب"),
});

const CreateNewCouponModal = ({ isOpened, setIsOpened }) => {
  const [isPrecentage, setIsPrecentage] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  const [createCoupon] = useCreateCouponMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      date: "",
      amount: 0,
      code: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const result = await createCoupon({
        name: values?.name,
        coupon: values?.code,
        discount: values?.amount,
        is_percentage: isPrecentage,
        start_at: values?.date,
      });
      if (result?.error) {
        toast.error(result?.error?.data?.message);
      } else {
        // resetForm();
        // setIsOpened(false);
        // setCloseModal(true);
        toast.success("تم حفظ الكوبون بنجاح");
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        console.log("submitting form");
        formik.handleSubmit(e);
      }}
    >
      <Modal
        id="create-new-coupon"
        title={"إضافة كوبون جديد"}
        mainBtn={"حفظ الكوبون"}
        mainBtnOnclick={() => {
          document.getElementById("submit-btn").click();
        }}
        // mainBtnOnclick={formik.submitForm}
        className="invite-employee-modal"
        maxWidth="530"
        isOpened={isOpened}
        modalHeaderClassName={`mx-auto pb-0 pt-4`}
        hasCancelBtn
        closeModal={closeModal}
        onClose={() => {
          setCloseModal(false);
          formik.resetForm();
        }}
        modalFooterClassName={`py-0 mb-36 mt-10`}
      >
        <div className="row">
          <label className="input-text__label font_14 font-color-grey-700 font-bold">
            اسم الكوبون
          </label>
          <InputFieldFloat
            marginBottom={15}
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            touched={formik.touched.name}
            placeholder="مثال: مناسبة عيد الأم"
            isWhiteInput
          />
        </div>

        <div className="row">
          <label className="input-text__label font_14 font-color-grey-700 font-bold">
            كود الكوبون
          </label>
          <InputFieldFloat
            marginBottom={15}
            type="text"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.errors.code}
            touched={formik.touched.code}
            placeholder="مثال: TADARAB20"
            isWhiteInput
          />
        </div>

        <div className="row">
          <label className="input-text__label font_14 font-color-grey-700 font-bold">
            {" "}
            نسبة الخصم
          </label>

          <div className="d-sm-flex align-items-center justify-content-between">
            <Radio
              id="present"
              name="is_percentage"
              value={true}
              setValue={setIsPrecentage}
              className={`flex-fill`}
            >
              <p className="mb-0 font-color-grey-500 font_16">% نسبة مئوية </p>
            </Radio>

            <Radio
              id="amount"
              name="is_percentage"
              value={false}
              setValue={setIsPrecentage}
              className={`flex-fill`}
            >
              <p className="mb-0 font-color-grey-500 font_16"> مبلغ</p>
            </Radio>

            <InputFieldFloat
              marginBottom={15}
              type="number"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.errors.amount}
              touched={formik.touched.amount}
              placeholder="اكتب القيمة هنا ..."
              isWhiteInput
              className={`flex-fill`}
              style={{ width: "200px" }}
            />
          </div>
        </div>

        <div className="row">
          <label className="input-text__label font_14 font-color-grey-700 font-bold">
            تاريخ بداية الكوبون
          </label>
          <DatePickerInput
            marginBottom={7}
            name="date"
            minDate={new Date()}
            value={formik.values.date}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            error={formik.errors.date}
            touched={formik.touched.date}
            placeholder="12/03/2022"
            isWhiteInput
            hasIcon
          />
          <p className="font-color-red font_13 mb-0 px-2">
            كود الكوبون فعال لمدة خمس أيام فقط
          </p>
        </div>
        <button
          type="submit"
          id="submit-btn"
          style={{ display: "none" }}
        ></button>
      </Modal>
    </form>
  );
};

export default CreateNewCouponModal;
