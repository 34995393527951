import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { getBlogInitialValue, toArabicNumerals } from "../utils/helpers";
import SelectWithRadioOptions from "../components/Inputs/SelectWithRadioOptions/SelectWithRadioOptions";
import SelectWithCheckboxOptions from "../components/Inputs/SelectWithCheckboxes/SelectWithCheckboxes";
import CreateBlogSection from "../components/Blogs/CreateBlogSection/CreateBlogSection";
import DateTimePicker from "../components/Inputs/DateTimePicker/DateTimePicker";
import StickyHeader from "../components/Blogs/StickyHeader/StickyHeader";
import AddBlogInfo from "../components/Blogs/AddBlogInfo/AddBlogInfo";
import TextField from "../components/Inputs/TextField/TextField";
import ImageUpload from "../components/Inputs/ImageUpload";
import { FaRegTrashAlt } from "react-icons/fa";
import PreviewBlog from "./PreviewBlog";
import { GoPlus } from "react-icons/go";
import { toast } from "react-toastify";
import "../scss/create_blogs.scss";
import MyModalWithActions from "../components/Modals/MyModalWithActions";
import { useGetTagsQuery } from "../services/tags";
import { useGetCategoriesQuery } from "../services/categories";
import { useCreateBlogMutation } from "../services/blogs";
import { addNewBlogSchema } from "../controllers/validationSchemas";
import { useListTutorsQuery } from "../services/tutors";

const AddNewBlog = () => {
  const currentUser = useSelector((state) => state?.auth?.currentUser);
  //states
  const [selectedDate, setDate] = useState(null);
  const [sections, setSections] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [blogData, setBlogData] = useState(new FormData());
  const navigation = useNavigate();

  //rtk queries
  const { data: tags } = useGetTagsQuery();
  const { data: topics } = useGetCategoriesQuery();
  const { data: tutors } = useListTutorsQuery();
  const [createBlog] = useCreateBlogMutation();

  //methods
  const addSection = () => {
    if (sections.length >= 50) {
      alert("لا يمكنك اضافة اكثر من 50 قسم");
      return;
    }
    setSections((prev) => [
      ...prev,
      <CreateBlogSection
        key={sections.length}
        id={sections.length}
        sectionTitle={`القسم ${toArabicNumerals(sections.length + 1)}`}
        isOpen={true}
      />,
    ]);
  };

  const removeSection = (index) => {
    if (sections.length === 1 && index === 0) {
      alert("لا يمكنك حذف القسم الأول");
      return;
    } else {
      setSections((prev) => {
        const newSections = [...prev.slice(0, index), ...prev.slice(index + 1)];
        toast.success("تم حذف القسم بنجاح");
        return newSections;
      });
    }
  };

  const formik = useFormik({
    initialValues: getBlogInitialValue(currentUser?.is_admin, selectedDate),
    validationSchema: addNewBlogSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const formData = new FormData();
      try {
        formData.append("blog[title]", values.blog.title);
        formData.append("blog[image]", values.image);
        formData.append("blog[status]", values.blog.status);
        values.tag_ids.map((tag) => {
          formData.append("blog[tag_ids][]", tag);
        });
        values.topic_ids.map((topic) => {
          formData.append("blog[topic_ids][]", topic);
        });
        values.blog_sections_attributes.forEach((section, index) => {
          formData.append(
            `blog[blog_sections_attributes][${index}][title]`,
            section.title
          );
          formData.append(
            `blog[blog_sections_attributes][${index}][body]`,
            section.body
          );
        });
        formData.append("blog[meta_title]", values.blog.meta_title);
        formData.append("blog[meta_description]", values.blog.meta_description);
        formData.append("blog[conclusion]", values.blog.conclusion);
        formData.append("blog[introduction]", values.blog.introduction);
        if (currentUser?.is_admin) {
          formData.append("blog[tutor_id]", values.tutor_id);
          formData.append("blog[published_at]", values.published_at);
        }
        setBlogData(formData);
        setShowSubmitModal(true);
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  const tagOptions = tags?.data.map((tag) => ({
    label: tag.title,
    name: tag.id,
  }));

  const tutorOptions = tutors?.data?.map((tutor) => ({
    label: (
      <div className="d-flex align-items-center gap-2">
        <img
          src={tutor.avatar_url}
          alt={tutor.name_ar}
          className="img"
          width={40}
          height={40}
        />
        <div className="d-flex align-items-center gap-2">{tutor.name_ar}</div>
      </div>
    ),
    name: tutor.id,
    tutorName: tutor.name_ar,
  }));

  const topicOptions = topics?.data?.map((topic) => ({
    label: topic.title,
    name: topic.id,
  }));

  return (
    <main className="blog-page">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        autoComplete="off"
      >
        <StickyHeader
          setShowDeleteModal={setShowDeleteModal}
          title="إضافة مقالة جديدة"
          isPreview={isPreview}
          setIsPreview={setIsPreview}
        />
        <AddBlogInfo isPreview={isPreview} setIsPreview={setIsPreview} />
        {isPreview ? (
          <PreviewBlog data={formik.values} />
        ) : (
          <div className="blog-page__content">
            <div className="blog-page__content__header">
              {/* INPUTS */}
              <div className="blog-page__content__header-inputs">
                <div className="blog-page__content__header-inputs__title">
                  <TextField
                    id="blog.title"
                    label={"عنوان المقالة"}
                    placeholder="عنوان المقالة"
                    formik={formik}
                    value={formik.values.blog.title}
                    error={
                      formik?.errors?.blog?.title &&
                      formik?.touched?.blog?.title
                    }
                    errorMessage={formik.errors?.blog?.title}
                  />
                </div>
                {currentUser?.is_admin && (
                  <div className="blog-page__content__header-inputs__author-date">
                    <SelectWithRadioOptions
                      placeholder={"اختر كاتب المقال"}
                      options={tutorOptions}
                      id="tutor_id"
                      value={formik.values.tutor_id}
                      // onChange={formik.handleChange}
                      error={formik?.errors?.tutor_id !== undefined} // add is submitting condition
                      errorMessage={formik?.errors?.tutor_id}
                      formik={formik}
                    />
                    <DateTimePicker
                      name="published_at"
                      label="تاريخ نشر المقال"
                      onChange={(date) => {
                        formik.setFieldValue("published_at", date);
                      }}
                      value={formik.values.published_at}
                    />
                  </div>
                )}
                <div className="blog-page__content__header-inputs__categories-tags">
                  <SelectWithCheckboxOptions
                    id="topic_ids"
                    value={formik.values.topic_ids}
                    // onChange={formik.handleChange}
                    formik={formik}
                    placeholder={"اختر القسم"}
                    options={topicOptions}
                    error={formik?.errors?.topic_ids !== undefined} // add is submitting condition
                    errorMessage={formik?.errors?.topic_ids}
                  />

                  <SelectWithCheckboxOptions
                    id="tag_ids"
                    isCategory={false}
                    value={formik.values.tag_ids}
                    placeholder={"اختر المواضيع"}
                    // onChange={formik.handleChange}
                    formik={formik}
                    options={tagOptions}
                    error={formik?.errors?.tags !== undefined} // add is submitting condition
                    errorMessage={formik?.errors?.tags}
                  />
                </div>
              </div>
              {/* INPUTS */}

              {/* IMAGE UPLOAD */}
              <div className="blog-page__content__image-upload">
                <p className="blog-page__content__image-upload__title">
                  صورة المقالة
                </p>
                <p className="blog-page__content__image-upload__description">
                  يفضل استخدام صورة بجودة عالية بصيغة JPG أو PNG
                </p>
                <ImageUpload
                  id={"image"}
                  formik={formik}
                  error={formik?.errors?.image && formik?.touched?.image}
                  errorMessage={formik?.errors?.image}
                />
              </div>
              {/* IMAGE UPLOAD */}
            </div>
            {/* Sections */}
            <section className="blog-page__content__data">
              <p className="blog-page__content__data__title">محتوى المقالة</p>
              <div className="blog-page__content__data__sections">
                <CreateBlogSection
                  sectionTitle="المقدمة"
                  inputLabel={"عنوان المقدمة"}
                  inputPlaceholder={"مقدمة عن المقالة و أهدافها"}
                  editorLabel={"النص"}
                  editorPlaceholder={"اكتب النص هنا"}
                  isOpen={true}
                  hasDelete={false}
                  hasTitle={false}
                  formik={formik}
                  id="blog.introduction"
                />
                {/* main sections */}
                {sections.length > 0 &&
                  sections.map((_, index) => (
                    <CreateBlogSection
                      key={index}
                      isOpen={true}
                      sectionTitle={`القسم ${toArabicNumerals(index + 1)}`}
                      inputLabel="عنوان القسم"
                      inputPlaceholder="اكتب عنوان القسم هنا"
                      editorLabel="النص"
                      editorPlaceholder="اكتب النص هنا"
                      onDelete={() => removeSection(index)}
                      formik={formik}
                      id={`blog_sections_attributes[${index}]`}
                      index={index}
                    />
                  ))}
                {/* main sections */}

                {/* Add section button */}
                <button
                  className="blog-page__content__add-section font-extrabold"
                  onClick={addSection}
                  type="button"
                >
                  <GoPlus size={24} />
                  قم بإضافة قسم جديد
                </button>
                {/* Add section button */}

                <CreateBlogSection
                  sectionTitle="الخاتمة"
                  inputLabel={"عنوان الخاتمة"}
                  inputPlaceholder={"مقدمة عن المقالة و أهدافها"}
                  editorLabel={"النص"}
                  editorPlaceholder={"اكتب النص هنا"}
                  isOpen={true}
                  hasDelete={false}
                  hasTitle={false}
                  formik={formik}
                  id="blog.conclusion"
                />
              </div>
            </section>
            {/* Sections */}

            {/* Meta data  */}
            <section className="blog-page__content__meta">
              <TextField
                id="blog.meta_title"
                label={"Meta Title"}
                placeholder="ادخل عنوان الميتا"
                formik={formik}
                value={formik.values.blog.meta_title}
                error={
                  formik?.errors?.blog?.title &&
                  formik?.touched?.blog?.meta_title
                }
                errorMessage={formik.errors?.blog?.meta_title}
              />
              <TextField
                id="blog.meta_description"
                label={"Meta Description"}
                placeholder="ادخل تفاصيل الميتا"
                formik={formik}
                value={formik.values.blog.meta_description}
                error={
                  formik?.errors?.blog?.meta_description &&
                  formik?.touched?.blog?.meta_description
                }
                errorMessage={formik.errors?.blog?.meta_description}
              />
            </section>

            {/* Meta data  */}
          </div>
        )}
      </form>
      {showDeleteModal && (
        <MyModalWithActions
          icon={<FaRegTrashAlt color="#AF151F" size={32} />}
          title="حذف المقالة"
          text="هل أنت متأكد من حذف هذه المقالة؟ هذا سوف يحذفها بشكل نهائي من المنصة"
          onAction={() => {
            navigation("/blogs");
            setShowDeleteModal(false);
          }}
          actionText="حذف"
          cancelText="الغاء"
          setShowModal={setShowDeleteModal}
        />
      )}
      {showSubmitModal && (
        <MyModalWithActions
          icon={<img src="/images/submit.svg" alt="submit" />}
          setShowModal={setShowSubmitModal}
          title="إرسال المقالة للمراجعة"
          text="هل تريد إرسال هذه المقالة إلى فريق تدرب للمراجعة النهائية؟ سوف يتمأرسال حالة المقالة إليك في أقرب وقت"
          actionText={"إرسال للمراجعة"}
          onAction={async () => {
            // setSubmitting(true);
            console.log("submitting");
            const resp = await createBlog(blogData);
            if (resp.error.status === 500) {
              // image error is accepted till eu-central issue is fixed
              console.log(resp);
              toast.success("تم اضافة المقالة بنجاح");
              navigation("/blogs");
            } else {
              toast.error(resp.error.data.message);
            }
            setShowSubmitModal(false);
          }}
          cancelText={"الغاء"}
        />
      )}
    </main>
  );
};

export default AddNewBlog;
