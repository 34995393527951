import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toArabicNumerals } from "../utils/helpers";
import SelectWithRadioOptions from "../components/Inputs/SelectWithRadioOptions/SelectWithRadioOptions";
import SelectWithCheckboxOptions from "../components/Inputs/SelectWithCheckboxes/SelectWithCheckboxes";
import CreateBlogSection from "../components/Blogs/CreateBlogSection/CreateBlogSection";
import DateTimePicker from "../components/Inputs/DateTimePicker/DateTimePicker";
import StickyHeader from "../components/Blogs/StickyHeader/StickyHeader";
import AddBlogInfo from "../components/Blogs/AddBlogInfo/AddBlogInfo";
import TextField from "../components/Inputs/TextField/TextField";
import ImageUpload from "../components/Inputs/ImageUpload";
import { FaRegTrashAlt } from "react-icons/fa";
import PreviewBlog from "./PreviewBlog";
import { GoPlus } from "react-icons/go";
import { toast } from "react-toastify";
import "../scss/create_blogs.scss";
import MyModalWithActions from "../components/Modals/MyModalWithActions";
import { useGetBlogQuery, useUpdateBlogMutation } from "../services/blogs";
import { useGetCategoriesQuery } from "../services/categories";
import { useGetTagsQuery } from "../services/tags";
import { addNewBlogSchema } from "../controllers/validationSchemas";

const EditBlog = () => {
  const { blogId } = useParams();
  const currentUser = useSelector((state) => state?.auth?.currentUser);
  //states
  const [selectedDate, setDate] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [sections, setSections] = useState();

  //rtk queries
  const { data: tags } = useGetTagsQuery();
  const { data: topics } = useGetCategoriesQuery();
  const [updateBlog] = useUpdateBlogMutation();
  const [editedBlogData, setEditedBlogData] = useState(new FormData());
  const { data: blogData, isLoading } = useGetBlogQuery({ slug: blogId });
  const navigation = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      setSections(blogData?.data?.blog_sections);
    }
  }, []);

  //methods
  const addSection = () => {
    const newSections = [
      ...sections,
      <CreateBlogSection
        // key={sections.length}
        // id={sections.length}
        sectionTitle={`القسم ${toArabicNumerals(sections.length + 1)}`}
        isOpen={true}
      />,
    ];
    if (sections.length >= 15) {
      alert("لا يمكنك اضافة  أكثر من 15 مقالة");
      return;
    }
    setSections(newSections);
  };

  const removeSection = (index) => {
    if (index === 0 && sections.length === 1) {
      alert("لا يمكنك حذف القسم الأول");
      return;
    } else {
      setSections((prev) => {
        const newSections = prev.filter((_, i) => i !== index);
        toast.success("تم حذف القسم بنجاح");
        return newSections;
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      blog: {
        title: blogData?.data?.blog?.title,
        author: currentUser?.name,
        status: "published",
        meta_title: blogData?.data?.blog?.meta_title,
        meta_description: blogData?.data?.blog?.meta_description,
        introduction: blogData?.data?.blog?.introduction,
        conclusion: blogData?.data?.blog?.conclusion,
      },
      image: null,
      blog_sections_attributes: blogData?.blog_sections,
      topic_ids: [],
      tag_ids: [],
    },
    validationSchema: addNewBlogSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const formData = new FormData();
      try {
        formData.append("blog[title]", values.blog.title);
        formData.append("blog[image]", values.image);
        formData.append("blog[status]", values.blog.status);
        values.tag_ids.map((tag) => {
          formData.append("blog[tag_ids][]", tag);
        });
        values.topic_ids.map((topic) => {
          formData.append("blog[topic_ids][]", topic);
        });
        values.blog_sections_attributes.forEach((section, index) => {
          formData.append(
            `blog[blog_sections_attributes][${index}][title]`,
            section.title
          );
          formData.append(
            `blog[blog_sections_attributes][${index}][body]`,
            section.body
          );
        });
        formData.append("blog[meta_title]", values.blog.meta_title);
        formData.append("blog[meta_description]", values.blog.meta_description);
        formData.append("blog[conclusion]", values.blog.conclusion);
        formData.append("blog[introduction]", values.blog.introduction);
        setEditedBlogData(formData);
        setShowSubmitModal(true);
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  // will be replaced with real tutors..
  const options = [
    { value: "تطوير الذات", label: "تطوير الذات" },
    { value: "تطوير النفس", label: "تطوير النفس" },
    { value: "تطوير الهويه", label: "تطوير الهويه" },
  ];

  const tagOptions = tags?.data?.map((tag) => ({
    label: tag.title,
    name: tag.id,
  }));

  const topicOptions = topics?.data?.map((topic) => ({
    label: topic.title,
    name: topic.id,
  }));

  return (
    <main className="blog-page">
      {blogData !== undefined && !isLoading ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          autoComplete="off"
        >
          <StickyHeader
            setShowDeleteModal={setShowDeleteModal}
            title="تعديل المقالة"
            isPreview={isPreview}
            setIsPreview={setIsPreview}
          />
          <AddBlogInfo isPreview={isPreview} setIsPreview={setIsPreview} />
          {isPreview ? (
            <PreviewBlog data={formik.values} />
          ) : (
            <div className="blog-page__content">
              <div className="blog-page__content__header">
                {/* INPUTS */}
                <div className="blog-page__content__header-inputs">
                  <div className="blog-page__content__header-inputs__title">
                    <TextField
                      id="blog.title"
                      label={"عنوان المقالة"}
                      placeholder="عنوان المقالة"
                      formik={formik}
                      value={formik.values.blog.title}
                      error={
                        formik?.errors?.blog?.title &&
                        formik?.touched?.blog?.title
                      }
                      errorMessage={formik.errors?.blog?.title}
                    />
                  </div>
                  {currentUser?.is_admin && (
                    <div className="blog-page__content__header-inputs__author-date">
                      <SelectWithRadioOptions
                        options={options}
                        name="blogAuthor"
                        value={formik.values.blogAuthor}
                        onChange={formik.handleChange}
                        placeholder={"اختر كاتب المقال"}
                      />
                      <DateTimePicker
                        name="placedDate"
                        label="تاريخ نشر المقال"
                        onChange={(date) => {
                          formik.setFieldValue("placedDate", date);
                          setDate(date);
                        }}
                        value={selectedDate}
                      />
                    </div>
                  )}
                  <div className="blog-page__content__header-inputs__categories-tags">
                    <SelectWithCheckboxOptions
                      id="topic_ids"
                      value={formik.values.topic_ids}
                      onChange={formik.handleChange}
                      formik={formik}
                      placeholder={"اختر القسم"}
                      options={topicOptions}
                      error={formik?.errors?.topic_ids !== undefined} // add is submitting condition
                      errorMessage={formik?.errors?.topic_ids}
                    />

                    <SelectWithCheckboxOptions
                      id="tag_ids"
                      isCategory={false}
                      value={formik.values.tag_ids}
                      placeholder={"اختر المواضيع"}
                      onChange={formik.handleChange}
                      formik={formik}
                      options={tagOptions}
                      error={formik?.errors?.tag_ids !== undefined} // add is submitting condition
                      errorMessage={formik?.errors?.tags}
                    />
                  </div>
                </div>
                {/* INPUTS */}

                {/* IMAGE UPLOAD */}
                <div className="blog-page__content__image-upload">
                  <p className="blog-page__content__image-upload__title">
                    صورة المقالة
                  </p>
                  <p className="blog-page__content__image-upload__description">
                    يفضل استخدام صورة بجودة عالية بصيغة JPG أو PNG
                  </p>
                  <ImageUpload
                    id={"image"}
                    formik={formik}
                    error={formik?.errors?.image && formik?.touched?.image}
                    errorMessage={formik?.errors?.image}
                  />
                </div>
                {/* IMAGE UPLOAD */}
              </div>
              {/* Sections */}
              <section className="blog-page__content__data">
                <p className="blog-page__content__data__title">محتوى المقالة</p>
                <div className="blog-page__content__data__sections">
                  <CreateBlogSection
                    sectionTitle="المقدمة"
                    inputLabel={"عنوان المقدمة"}
                    inputPlaceholder={"مقدمة عن المقالة و أهدافها"}
                    editorLabel={"النص"}
                    editorPlaceholder={"اكتب النص هنا"}
                    isOpen={true}
                    hasDelete={false}
                    hasTitle={false}
                    formik={formik}
                    id="blog.introduction"
                  />
                  {/* main sections */}
                  {sections?.length > 0 &&
                    sections?.map((section, index) => (
                      <CreateBlogSection
                        key={index}
                        isOpen={true}
                        sectionTitle={`القسم ${toArabicNumerals(index + 1)}`}
                        inputLabel="عنوان القسم"
                        inputPlaceholder="اكتب عنوان القسم هنا"
                        editorLabel="النص"
                        editorPlaceholder="اكتب النص هنا"
                        onDelete={() => removeSection(index - 1)}
                        formik={formik}
                        id={`blog_sections_attributes[${index}]`}
                        formikTitle={section.title}
                        formikBody={section.body}
                      />
                    ))}
                  {/* main sections */}

                  {/* Add section button */}
                  <button
                    className="blog-page__content__add-section font-extrabold"
                    onClick={addSection}
                    type="button"
                  >
                    <GoPlus size={24} />
                    قم بإضافة قسم جديد
                  </button>
                  {/* Add section button */}

                  <CreateBlogSection
                    sectionTitle="الخاتمة"
                    inputLabel={"عنوان الخاتمة"}
                    inputPlaceholder={"مقدمة عن المقالة و أهدافها"}
                    editorLabel={"النص"}
                    editorPlaceholder={"اكتب النص هنا"}
                    isOpen={true}
                    hasDelete={false}
                    hasTitle={false}
                    formik={formik}
                    id="blog.conclusion"
                  />
                </div>
              </section>
              {/* Sections */}

              {/* Meta data  */}
              <section className="blog-page__content__meta">
                <TextField
                  id="blog.meta_title"
                  label={"Meta Title"}
                  placeholder="ادخل عنوان الميتا"
                  formik={formik}
                  value={formik.values.blog.meta_title}
                  error={
                    formik?.errors?.blog?.title &&
                    formik?.touched?.blog?.meta_title
                  }
                  errorMessage={formik.errors?.blog?.meta_title}
                />

                <TextField
                  id="blog.meta_description"
                  label={"Meta Description"}
                  placeholder="ادخل تفاصيل الميتا"
                  formik={formik}
                  value={formik.values.blog.meta_description}
                  error={
                    formik?.errors?.blog?.meta_description &&
                    formik?.touched?.blog?.meta_description
                  }
                  errorMessage={formik.errors?.blog?.meta_description}
                />
              </section>

              {/* Meta data  */}
            </div>
          )}
        </form>
      ) : null}
      {showDeleteModal && (
        <MyModalWithActions
          icon={<FaRegTrashAlt color="#AF151F" size={32} />}
          title="إلغاء تعديل المقالة"
          text="هل أنت متأكد من إلغاء تعديل هذه المقالة؟"
          onAction={() => {
            navigation("/blogs");
            setShowDeleteModal(false);
          }}
          actionText="حذف"
          cancelText="الغاء"
          setShowModal={setShowDeleteModal}
        />
      )}
      {showSubmitModal && (
        <MyModalWithActions
          icon={<img src="/images/submit.svg" alt="submit" />}
          setShowModal={setShowSubmitModal}
          title="إرسال المقالة للمراجعة"
          text="هل تريد إرسال هذه المقالة إلى فريق تدرب للمراجعة النهائية؟ سوف يتمأرسال حالة المقالة إليك في أقرب وقت"
          actionText={"إرسال للمراجعة"}
          onAction={async () => {
            const resp = await updateBlog({ id: blogId, body: editedBlogData });
            if (resp.error.status === 500) {
              toast.success("تم اضافة المقالة بنجاح");
              navigation("/blogs");
            } else {
              toast.error(resp.error.data.message);
            }
            setShowSubmitModal(false);
          }}
          cancelText={"الغاء"}
        />
      )}
    </main>
  );
};

export default EditBlog;
