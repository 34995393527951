export const userProfileTabs = [
  {
    id: 0,
    title: "البيانات الشخصية",
    route: "personal-details",
  },
  {
    id: 1,
    title: "الدورات التدريبية",
    route: "training-courses",
  },
  {
    id: 2,
    title: "الفواتير و المعاملات",
    route: "transactions-bills",
  },
];
