import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import v from "../../scss/helpers/_export-theme-variables.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, labels, backgroundColor, borderColor }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  };

  return (
    <Line
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          // title: {
          //   display: true,
          //   text: "تقارير الربح السنوي",
          //   align: "end",
          //   color: v.colorBlack,
          //   padding: {
          //     bottom: 28,
          //   },
          //   font: {
          //     family: v.fontFamilyBase,
          //     size: 20,
          //     weight: 800,
          //   },
          // },
        },
      }}
    />
  );
};

export default LineChart;
