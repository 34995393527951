import StatsCard from "../cards/StatsCard";

//SCSS
import v from "../../scss/helpers/_export-theme-variables.scss";

const SquadTab = ({ monthlyRevenues }) => {
  return (
    <div className="revenue__summary-reports__content">
      <div className="row">
        <div className="col-12 col-md-4">
          <StatsCard
            bgColor={v.colorgrey60}
            title="صافي الربح"
            num={monthlyRevenues?.one_time_revenue}
            text="د.ك"
            numFontSize={24}
            numClassName="mb-0"
            containerClassName="h-auto px-21 py-22"
            textFontSize={16}
            textFontWeight="font-bold"
          ></StatsCard>
        </div>
      </div>
    </div>
  );
};

export default SquadTab;
