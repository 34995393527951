import React, { useState } from "react";
import "./Header.scss";
import Tags from "../Tags/Tags";
import Topics from "../Topics/Topics";

const Header = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const _renderTabContent = () => {
    if (selectedTab === 0) {
      return <Topics />;
    } else if (selectedTab === 1) {
      return <Tags />;
    }
  };

  return (
    <main className="blog-settings">
      <header className="blog-settings__header">
        <h2>إعدادات المقالات</h2>
        <div className="tabs">
          <div
            className={`tab ${selectedTab === 0 ? "active" : ""}`}
            onClick={() => setSelectedTab(0)}
          >
            أقسام المقالات
          </div>
          <div
            className={`tab ${selectedTab === 1 ? "active" : ""}`}
            onClick={() => setSelectedTab(1)}
          >
            مواضيع المقالات
          </div>
        </div>
      </header>
      {_renderTabContent()}
    </main>
  );
};

export default Header;
