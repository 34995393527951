import React from "react";

const Pagination = ({ current = 1, pages, handlePagination }) => {
  return (
    <ul className="pagination justify-content-center">
      <li className="page-item">
        <button
          className="page-link Next"
          aria-label="Next"
          onClick={() => handlePagination(--current)}
          disabled={current <= 1}
        >
          <span
            aria-hidden="true"
            className="fas fa-chevron-right fa-fw"
          ></span>
        </button>
      </li>

      {pages <= 7 &&
        [...Array(pages)].map((e, i) => {
          let pageNumber = ++i;
          return (
            <li
              className={
                current == pageNumber ? "page-item active" : "page-item"
              }
              onClick={() => handlePagination(pageNumber)}
              key={pageNumber}
            >
              <button className="page-link">{pageNumber}</button>
            </li>
          );
        })}
      {pages > 7 && current <= 4 && (
        <>
          {[...Array(5)].map((e, i) => {
            let pageNumber = ++i;
            return (
              <li
                className={
                  current == pageNumber ? "page-item active" : "page-item"
                }
                onClick={() => handlePagination(pageNumber)}
                key={pageNumber}
              >
                <button className="page-link">{pageNumber}</button>
              </li>
            );
          })}
          <li
            className="page-item d-flex align-center"
            onClick={() => handlePagination(pages)}
          >
            <button className="dots">...</button>
          </li>
        </>
      )}
      {pages > 7 && current >= pages - 3 && (
        <>
          <li
            className="page-item d-flex align-center"
            onClick={() => handlePagination(1)}
          >
            <button className="dots">...</button>
          </li>
          {new Array(pages + 1 - (pages - 4))
            .fill()
            .map((d, i) => i + (pages - 4))
            .map((pageNumber, i) => {
              return (
                <li
                  className={
                    current == pageNumber ? "page-item active" : "page-item"
                  }
                  onClick={() => handlePagination(pageNumber)}
                  key={pageNumber}
                >
                  <button className="page-link">{pageNumber}</button>
                </li>
              );
            })}
        </>
      )}

      {pages > 7 && current < pages - 3 && current > 4 && (
        <>
          <li
            className="page-item d-flex align-center"
            onClick={() => handlePagination(1)}
          >
            <button className="dots">...</button>
          </li>
          {new Array(current + 3 - (current - 2))
            .fill()
            .map((d, i) => i + (current - 2))
            .map((pageNumber, i) => {
              console.log(pageNumber);
              return (
                <li
                  className={
                    current == pageNumber ? "page-item active" : "page-item"
                  }
                  onClick={() => handlePagination(pageNumber)}
                  key={pageNumber}
                >
                  <button className="page-link">{pageNumber}</button>
                </li>
              );
            })}
          <li
            className="page-item d-flex align-center"
            onClick={() => handlePagination(pages)}
          >
            <button className="dots">...</button>
          </li>
        </>
      )}
      <li className="page-item">
        <button
          className="page-link Previous"
          aria-label="Previous"
          onClick={() => handlePagination(++current)}
          disabled={current >= pages}
        >
          <span aria-hidden="true" className="fas fa-chevron-left fa-fw"></span>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
