import { useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";

import "react-datepicker/dist/react-datepicker.css";
import v from "../../scss/helpers/_export-theme-variables.scss";

export default function DatePickerInput({
  name,
  label,
  disabled,
  marginBottom,
  error,
  touched,
  minDate,
  setFieldValue,
  onBlur,
  placeholder,
  inputClassName,
  isWhiteInput,
  hasIcon,
  incomevalue,
}) {
  const [value, setValue] = useState();
  const [isFocused, setIsFocused] = useState("");

  function updateSelectedDate(val) {
    const date = val?.toLocaleDateString("en-GB");
    setFieldValue(name, date);
    setValue(val);
  }
  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur(e) {
    setIsFocused(false);
    onBlur(e);
  }

  return (
    <div className="input-text">
      <div
        className="did-floating-label-content"
        style={{ marginBottom: marginBottom ? marginBottom + "px" : "27px" }}
      >
        <DatePicker
          className={`did-floating-input ${inputClassName} ${
            isWhiteInput ? "white-input" : ""
          }`}
          dateFormat="dd/MM/yyyy"
          name={name}
          selected={value ? value : incomevalue}
          minDate={minDate}
          onBlur={handleBlur}
          onChange={updateSelectedDate}
          disabled={disabled}
          onFocus={handleFocus}
          placeholderText={placeholder}
          value={value ? value : incomevalue}
        />
        <input
          name={name}
          style={{ display: "none" }}
          className={`did-floating-input ${inputClassName} ${
            isWhiteInput && "white-input"
          }`}
          value={value ? value : incomevalue}
          disabled={disabled}
          placeholder={isFocused ? "" : ""}
        />
        <label className="did-floating-label">{label}</label>
        {error && touched ? <div className="input-error">{error}</div> : null}

        {hasIcon && (
          <div className="input-text__icon">
            <Icon
              iconName="date-filled"
              iconSize={18}
              iconHeight={20}
              iconColor={v.colorgrey500}
            />
          </div>
        )}
      </div>
    </div>
  );
}

DatePickerInput.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.number,
  error: PropTypes.string,
  touched: PropTypes.bool,
  minDate: PropTypes.string,
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  isWhiteInput: PropTypes.bool,
  hasIcon: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  placeholder: "",
  label: "",
  disabled: false,
  marginBottom: null,
  error: "",
  touched: false,
  minDate: "",
  setFieldValue: () => {},
  onBlur: () => {},
  inputClassName: "",
  isWhiteInput: false,
  hasIcon: false,
};
