import { useSelector } from "react-redux";
import { useGetCoursesQuery } from "../services/courses";
import hexToRgba from "hex-to-rgba";
import StatsCard from "../components/cards/StatsCard";
import { useState } from "react";
import DiscoverCourseCard from "../components/cards/DiscoverCourseCard";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";
import { useGetDashboardInfoQuery } from "../services/dashboard";
import { displayDate, arabicMonths } from "../utils/arabicDatesUtilities";
import { useListLiveCoursesQuery } from "../services/liveCourses";

//SCSS
import "../scss/pages/_dashboard.scss";
import v from "../scss/helpers/_export-theme-variables.scss";
import CreateNewCourseModal from "../components/Modals/CreateNewCourseModal";
import Search from "../components/Search/Search";

const Dashboard = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [page, setPage] = useState(1);
  const { data: coursesList } = useGetCoursesQuery({ page: page, per_page: 8 }, { refetchOnMountOrArgChange: true });
  const { data: dashboardInfo } = useGetDashboardInfoQuery();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [pageLive, setPageLive] = useState(1);
  const { data: liveCoursesList } = useListLiveCoursesQuery({ page: pageLive, per_page: 8 }, { refetchOnMountOrArgChange: true });

  return (
    <div className="dashboard layout-pages">
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="font-extrabold text-black"> مرحبا بك يا {currentUser?.name} 👋 </h6>
        <button
          type="button"
          className="btn btn-main-color font-15 font-extrabold dashboard__create-course"
          onClick={() => {
            if (currentUser?.is_admin == true) {
              setShowModal(true)
            }
            else {
              navigate("/courses/create");
            }
          }
          }
          data-bs-toggle={currentUser?.is_admin ? "modal" : ''}
          data-bs-target="#create-new-course-modal"
        >
          <i className="fa-solid fa-plus dashboard__create-course__icon" />
          إنشاء دورة جديدة
        </button>
      </div>

      <div className="dashboard__summary-reports">
        <h5 className="font_20 font-extrabold text-black"> ملخص تقاريري </h5>
        {dashboardInfo?.data?.join_date ? <p className="font-color-grey"> منذ تسجيلك كمدرب {displayDate(dashboardInfo?.data?.join_date)} </p> : null}

        <div className="dashboard__summary-reports__content">
          <div className="row">
            <div className="col-12 col-md-3">
              <StatsCard
                bgColor={hexToRgba(v.colorPurple500, 0.06)}
                iconName="courses-filled"
                iconColor={v.colorPurple500}
                title={"عدد الدورات"}
                num={dashboardInfo?.data?.courses_count}
                numFontSize={24}
              />
            </div>
            <div className="col-12 col-md-3 my-3 my-md-0">
              <StatsCard
                bgColor={hexToRgba(v.colorOrange500, 0.06)}
                iconName="users-filled"
                iconColor={v.colorOrange500}
                title="عدد الطلاب المشتركين"
                num={dashboardInfo?.data?.enrollments_count}
                numFontSize={24}
              />
            </div>
            <div className="col-12 col-md-6">
              <StatsCard
                bgColor={hexToRgba(v.colorGreen300, 0.06)}
                iconName="hand-dollar-filled"
                iconColor={v.colorGreen300}
                title={`صافي الأرباح (شهر ${arabicMonths[new Date().getUTCMonth()]} ${new Date().getFullYear()})`}
                num={dashboardInfo?.data?.revenues}
                text="د.ك"
                numFontSize={24}
                textFontSize={20}
                textFontWeight="font-bold"
              >
                <button className="font_15 btn btn-grey-color-border font-extrabold stats-show-details" onClick={() => navigate('/revenue')}>
                  اعرض التفاصيل
                  <i className="fa-solid fa-angle-left stats-show-details__icon" />
                </button>
              </StatsCard>
            </div>
          </div>

        </div>
      </div>
      <div className="mt-5">
        <Search id="search" name="search" placeholder="ابحث عن دوراتك" url="courses" type={"courses"} />
      </div>

      {
        coursesList?.data.length > 0 && (
          <div className="dashboard__summary-reports">
            <h5 className="font_20 font-extrabold text-black"> دوراتي </h5>
            <p className="font-color-grey mb-0">لديك  <strong className="font-extrabold">{dashboardInfo?.data?.courses_count}</strong> دورات على منصة تدرب</p>
            <div className="dashboard__summary-reports__content">
              <div className="row">
                {coursesList?.data?.map((course) => (
                  <div key={course.id} className="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                    <DiscoverCourseCard course={course} titleFontSize={14} isLive={false} />
                  </div>
                )
                )}
              </div>
              {coursesList?.pagination?.count > coursesList?.pagination?.per_page && (
                <Pagination
                  current={coursesList?.pagination?.current}
                  pages={coursesList?.pagination?.pages}
                  handlePagination={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        )
      }
      {
        liveCoursesList?.data.length > 0 && currentUser?.is_admin && (
          <div className="dashboard__summary-reports">
            <h5 className="font_20 font-extrabold text-black"> دوراتي المباشرة</h5>
            <p className="font-color-grey mb-0">لديك  <strong className="font-extrabold">{liveCoursesList?.pagination?.count}</strong> دورات مباشرة على منصة تدرب</p>
            <div className="dashboard__summary-reports__content">
              <div className="row">
                {liveCoursesList?.data?.map((course) => (
                  <div key={course.id} className="col-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                    <DiscoverCourseCard course={course} titleFontSize={14} isLive={true} />
                  </div>
                )
                )}
              </div>
              {liveCoursesList?.pagination?.count > liveCoursesList?.pagination?.per_page && (
                <Pagination
                  current={liveCoursesList?.pagination?.current}
                  pages={liveCoursesList?.pagination?.pages}
                  handlePagination={(pageLive) => setPageLive(pageLive)}
                />
              )}
            </div>
          </div>
        )
      }
      <CreateNewCourseModal isOpened={showModal} setIsOpened={setShowModal} />
    </div >
  );
};

export default Dashboard;
