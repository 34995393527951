import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import InputFieldFloat from "../components/Inputs/InputFieldFloat";
import PasswordInputFloat from "../components/Inputs/PasswordInputFloat";
import { useLoginMutation } from "../services/auth";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { useLazyShowProfileQuery } from "../services/auth";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("من فضلك ادخل بريد الكتروني صحيح")
    .required("البريد الالكتروني مطلوب"),
  password: yup
    .string()
    .min(3, "كلمة المرور يجب ان تكون مكونة من 3 حروف كحد ادني")
    .required("كلمة المرور مطلوبة"),
});

const Login = () => {
  const [login] = useLoginMutation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [showProfile, { data: profile }] = useLazyShowProfileQuery();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const result = await login(values);
      if (result?.error) {
        toast.error(result?.error?.data?.message);
        dispatch(setCurrentUser(null));
      } else {
        dispatch(setCurrentUser(result?.data?.data));
        localStorage.setItem("token", result?.data?.data?.token);
        showProfile();
        navigate("/dashboard");
      }
    },
  });

  const redirectToResetViaEmailForm = () =>
    navigate("/reset-password/email-confirmation");

  return (
    <div className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 login-page__banner"
            style={{
              backgroundImage: `url('/images/admin-bg.png')`,
            }}
          >
            <div className="content-admin">
              <h1>
                مرحبًا بك <span>في لوحة التحكم الخاصة بالمدربين.</span>
              </h1>
              <p>سجّل الدخول لبدء رحلتك التدريبية معنا.</p>
            </div>
          </div>
          <div className="col">
            <div className="login-page__content">
              <div className="content-header">
                <img className="logo" alt="" src="/images/logo.svg" />
                <h1>تسجيل دخول</h1>
                <p>مرحباً بك مرة أخرى , من فضلك ادخل بياناتك لتسجيل الدخول</p>
              </div>
              <form
                className="content-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <InputFieldFloat
                  label="البريد الالكتروني"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
                <PasswordInputFloat
                  label="كلمة المرور"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
                <button
                  className="btn btn-main-color d-block w-100"
                  type="submit"
                >
                  تسجيل دخول
                </button>
                <button
                  className="reset-password text-link-red"
                  type="button"
                  onClick={() => redirectToResetViaEmailForm()}
                >
                  هل نسيت كلمة المرور؟
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
