import React from "react";
import "./user-training-courses.scss";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../../services/users";

const UserTrainingCourses = () => {
  let { id } = useParams();
  const { data: user } = useGetUserByIdQuery({ userId: id });
  return (
    <div className="user-training-courses">
      {user.data.enrollments.length !== 0 && (
        <p className="font_16 font-color-dark font-bold">
          {user.data?.enrollments?.length} دورات
        </p>
      )}
      {user && user.data.enrollments.length ? (
        user.data.enrollments.map((course) => (
          <div className="user-training-courses__content">
            <div className="user-training-courses__content__card">
              <div className="user-training-courses__content__card__image">
                <img
                  src={course.image_url || "/images/placeholder-image.png"}
                  alt={course.title}
                />
              </div>
              <div className="user-training-courses__content__card__info">
                <p className="font_16 font-color-dark font-bold mb-0">
                  {course.title}
                </p>
                <div className="d-flex gap-2 align-items-center">
                  <div className="user-training-courses__content__card__info__progress">
                    <div
                      style={{
                        width: `${course.progress}%`,
                        height: "8px",
                        backgroundColor:
                          course.progress === 100 ? "#01C299" : "#AF151F",
                      }}
                    />
                  </div>
                  <p className="font_12 font-color-grey-500 mb-0">
                    {course.progress}
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={course.tutor.avatar_url}
                    alt={course.tutor.name}
                    width={36}
                    height={36}
                    style={{ borderRadius: "50%" }}
                  />
                  <div className="d-flex flex-column">
                    <p className="font_14 font-color-grey-500 font-bold mb-1">
                      {course.tutor.name_ar}
                    </p>
                    <span className="font_12 font-color-grey-500">
                      {course.tutor.title}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>لا يوجد دورات</p>
      )}
    </div>
  );
};

export default UserTrainingCourses;
