import Avatar from "../Inputs/Avatar";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const DiscoverCourseCard = ({ course, titleFontSize, isLive }) => {
  const navigate = useNavigate();
  return (
    <div className="card card-style h-100 border-0 p-0 discover-card mb-0">
      {!isLive && course?.topics.length > 0 ? (
        <span
          className="course-label mb-1"
          style={{ backgroundColor: `${course?.topics[0]?.color}` }}
        >
          {course?.topics[0]?.title}
        </span>
      ) : isLive && course?.topic ? (
        <span
          className="course-label mb-1"
          style={{ backgroundColor: `${course?.topic?.color}` }}
        >
          {course?.topic?.title}
        </span>
      ) : (
        <></>
      )}
      <img
        src={course?.image_url}
        className="card-img-top discover-card__image mb-0"
        alt=""
      />
      <div className="card-body">
        <div className="d-flex">
          <Avatar
            avatarUrl={course?.tutor?.avatar_url}
            width={40}
            height={40}
          />
          <div>
            <h5
              className={`card-title font_${titleFontSize} font-extrabold mb-0`}
            >
              {course?.title}
            </h5>
            <p className="font_13 font-color-grey mb-0">
              {course?.tutor?.name}
            </p>
          </div>
        </div>
      </div>
      <div className="card-footer d-flex align-items-center justify-content-between border-0 bg-transparent">
        <p class="mb-0 font-color-dark d-flex align-items-center ">
          <strong className="font_20 font-extrabold"> {course.price}</strong>
          <span className="font_13 font-bold pe-1">{course.currency_code}</span>
        </p>
        <button
          className="discover-card__edit-btn mb-12 mt-9"
          onClick={
            isLive
              ? () => {
                  navigate(`/live-courses/${course?.slug}/edit`);
                }
              : () => {
                  navigate(`/courses/${course?.slug}/edit`);
                }
          }
        >
          <i className="fa-regular fa-pen-to-square font-color-grey-200 font_20"></i>
        </button>
      </div>
    </div>
  );
};

export default DiscoverCourseCard;

DiscoverCourseCard.prototype = {
  titleFontSize: PropTypes.number,
  course: PropTypes.shape({
    avatar_url: PropTypes.string,
    image_url: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
    name: PropTypes.string,
  }).isRequired,
};

DiscoverCourseCard.defaultProps = {
  titleFontSize: 16,
};
