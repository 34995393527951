export function decodeQueryString(queryString) {
    try {
        // Decode the URI component and replace '%20' with spaces
        return decodeURIComponent(queryString.replace(/\+/g, ' '));
    } catch (error) {
        console.error('Error decoding query string:', error);
        return queryString; // Return the original string in case of an error
    }
}

export function encodeQueryString(readableString) {
    try {
        // Encode the URI component and replace spaces with '%20'
        return encodeURIComponent(readableString).replace(/%20/g, '+');
    } catch (error) {
        console.error('Error encoding query string:', error);
        return readableString; // Return the original string in case of an error
    }
}

