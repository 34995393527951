import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./SelectWithText.scss";

const SelectWithText = ({ name }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const dropDownRef = useRef(null);
  const dropDownData = [
    {
      id: 0,
      label: "نسخة تمهيدية",
      color: "#000",
    },
    {
      id: 1,
      label: "قيد المراجعة",
      color: "#f2f2f2",
    },
    {
      id: 2,
      label: "مرفوضة",
      color: "#eee",
    },
    {
      id: 3,
      label: "تم النشر",
      color: "#777",
    },
  ];

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleDropdown = () => {
    setShowDropDown((prev) => !prev);
  };

  const handleSelectItem = (item) => {
    setSelectedValue(item);
    setShowDropDown(false);
  };

  return (
    <div className="select-with-text-wrapper" ref={dropDownRef}>
      <div className="select-with-text" onClick={toggleDropdown}>
        <div className="select-with-text__right">
          <p className="select-with-text__right-title">حالة المقالة</p>
          <p className="select-with-text__right-value">
            {selectedValue ? (
              <>
                <div
                  // className="select-with-text__right-value__dot"
                  style={{
                    width: "8px",
                    display: "inline-block",
                    marginLeft: "4px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: `${selectedValue.color}`,
                  }}
                />
                {selectedValue.label}
              </>
            ) : (
              "اختر الحالة"
            )}
          </p>
        </div>
        <div className="select-with-text__left">
          <MdKeyboardArrowDown color="#ccc" size={24} />
        </div>
      </div>
      {showDropDown && (
        <div className="drop-down">
          {dropDownData.map((item) => {
            return (
              <div
                className="drop-down__item"
                key={item.id}
                onClick={() => handleSelectItem(item)}
              >
                <div
                  className="drop-down__item__dot"
                  style={{ backgroundColor: `${item.color}` }}
                />
                <p className="drop-down__item__label">{item.label}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectWithText;
