import React from "react";
import "./TagWrapper.scss";
const TagWrapper = ({ title, bgColor, textColor }) => {
  return (
    <div
      className={"tag-wrapper"}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {title}
    </div>
  );
};

export default TagWrapper;
