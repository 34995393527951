import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../services/users";
import UserProfileHeader from "../components/User/UserProfileHeader/UserProfileHeader";

const UserProfile = () => {
  const params = useParams();
  const { data: user } = useGetUserByIdQuery({ userId: params.id });

  return (
    <main>
      <UserProfileHeader user={user} />
      <Outlet />
    </main>
  );
};

export default UserProfile;
