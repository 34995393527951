import CourseBriefSection from "./sections/CourseBriefSection";
import CourseLevelSection from "./sections/CourseLevelSection";
import InfoSection from "./sections/InfoSection";
import TagsSection from "./sections/TagsSection";
import PrerequisitesSection from "./sections/PrerequisitesSection";
import WhatWillYouLearnSection from "./sections/WhatWillYouLearnSection";
import FAQSection from "./sections/FAQSection";
import { useSelector } from "react-redux";
import DetailsSection from "./sections/DetailsSection";

const Details = ({
  formik,
  submittingController,
  setimageUploaded,
  imageUploaded,
}) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  return (
    <div className="create-course layout-page">
      <div className="create-content">
        <h1 className="create-course__title font_24 font-extrabold">
          تفاصيل الدورة
        </h1>

        <InfoSection
          values={formik.values}
          error={formik.errors}
          touched={formik.touched}
          form={formik}
          setimageUploaded={setimageUploaded}
          imageUploaded={imageUploaded}
          submittingController={submittingController}
          disabled={!currentUser?.is_admin}
        />
        <DetailsSection form={formik} disabled={!currentUser?.is_admin} />
        <FAQSection
          faqs={formik.values.faqs}
          handleChange={formik.handleChange}
          form={formik}
          disabled={!currentUser?.is_admin}
        />
        <WhatWillYouLearnSection
          key_points={formik.values.key_points}
          handleChange={formik.handleChange}
          form={formik}
          type="course"
          disabled={!currentUser?.is_admin}
        />
        <CourseBriefSection
          description={formik.values.description}
          handleChange={formik.setFieldValue}
          error={formik.errors}
          touched={formik.touched}
          type="course"
          disabled={!currentUser?.is_admin}
        />
        <TagsSection form={formik} disabled={!currentUser?.is_admin} />
        <PrerequisitesSection
          prerequisites={formik.values.prerequisites}
          handleChange={formik.handleChange}
          form={formik}
          disabled={!currentUser?.is_admin}
        />
        <CourseLevelSection
          value={formik.values.level}
          error={formik.errors}
          touched={formik.touched}
          form={formik}
          disabled={!currentUser?.is_admin}
        />
      </div>
    </div>
  );
};
export default Details;
