import { useState } from "react";
import InputFieldFloat from "../../../../Inputs/InputFieldFloat";
import CourseLessonSection from "./CourseLessonSection";
import { FieldArray } from "formik";
import Icon from "../../../../Icon/Icon";

const CourseSyllabusSection = ({
  section,
  form,
  index,
  setSection,
  setTriggerSection,
  setShowModal,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);

  return (
    <div className="mb-4 create-section">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="create-section__title font_20 font-extrabold">
          {section?.name !== "" ? section?.name : "اسم المجموعة"}
        </h2>

        <div>
          {form?.values?.sections?.length > 1 && (
            <button
              className="flex align-items-center justify-content-between remove-btn position-relative start-0 top-0 me-3"
              id="deleteDepartmentButton"
              type="button"
              onClick={() => {
                let tempSections = [...form?.values?.sections];
                tempSections?.splice(index, 1);
                form.setFieldValue("sections", tempSections);
              }}
              hidden={disabled}
            >
              <i className="fa-regular fa-trash-can font-color-grey-600 font_20" />
            </button>
          )}

          <button
            className="text-link-grey btn-link"
            onClick={() => {
              setSectionVisibility(!sectionVisiblity);
            }}
          >
            <i
              class={`fas fa-angle-${
                sectionVisiblity ? "up" : "down"
              } font_24 font-color-grey`}
            />
          </button>
        </div>
      </div>
      {sectionVisiblity && (
        <div>
          <InputFieldFloat
            label="اسم مجموعة الدروس "
            type="text"
            name={`sections.${index}.name`}
            value={section.name}
            onChange={(e) =>
              form.setFieldValue(`sections.${index}.name`, e?.target?.value)
            }
            error={
              form.errors?.sections ? form.errors?.sections[index]?.name : ""
            }
            touched={
              form.touched?.sections ? form.touched?.sections[index]?.name : ""
            }
            disabled={disabled}
          />
          <FieldArray name="array" validateOnChange={false}>
            <div>
              {section?.lessons?.map((lesson, lessonIndex) => (
                <CourseLessonSection
                  lesson={lesson}
                  form={form}
                  lessonIndex={lessonIndex}
                  sectionIndex={index}
                  section={section}
                  disabled={disabled}
                />
              ))}

              <div className="create-lesson-section">
                <button
                  onClick={() => {
                    setShowModal(true);
                    setTriggerSection(index);
                    setSection(section);
                  }}
                  className="font_18"
                  data-bs-toggle="modal"
                  data-bs-target="#create-new-lesson-modal"
                  disabled={disabled}
                >
                  <Icon
                    iconName={"add-filled"}
                    iconSize={12}
                    className={"me-2"}
                  />
                  إضافة درس جديد
                </button>
              </div>
            </div>
          </FieldArray>
        </div>
      )}
    </div>
  );
};
export default CourseSyllabusSection;
