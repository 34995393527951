import { api } from "./index";

export const liveCourses = api.injectEndpoints({
  endpoints: (builder) => ({
    createLiveCourse: builder.mutation({
      query: (body) => ({
        url: "/api/v1/tutor/live_courses",
        method: "POST",
        body,
      }),
      invalidatesTags: ['LiveCourses']
    }),
    listLiveCourses: builder.query({
      query: ({ page, per_page }) => `/api/v1/tutor/live_courses?page=${page}&per_page=${per_page}`,
      providesTags: ['LiveCourses']
    }),
    getLiveCourse: builder.query({
      query: (courseSlug) => `/api/v1/tutor/live_courses/${courseSlug}`,
      providesTags: ['LiveCourses']
    }),
    updateLiveCourse: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/v1/tutor/live_courses/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['LiveCourses']
    })
  })
})

export const { useCreateLiveCourseMutation, useListLiveCoursesQuery, useGetLiveCourseQuery, useUpdateLiveCourseMutation } = liveCourses; 