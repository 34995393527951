import React from "react";
import SelectWithText from "../../Inputs/SelectWithText/SelectWithText";
import { useSelector } from "react-redux";
import "./AddBlogInfo.scss";

const AddBlogInfo = ({ isPreview, setIsPreview }) => {
  const currentUser = useSelector((state) => state?.auth?.currentUser);

  return (
    <div
      className="header"
      style={{ backgroundColor: `${isPreview ? "transparent" : "#fff"}` }}
    >
      <div className="header-upper">
        <div className="header-upper__title font-extrabold">
          {!isPreview ? (
            "معلومات المقالة"
          ) : (
            <div className="header-upper__title">
              <p className="header-upper__title__text">
                معاينة المقالة
              </p>
              <div className="header-upper__title__dot" />
              <button onClick={() => setIsPreview(false)}>
                العودة إلى التعديلات
              </button>
            </div>
          )}
        </div>
        <div className="header-upper__status">
          {currentUser?.is_admin ? (
            <SelectWithText name="status" />
          ) : (
            <>
              <span className="header-upper__status__text">
                حالة المقالة:
              </span>

              <div className="header-upper__status__parent">
                <span className="header-upper__status__parent__dot" />

                <span className="header-upper__status__parent__value">
                  نسخة تمهيدية
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBlogInfo;
