import PasswordInputFloat from "../components/Inputs/PasswordInputFloat";
import { Formik } from "formik";
import { useChangePasswordMutation, useLogoutMutation } from "../services/auth";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../app/store/reducers/authSlice";
import { api } from "../services";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [ChangePassword] = useChangePasswordMutation();
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmedPassword: "",
  };

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .min(3, "كلمة المرور يجب ان تكون مكونة من 3 حروف كحد ادني")
      .required("كلمة المرور مطلوبة"),
    newPassword: yup
      .string()
      .min(3, "كلمة المرور يجب ان تكون مكونة من 3 حروف كحد ادني")
      .required("كلمة المرور الجديدة مطلوبة")
      .notOneOf(
        [yup.ref("currentPassword"), null],
        "يجب ان تكون كلمة المرور الجديدة مختلفة عن القديمة "
      ),
    confirmedPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "الكلمتان غير متطابقتان")
      .required("اعادة كلمة المرور الجديدة مطلوبة"),
  });

  const handleLogout = async () => {
    const result = await logout();
    if (!result.error || result?.error?.status === 401) {
      dispatch(setCurrentUser(null));
      dispatch(api.util.resetApiState());
      localStorage.removeItem("token");
      navigate("/");
    } else {
      toast.error(result?.error?.data?.message);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    const response = await ChangePassword({
      password: values.currentPassword,
      new_password: values.confirmedPassword,
    });
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("تم التعديل بنجاح");
      handleLogout();
    }
  };

  return (
    <div className="container-fluid change-password-page">
      <div className="row justify-content-center p-4">
        <div className="col-lg-9 col-md-12">
          <div className="row align-items-center">
            <div className="col-lg-6 offset-lg-2 col-md-12 order-2 order-lg-1">
              <div className="change-password-page__form flex-grow-1">
                <h3 className="font_24 font-extrabold">تغير كلمة المرور</h3>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="change-password-page__current-password">
                        <p className="font_16 font-color-grey">
                          إدخال كلمة المرور الحالية لحسابك الشخصي لتنفيذ الطلب
                        </p>
                        <PasswordInputFloat
                          label="كلمة المرور الحالية"
                          type="password"
                          name="currentPassword"
                          value={values.currentPassword}
                          onChange={handleChange}
                          error={errors.currentPassword}
                          touched={touched.currentPassword}
                        />
                      </div>

                      <div className="change-password-page__new-password">
                        <p className="font_16 font-color-grey">
                          من فضلك قم بإنشاء كلمة المرور الجديدة لحسابك
                        </p>
                        <PasswordInputFloat
                          label="كلمة المرور الجديدة"
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          error={errors.newPassword}
                          touched={touched.newPassword}
                        />

                        <PasswordInputFloat
                          label="تأكيد كلمة المرور الجديدة"
                          type="password"
                          name="confirmedPassword"
                          value={values.confirmedPassword}
                          onChange={handleChange}
                          error={errors.confirmedPassword}
                          touched={touched.confirmedPassword}
                        />
                      </div>

                      <button
                        className="btn btn-main-color d-block w-100"
                        type="submit"
                      >
                        تغير كلمة المرور
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="col-lg-3 col-md-12 order-1 order-lg-2 mb-3 mb-lg-0">
              <div className="change-password-page__icon text-center text-lg-start">
                <img
                  src="/images/reset-password.png"
                  alt="reset-password"
                  width={382}
                  height={287}
                  className="object-fit-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
