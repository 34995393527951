import React from "react";
import "./user-profile-header.scss";
import UserProfileTabs from "../UserProfileTabs/UserProfileTabs";

const UserProfileHeader = ({ user }) => {
  return (
    <header className="user-profile-header">
      <div className="user-profile-header__container">
        <div className="d-flex align-items-center">
          <img
            src={user?.data?.avatar_url}
            className="user-profile-header__avatar"
            alt={user?.data?.name}
            width={50}
            height={50}
          />
          <div className="user-profile-header__info ms-3">
            <p className="font_16 font-color-dark mb-0">{user?.data?.name}</p>
            <span className="font_12 font-color-grey-500">مستخدم</span>
          </div>
        </div>
        <UserProfileTabs user={user} />
      </div>
    </header>
  );
};

export default UserProfileHeader;
