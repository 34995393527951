import React from "react";
import "./user-transaction.scss";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";
import Pagination from "../../../../components/Pagination";
import { useGetUserByIdQuery } from "../../../../services/users";
import { useParams } from "react-router-dom";
import { paymentMethod } from "../../../../utils/helpers";

const UserTransactions = () => {
  let { id } = useParams();
  const { data: user } = useGetUserByIdQuery({ userId: id });

  return (
    <div className="user-transactions">
      <h2 className="font_24 font-color-dark font-bold mb-5 mt-3">
        الفواتير السابقة
      </h2>
      <>
        <Table className="table table-admin">
          <Thead className="table-header">
            <Tr>
              <Th className="font_12 font-color-grey font-bold">
                رقم الفاتورة{" "}
              </Th>
              <Th className="font_12 font-color-grey font-bold">تاريخ الدفع</Th>
              <Th className="font_12 font-color-grey font-bold">
                نوع الفاتورة
              </Th>
              <Th className="font_12 font-color-grey font-bold">المبلغ</Th>
              <Th className="font_12 font-color-grey font-bold">
                نوع الفاتورة
              </Th>
              {/* <Th className="font_12 font-color-grey font-bold"></Th> */}
            </Tr>
          </Thead>
          <Tbody className="table__content">
            {user?.data?.orders?.length
              ? user?.data?.orders?.map((element, key) => {
                  return (
                    <Tr key={key}>
                      <Td>
                        <div className="item">
                          <span className="font-extrabold font_14">
                            {element.id}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span className="font-extrabold font_14">
                            {moment(element?.created_at).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span className="font-extrabold font_14">
                            {element.price == 90
                              ? "اشتراك تدرب بلا حدود"
                              : "شراء دورة تدريبية"}
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span
                            className="font-extrabold font_14"
                            // style={{ marginRight: 10 }}
                          >
                            {element.price}
                            دك
                          </span>
                        </div>
                      </Td>
                      <Td>
                        <div className="item">
                          <span className="bold font_14">
                            <img
                              src={paymentMethod(
                                element.payment_options.payment_method
                              )}
                            />
                          </span>
                        </div>
                      </Td>
                      {/* <Td>
                        <div className="item">
                          <a
                            className="bold font_14 font-color-primary"
                            style={{ marginRight: 10 }}
                            href="#"
                          >
                            تحميل الفاتورة
                            <img
                              src="/images/download.png"
                              style={{ marginRight: 10 }}
                            />
                          </a>
                        </div>
                      </Td> */}
                    </Tr>
                  );
                })
              : null}
          </Tbody>
        </Table>
      </>
    </div>
  );
};

export default UserTransactions;
