import { useState } from "react";
import StatsCard from "../components/cards/StatsCard";
import SubscriptionTab from "../components/RevenueTabs/SubscriptionTab";
import SquadTab from "../components/RevenueTabs/SquadTab";
import BusinessTab from "../components/RevenueTabs/BusinessTab";
import LineChart from "../components/Chart/LineChart";
import Dropdown from "../components/Inputs/Dropdown";
//SCSS
import v from "../scss/helpers/_export-theme-variables.scss";
import {
  useGetYearlyRevenuesQuery,
  useGetMonthlyRevenuesQuery,
  useGetTotalRevenuesQuery,
} from "../services/revenues";
import { months } from "../utils/helpers";
import moment from "moment";

const Revenue = () => {
  let monthIndex = new Date().getMonth();
  let currentMonth = months[monthIndex];
  let reversedMonths = [...months].reverse();

  let currentYear = new Date().getFullYear();
  const [currentMonthDate, setCurrentMonthDate] = useState(
    moment(new Date(`${currentYear}`, `${monthIndex}`)).format()
  );
  const [currentMonthLabel, setCurrentMonthLabel] = useState(
    `${currentMonth} ${currentYear}`
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const { data: revenues } = useGetYearlyRevenuesQuery(selectedYear, {
    refetchOnMountOrArgChange: true,
  });
  const { data: monthlyRevenues } = useGetMonthlyRevenuesQuery(
    currentMonthDate,
    { refetchOnMountOrArgChange: true }
  );
  const { data: totalRevenues } = useGetTotalRevenuesQuery({
    refetchOnMountOrArgChange: true,
  });

  let currentYearMonths = reversedMonths.slice(12 - monthIndex - 1);
  let previousYearMonths = reversedMonths.slice(
    0,
    13 - currentYearMonths.length
  );

  let monthsToBeDisplayed = [
    ...currentYearMonths.map((month) => {
      return {
        label: `${month} ${currentYear}`,
        monthIndex: months.indexOf(month),
        year: currentYear,
      };
    }),
    ...previousYearMonths.map((month) => {
      return {
        label: `${month} ${currentYear - 1}`,
        monthIndex: months.indexOf(month),
        year: currentYear - 1,
      };
    }),
  ];

  const TABS = {
    SUBSCRIPTION: 1,
    SQUAD: 2,
    BUSINESS: 3,
  };

  const [selectedTab, setSelectedTab] = useState(TABS.SUBSCRIPTION);

  const lineChartData = revenues?.data?.monthly_revenues;
  const monthLabels = [
    `يناير ${selectedYear}`,
    `فبراير ${selectedYear}`,
    `مارس ${selectedYear}`,
    `أبريل ${selectedYear}`,
    `مايو ${selectedYear}`,
    `يوليو ${selectedYear}`,
    `يوليه ${selectedYear}`,
    `أغسطس ${selectedYear}`,
    `سبتمبر ${selectedYear}`,
    `أكتوبر ${selectedYear}`,
    `نوفمبر ${selectedYear}`,
    `ديسمبر ${selectedYear}`,
  ];

  return (
    <div className="revenue layout-pages">
      <section className="mb-4">
        <h1 className="font_24 font-extrabold mb-36">أرباحي عبر المنصة</h1>

        <div className="revenue__summary-reports__content">
          <div className="row">
            <div className="col-12 col-md-4">
              <StatsCard
                bgColor={v.colorGreen50}
                iconName="hand-dollar-filled"
                iconColor={v.colorGreen300}
                iconSize={40}
                title={"لديك في حسابك"}
                num={totalRevenues?.data?.wallet}
                numFontSize={24}
                containerClassName="h-auto px-21 py-22 d-flex "
                numClassName="mb-0 ps-20"
                titleClassName="ps-20"
                titleFontcolor="grey-800"
                text="د.ك"
                textFontSize={16}
              />
            </div>
            <div className="col-12 col-md-4 my-3 my-md-0">
              <StatsCard
                bgColor={v.colorgrey60}
                title="المدفوع"
                num={totalRevenues?.data?.transferred}
                numFontSize={24}
                text="د.ك"
                textFontSize={16}
                containerClassName="h-auto px-21 py-22"
                numClassName="mb-0"
              />
            </div>
            <div className="col-12 col-md-4">
              <StatsCard
                bgColor={v.colorgrey60}
                title="إجمالي الربح"
                num={totalRevenues?.data?.total_profit}
                text="د.ك"
                textFontSize={16}
                numFontSize={24}
                textFontWeight="font-bold"
                containerClassName="h-auto px-21 py-22"
                numClassName="mb-0"
              ></StatsCard>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h1 className="font_24 font-extrabold mb-25"> ملخص الأرباح</h1>

        <div className="app-tabs mb-20">
          <div
            className="nav nav-tabs align-items-center"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active font_16"
              id="subscription-tab"
              data-bs-toggle="tab"
              data-bs-target="#subscription"
              type="button"
              role="tab"
              aria-controls="subscription"
              aria-selected="true"
              onClick={() => {
                setSelectedTab(TABS.SUBSCRIPTION);
              }}
            >
              نظام الإشتراكات
            </button>
            <button
              className="nav-link font_16"
              id="squad-tab"
              data-bs-toggle="tab"
              data-bs-target="#squad"
              type="button"
              role="tab"
              aria-controls="squad"
              aria-selected="false"
              onClick={() => {
                setSelectedTab(TABS.SQUAD);
              }}
            >
              مبيعات الدورات
            </button>
            <button
              className="nav-link font_16"
              id="business-tab"
              data-bs-toggle="tab"
              data-bs-target="#business"
              type="button"
              role="tab"
              aria-controls="business"
              aria-selected="false"
              onClick={() => {
                setSelectedTab(TABS.BUSINESS);
              }}
            >
              تدرب بيزنس
            </button>
            <span style={{ margin: "0 auto" }}></span>
            <Dropdown
              toggleBtnClassName="revenue-menu"
              toggleLabel={currentMonthLabel}
              hasToggleArrowDown
            >
              {monthsToBeDisplayed.map((month) => {
                return (
                  <li
                    className="setting-menu__item"
                    onClick={() => {
                      setCurrentMonthDate(
                        moment(
                          new Date(month?.year, month?.monthIndex)
                        ).format()
                      );
                      setCurrentMonthLabel(month?.label);
                    }}
                  >
                    <p
                      className="mb-1 font-bold"
                      style={{ textAlign: "center", cursor: "pointer" }}
                    >
                      {month?.label}
                    </p>
                  </li>
                );
              })}
            </Dropdown>
          </div>
        </div>

        {selectedTab === TABS.SUBSCRIPTION && (
          <SubscriptionTab monthlyRevenues={monthlyRevenues?.data} />
        )}
        {selectedTab === TABS.SQUAD && (
          <SquadTab monthlyRevenues={monthlyRevenues?.data} />
        )}
        {selectedTab === TABS.BUSINESS && <BusinessTab />}
      </section>

      <section className="bg-white shadow-2 mt-30 border-radius-20 py-30 px-40">
        <h4 style={{ fontWeight: 600 }}>تقارير الربح السنوي</h4>
        <Dropdown
          toggleBtnClassName="revenue-menu"
          toggleLabel={selectedYear}
          hasToggleArrowDown
        >
          <li
            className="setting-menu__item"
            onClick={() => setSelectedYear(currentYear)}
          >
            <p
              className="mb-1 font-bold"
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              سنة {currentYear}
            </p>
          </li>
          <li
            className="setting-menu__item"
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => setSelectedYear(currentYear - 1)}
          >
            <p className="mb-1 font-bold">سنة {currentYear - 1}</p>
          </li>
          <li
            className="setting-menu__item"
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => setSelectedYear(currentYear - 2)}
          >
            <p className="mb-1 font-bold">سنة {currentYear - 2}</p>
          </li>
        </Dropdown>
        <LineChart
          data={lineChartData}
          labels={monthLabels}
          backgroundColor={v.colorGreen300}
          borderColor={v.colorGreen200}
        />
      </section>
    </div>
  );
};

export default Revenue;
