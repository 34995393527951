import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import "./StickyHeader.scss";

const StickyHeader = ({
  title,
  setShowDeleteModal,
  setShowSubmitModal,
  isPreview,
  setIsPreview,
  isSubmitting,
}) => {
  return (
    <div className="sticky-bar">
      <div className="blog-page__sticky-bar__title">{title}</div>
      <div className="blog-page__sticky-bar__buttons">
        {/* <button className="btn filled-btn" type="button">
      حفظ المقالة
     </button> */}
        <button
          className="btn outlined-btn"
          type="submit"
          disabled={isSubmitting}
        >
          إرسال للمراجعة
        </button>
        <button
          onClick={() => {
            setIsPreview(!isPreview);
          }}
          type="button"
          className="btn outlined-btn"
        >
          {!isPreview ? (
            <img
              src="/images/Preview.svg"
              width={24}
              height={24}
              alt="preview icon"
            />
          ) : (
            <MdEdit size={24} />
          )}
        </button>
        <button
          onClick={() => setShowDeleteModal(true)}
          type="button"
          className="btn outlined-btn"
        >
          <FaRegTrashAlt size={24} color={"#AF151F"} />
        </button>
      </div>
    </div>
  );
};

export default StickyHeader;
