import { useEffect, useState } from "react";
import InputFieldFloat from "../../../../Inputs/InputFieldFloat";
import Checkbox from "../../../../Inputs/Checkbox";
import SelectFile from "../../../../Inputs/SelectFile";
import { useSelector } from "react-redux";

const CourseLessonSection = ({
  section,
  lesson,
  form,
  lessonIndex,
  sectionIndex,
  disabled,
}) => {
  const [sectionVisiblity, setSectionVisibility] = useState(true);
  const [checked, setChecked] = useState(lesson?.is_free);
  const [fileAttachment, setFileAttachment] = useState("");
  const [fileName, setFileName] = useState(
    lesson?.type == "ExplanationFile"
      ? lesson?.file_url == ""
        ? ""
        : decodeURIComponent(
            lesson?.file_url?.substring(
              lesson?.file_url?.lastIndexOf("/") + 1,
              lesson?.file_url?.lastIndexOf(lesson?.file_url?.slice(-1)) + 1
            )
          )
      : ""
  );
  const [videoName, setVideoName] = useState();
  const [VideoAttachment, setVideoAttachment] = useState("");
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (VideoAttachment != "") {
      setVideoName(VideoAttachment?.name);
      form.setFieldValue(
        `sections.${sectionIndex}.lessons.${lessonIndex}.video`,
        VideoAttachment
      );
    }
  }, [VideoAttachment]);

  useEffect(() => {
    if (fileAttachment != "") {
      setFileName(fileAttachment?.name);
      form.setFieldValue(
        `sections.${sectionIndex}.lessons.${lessonIndex}.file`,
        fileAttachment
      );
    }
  }, [fileAttachment]);

  useEffect(() => {
    if (fileAttachment == "") {
      setFileName(section[sectionIndex]?.lesson[lessonIndex]?.file?.name);
    }
  }, [section[sectionIndex]?.lesson[lessonIndex]?.file]);

  useEffect(() => {
    form.setFieldValue(
      `sections.${sectionIndex}.lessons.${lessonIndex}.is_free`,
      checked
    );
  }, [checked]);

  return (
    <div className="create-lesson-section">
      <div className="d-flex justify-content-between align-items-center create-lesson-section__title">
        <h3 className="font-extrabold mb-0">
          {lesson?.name ? lesson.name : "اسم الدرس"}
        </h3>
        <div>
          {section?.lessons?.length > 1 && (
            <button
              className="flex align-items-center justify-content-between remove-btn position-relative start-0 top-0 me-3"
              id="deleteDepartmentButton"
              type="button"
              onClick={() => {
                let tempLessons = [...section?.lessons];
                tempLessons?.splice(lessonIndex, 1);
                form.setFieldValue(
                  `sections.${sectionIndex}.lessons`,
                  tempLessons
                );
              }}
              hidden={disabled}
            >
              <i className="fa-regular fa-trash-can font-color-grey-600 font_20" />
            </button>
          )}
          <button
            className="text-link-grey btn-link"
            onClick={() => {
              setSectionVisibility(!sectionVisiblity);
            }}
          >
            <i
              class={`fas fa-angle-${
                sectionVisiblity ? "up" : "down"
              } font_24 `}
            />
          </button>
        </div>
      </div>

      {sectionVisiblity && (
        <div>
          <InputFieldFloat
            label="اسم الدرس"
            type="text"
            name={`sections.${sectionIndex}.lessons.${lessonIndex}.name`}
            value={lesson.name}
            onChange={(e) =>
              form.setFieldValue(
                `sections.${sectionIndex}.lessons.${lessonIndex}.name`,
                e?.target?.value
              )
            }
            error={
              form.errors?.sections
                ? form.errors.sections[sectionIndex]
                  ? form.errors.sections[sectionIndex]?.lessons
                    ? form.errors.sections[sectionIndex]?.lessons[lessonIndex]
                        ?.name
                    : ""
                  : ""
                : ""
            }
            touched={
              form.touched?.sections
                ? form.touched?.sections[sectionIndex]?.lessons[lessonIndex]
                    ?.name
                : ""
            }
            disabled={disabled}
          />
          <div className="row">
            {lesson.type == "Video" ? (
              <>
                <div className="col-12 col-md-5">
                  <div
                    className="col-md-11"
                    style={{ display: "inline-block" }}
                  >
                    <label className="font-color-grey-500 font_16 lh-17 mb-13">
                      فيديو الدرس
                    </label>
                    <SelectFile
                      id={`lesson-upload-sec[${sectionIndex}]-lec[${lessonIndex}]`}
                      value={
                        typeof form.values?.sections[sectionIndex]?.lessons[
                          lessonIndex
                        ].video_review_download_url == "string" &&
                        form.values?.sections[sectionIndex]?.lessons[
                          lessonIndex
                        ].video_review_download_url != ""
                          ? decodeURIComponent(
                              form.values?.sections[sectionIndex]?.lessons[
                                lessonIndex
                              ].video_review_download_url?.substring(
                                form.values?.sections[sectionIndex]?.lessons[
                                  lessonIndex
                                ].video_review_download_url?.lastIndexOf("/") +
                                  1,
                                form.values?.sections[sectionIndex]?.lessons[
                                  lessonIndex
                                ].video_review_download_url?.lastIndexOf("?")
                              )
                            )
                          : form.values.sections[sectionIndex]?.lessons[
                              lessonIndex
                            ]?.video?.name
                      }
                      setFile={setVideoAttachment}
                      accept="video/mp4,video/x-m4v,video/*"
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-md-1" style={{ display: "inline-block" }}>
                    {typeof form.values?.sections[sectionIndex]?.lessons[
                      lessonIndex
                    ].video_review_download_url == "string" &&
                    form.values?.sections[sectionIndex]?.lessons[lessonIndex]
                      .video_review_download_url != "" ? (
                      <img
                        className="mt-2 mx-2"
                        alt=""
                        src="/images/download.svg"
                        style={{ cursor: "pointer", width: "25px" }}
                        onClick={() => {
                          window.location.href =
                            form.values?.sections[sectionIndex]?.lessons[
                              lessonIndex
                            ].video_review_download_url;
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4" style={{ marginTop: "34px" }}>
                  <InputFieldFloat
                    label="أدخل لينك  فيديو الدرس"
                    name={`sections.${sectionIndex}.lessons.${lessonIndex}.video_source[0].src`}
                    value={
                      lesson?.video_source ? lesson?.video_source[0]?.src : ""
                    }
                    onChange={(e) =>
                      form.setFieldValue(
                        `sections.${sectionIndex}.lessons.${lessonIndex}.video_source[0].src`,
                        e?.target?.value
                      )
                    }
                    disabled={disabled}
                  />
                </div>
                {currentUser?.is_admin == true ? (
                  <div
                    className="col-12 col-md-4"
                    style={{ marginTop: "34px" }}
                  >
                    <InputFieldFloat
                      label="أدخل ID  فيديو الدرس"
                      name={`sections.${sectionIndex}.lessons.${lessonIndex}.video_id`}
                      value={
                        lesson?.video_id &&
                        lesson?.video_id != "undefined" &&
                        lesson?.video_id != "null"
                          ? lesson?.video_id
                          : ""
                      }
                      onChange={(e) =>
                        form.setFieldValue(
                          `sections.${sectionIndex}.lessons.${lessonIndex}.video_id`,
                          e?.target?.value
                        )
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className="col-12 col-md-6">
                  {" "}
                  <label className="font-color-grey-500 font_16 lh-17 mb-13">
                    ملف الدرس
                  </label>
                  <SelectFile
                    id={`lesson-upload-pdf-sec[${sectionIndex}]-lec[${lessonIndex}]`}
                    value={fileName}
                    setFile={setFileAttachment}
                    accept="application/pdf"
                    error={form.errors.attachment}
                    touched={form.touched.attachment}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            <div className="col-12 col-md-3 mb-1" style={{ marginTop: "34px" }}>
              <Checkbox
                children="هل الدرس مجاني؟"
                id="isFree"
                checked={checked}
                setChecked={setChecked}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CourseLessonSection;
